import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Divider, IconButton, Typography } from '@industrial-plus/shared-ui'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { Box, DialogContent, Popover, useTheme } from '@mui/material'

import {
  PushNotification,
  usePushNotificationsQuery,
} from '@/__generated__/graphql'
import NoNotificationsBell from '@/assets/svg/no-notifications-bell.svg'
import { useNotificationProviderContext } from '@/providers/NotificationProvider'

import EventTile from './EventTile'
import {
  FilterValues,
  NotificationStatus,
  Properties,
} from './NotificationInterfaces'
import { NotificationPopupHeader } from './NotificationPopupHeader'
import {
  StyledBadge,
  StyledNoNotificationsBox,
} from './NotificationPopupStyled'

export const NotificationPopup = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { data, refetch } = usePushNotificationsQuery({
    context: {
      clientName: 'notifications',
    },
  })
  const { notification: fcmNotification, setNotification } =
    useNotificationProviderContext()

  const notifications = data?.pushNotifications
  const [filteredNotifications, setFilteredNotifications] = useState<
    PushNotification[]
  >([])

  const [filters, setFilters] = useState<FilterValues>({
    businessUnit: '',
    type: '',
    siteName: '',
    from: null,
    to: null,
  })

  useEffect(() => {
    if (notifications) {
      setFilteredNotifications(notifications as PushNotification[])
    }
  }, [notifications])

  const businessUnits = useMemo(
    () => [
      ...new Set(
        notifications?.map((notification) => notification?.businessUnit)
      ),
    ],
    [notifications]
  )

  const types = useMemo(
    () => [
      ...new Set(notifications?.map((notification) => notification?.type)),
    ],
    [notifications]
  )

  const sites = useMemo(
    () => [
      ...new Set(
        notifications?.map((notification) => {
          const siteNameObject = notification?.propertiesMap.find(
            (property) => property?.key === Properties.SITE_NAME
          )
          return siteNameObject ? siteNameObject.value : ''
        })
      ),
    ],
    [notifications]
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    refetch()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFilter = (input: FilterValues) => {
    const { businessUnit, type, siteName, from, to } = input

    const fromDate = from ? new Date(from) : null
    const toDate = to ? new Date(to) : null

    setFilters(input)

    const newFilteredNotifications = (notifications ?? []).filter(
      (notification) => {
        const {
          businessUnit: nBusinessUnit,
          type: nType,
          createdAt,
          propertiesMap,
        } = notification ?? {}
        const siteNameObject = propertiesMap?.find(
          (property) =>
            property?.key === Properties.SITE_NAME &&
            property?.value === siteName
        )
        const notificationDate = createdAt ? new Date(createdAt) : null

        return (
          (businessUnit === '' || nBusinessUnit === businessUnit) &&
          (type === '' || nType === type) &&
          (siteName === '' || siteNameObject !== undefined) &&
          (!fromDate || (notificationDate && notificationDate >= fromDate)) &&
          (!toDate || (notificationDate && notificationDate <= toDate))
        )
      }
    )
    setFilteredNotifications(newFilteredNotifications as PushNotification[])
  }

  const handleSortClick = () => {
    if (filteredNotifications) {
      const sortedNotifications = [...filteredNotifications].reverse()
      setFilteredNotifications(sortedNotifications)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    if (open) {
      // Resetting the FCM notification storage when notification panel opens as API will fetch new notifications
      setNotification({})
    }
  }, [open, setNotification])

  const unReadNotificationsCount = useMemo(() => {
    let notificationCount =
      notifications?.filter(
        (notification) => notification?.status === NotificationStatus.NEW
      ).length ?? 0

    if (fcmNotification) {
      notificationCount =
        notificationCount + Object.keys(fcmNotification).length
    }

    return notificationCount
  }, [notifications, fcmNotification])

  const areFiltersActive = useMemo(() => {
    return (
      businessUnits.length > 0 ||
      types.length > 0 ||
      sites.length > 0 ||
      filters.from !== null ||
      filters.to !== null
    )
  }, [businessUnits, types, sites, filters.from, filters.to])

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        {unReadNotificationsCount > 0 ? (
          <StyledBadge
            badgeContent={unReadNotificationsCount?.toString().padStart(2, '0')}
          >
            <NotificationsNoneIcon />
          </StyledBadge>
        ) : (
          <NotificationsNoneIcon />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { borderRadius: 0, width: '40rem', margin: 0, padding: 0 },
        }}
      >
        <NotificationPopupHeader
          totalCount={filteredNotifications?.length ?? 0}
          areFiltersActive={areFiltersActive}
          businessUnits={businessUnits}
          types={types}
          sites={sites}
          handleFilter={handleFilter}
          handleSortClick={handleSortClick}
        />
        <DialogContent sx={{ padding: '0rem !important', margin: '0rem' }}>
          {(filteredNotifications?.length ?? 0) > 0 ? (
            filteredNotifications?.map((notification, index) => (
              <Box key={notification?.id} sx={{ width: 'inherit' }}>
                {notification && <EventTile notificationData={notification} />}
                {index < (filteredNotifications?.length ?? 0) - 1 && (
                  <Divider sx={{ width: 'inherit', margin: 0 }} />
                )}
              </Box>
            ))
          ) : (
            <StyledNoNotificationsBox>
              <img
                src={NoNotificationsBell}
                alt={t('users.profile.notifications.empty')}
              />
              <Typography
                variant="h6"
                color={theme.colors?.primary300}
                mt={4.5}
                mb={2}
              >
                {t('users.profile.notifications.empty')}
              </Typography>
            </StyledNoNotificationsBox>
          )}
        </DialogContent>
      </Popover>
    </div>
  )
}
