import { forwardRef } from 'react';

import {
    ListItemIcon as MuiListItemIcon,
    ListItemIconProps as MuiListItemIconProps,
} from '@mui/material';

export type ListItemIconProps = MuiListItemIconProps

/** ListItemIcon
 *
 * `import ListItemIcon from '@fsp-io/ListItemIcon'`
 * 
 * To see Usage Example, check `MenuItem` storybook
 */

const ListItemIcon = forwardRef<HTMLDivElement, ListItemIconProps>(
  (props, ref) => {
    return <MuiListItemIcon {...props} ref={ref} />;
  },
);

ListItemIcon.displayName = 'ListItemIcon';

export default ListItemIcon;
