import { memo, useMemo } from 'react';
import {
  CountryCode,
  CountryFlag as CountryFlagLazyload,
} from 'react-country-flags-lazyload';
import countryList from 'react-select-country-list';

import { CountryFlagProps } from './types';
import { unifyCountryCodeToISO2 } from '../../utils/location.utils';

const CountryFlag: React.FC<CountryFlagProps> = ({
  countryCode,
  countryName = '',
  ...restProps
}: CountryFlagProps) => {
  const validCountryCode = useMemo(() => {
    if (countryCode) {
      return countryCode;
    }

    return countryList().getValue(countryName);
  }, [countryCode, countryName]);

  if (!validCountryCode) {
    return null;
  }

  return (
    <CountryFlagLazyload
      countryCode={unifyCountryCodeToISO2(validCountryCode) as CountryCode}
      {...restProps}
    />
  );
};

export default memo(CountryFlag);
