export const TABS_CONFIGURATION = [
  {
    id: "1",
    title: "Terms of Use",
  },
  {
    id: "2",
    title: "Privacy Policy",
  },
];
