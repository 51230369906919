import { forwardRef } from 'react';

import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material';

export type DialogActionsProps = MuiDialogActionsProps

/** DialogActions
 *
 *  DialogActions are used to give an option to the users to perform an action while the Dialog is present on the screen.
 *  This action can be different from opening and closing the Dialog itself.
 *
 * `import { DialogActions } from '@fsp-io/shared-ui'`
 */

const DialogActions = forwardRef<HTMLDivElement, DialogActionsProps>(
  (props, ref) => {
    return <MuiDialogActions {...props} ref={ref} />;
  },
);

DialogActions.displayName = 'DialogActions';

export default DialogActions;
