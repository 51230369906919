import { forwardRef } from 'react';

import MuiTableCell, {
  TableCellProps as MuiTableCellProps,
} from '@mui/material/TableCell';
import { getSxStyles } from "../../utils/styles.utils";
import { SystemStyleObject } from '@mui/system';

export interface TableCellProps extends Omit<MuiTableCellProps, 'valign'> {
  bgColor?: string;
  disabled?: boolean;
}

/** TableCell
 *
 * `import { TableCell } from '@fsp-io/shared-ui'`
 */

const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ sx, bgColor, ...rest }, ref) => {
    return (
      <MuiTableCell
        {...rest}
        ref={ref}
        sx={(theme) =>
          ({ backgroundColor: bgColor, ...getSxStyles(theme, sx) } as SystemStyleObject)
        }
      />
    );
  },
);

TableCell.displayName = 'TableCell';

export default TableCell;
