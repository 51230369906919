import React, { SyntheticEvent, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Tab, TabContext, Tabs } from '@industrial-plus/shared-ui'
import { ROUTE_CONSTANTS } from '@/constants/paths'
import { useCurrentUserQuery } from '@/__generated__/graphql'

const UserProfileTabs: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data } = useCurrentUserQuery({
    context: {
      clientName: 'users',
    },
  })
  const userRole = data?.currentUser?.user?.role

  const activeTab = useMemo(() => {
    const path = location.pathname
    if (path.includes(ROUTE_CONSTANTS.profile)) {
      return ROUTE_CONSTANTS.profile
    } else if (path.includes(ROUTE_CONSTANTS.notifications)) {
      return ROUTE_CONSTANTS.notifications
    } else {
      return ROUTE_CONSTANTS.profile
    }
  }, [location.pathname])

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    navigate(newValue)
  }
  if (userRole !== 'ADMIN') {
    return (
      <Box sx={{ width: '100%', mt: '2rem', backgroundColor: 'white' }}>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab
                label={t('common.profile')}
                value={ROUTE_CONSTANTS.profile}
              />
              <Tab
                label={t('users.profile.notifications.index')}
                value={ROUTE_CONSTANTS.notifications}
              />
            </Tabs>
          </Box>
        </TabContext>
      </Box>
    )
  } else {
    return null
  }
}

export default React.memo(UserProfileTabs)
