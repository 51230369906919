import { lazy } from 'react'

// Auth Routes
export const LazyLoadedLogin = lazy(
  () => import('@/app/pages/auth/login/LoginLayout')
)
export const LazyLoadedLogout = lazy(() => import('@/app/pages/auth/Logout'))
export const LazyLoadedLogoutCallback = lazy(
  () => import('@/app/pages/auth/LoginCallback')
)
export const LazyLoadedForgotPassword = lazy(
  () => import('@/app/pages/auth/reset-password/ResetPasswordLayout')
)

// Site Routes
export const LazyLoadedSitesList = lazy(
  () => import('@/app/pages/sites/list/index')
)
export const LazyLoadedSitesOverview = lazy(
  () => import('@/app/pages/sites/site-overview')
)
export const LazyLoadedSiteDashboard = lazy(
  () => import('@/app/pages/sites/site-dashboard')
)
export const LazyLoadedSiteSystems = lazy(
  () => import('@/app/pages/sites/site-systems')
)
export const LazyLoadedSiteDevices = lazy(
  () => import('@/app/pages/sites/site-devices')
)
export const LazyLoadedSiteAssignedUsers = lazy(
  () => import('@/app/pages/sites/site-assigned-users')
)
export const LazyLoadedSiteEvents = lazy(
  () => import('@/app/pages/sites/site-events/SiteEvents')
)
export const LazyLoadedSiteEventsHistory = lazy(
  () => import('@/app/pages/sites/site-events-history/SiteEventsHistory')
)
export const LazyLoadedSiteAlarms = lazy(
  () => import('@/app/pages/sites/site-alarms/SiteAlarms')
)
export const LazyLoadedSiteAlarmsHistory = lazy(
  () => import('@/app/pages/sites/site-alarms-history/SiteAlarmsHistory')
)
export const LazyLoadedSiteIncidents = lazy(
  () => import('@/app/pages/sites/site-incidents')
)
export const LazyLoadedSiteWalkTests = lazy(
  () => import('@/app/pages/sites/site-walk-tests')
)
export const LazyLoadedSystemDetails = lazy(
  () => import('@/app/pages/sites/site-systems/details/index')
)
export const LazyLoadedAssignUsers = lazy(
  () => import('@/app/pages/sites/site-assignable-users')
)
export const LazyLoadedSiteSystemAnalytics = lazy(
  () => import('@/app/pages/sites/site-system-analytics/SystemAnalytics')
)

// Manager+ Routes
export const LazyLoadedManagerPlusRouting = lazy(
  () => import('@/app/pages/plus/manager/ManagerPlusRouting')
)
export const LazyLoadedManagerPlusDashboard = lazy(
  () => import('@/app/pages/plus/manager/dashboard/ManagerDashboard')
)
export const LazyLoadedManagerPlusInspections = lazy(
  () => import('@/app/pages/plus/manager/inspections/InspectionsRouting')
)
export const LazyLoadedManagerPlusReports = lazy(
  () => import('@/app/pages/plus/manager/reports-list/ReportsList')
)

// Installation Routes
export const LazyLoadedInstallationPage = lazy(
  () => import('@/app/pages/sites/site-systems/installation/Installation')
)

// User Routes
export const LazyLoadedUsersList = lazy(
  () => import('@/app/pages/users/list/index')
)
export const LazyLoadedUserProfile = lazy(
  () => import('@/app/pages/users/profile/index')
)
export const LazyLoadedUserNotifications = lazy(
  () => import('@/app/pages/users/profile/UserNotifications')
)
export const LazyLoadedUserDetails = lazy(
  () => import('@/app/pages/users/details/index')
)

// Support Routes
export const LazyLoadedSupportPage = lazy(
  () => import('@/app/pages/support/index')
)

// Settings Routes
export const LazyLoadedSettingsPage = lazy(
  () => import('@/app/pages/settings/index')
)

// 404 Page Routes
export const LazyLoadedPageNotFound = lazy(
  () => import('@/app/pages/page-not-found')
)

// AdminPlus Routes
export const LazyLoadedAdminPlusDashboard = lazy(
  () => import('@/app/pages/plus/admin/dashboard/Dashboard')
)
export const LazyLoadedMasterTemplateRoutes = lazy(
  () => import('@/app/pages/plus/admin/templates/MasterTemplateRoutes')
)
export const LazyLoadedMasterTemplateList = lazy(
  () => import('@/app/pages/plus/admin/templates/master/MasterTemplateList')
)
export const LazyLoadedArchivedTemplateList = lazy(
  () => import('@/app/pages/plus/admin/templates/archived/ArchivedTemplates')
)
export const LazyLoadedAssignedTemplateList = lazy(
  () => import('@/app/pages/plus/admin/templates/assigned/AssignedTemplates')
)
export const LazyLoadedAdminPlusDetailRoutes = lazy(
  () => import('@/app/pages/plus/admin/AdminPlusDetailsRoutes')
)
export const LazyLoadedAdminPlusAssignTemplates = lazy(
  () =>
    import(
      '@/app/pages/plus/admin/templates/master/assign-templates/AssignTemplates'
    )
)
export const LazyLoadedAdminPlusUnAssignTemplates = lazy(
  () =>
    import(
      '@/app/pages/plus/admin/templates/assigned/un-assign-template/UnAssignTemplates'
    )
)
