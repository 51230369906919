import {
  ClickAwayListener as MuiClickAwayListener,
  ClickAwayListenerProps as MuiClickAwayListenerProps,
} from '@mui/material';

export type ClickAwayListenerProps = MuiClickAwayListenerProps

/** ClickAwayListener
 *
 * Detect if a click event happened outside of an element. It listens for clicks that occur somewhere in the document.
 *
 * `import { ClickAwayListener } from '@fsp-io/shared-ui'`
 */

export default function ClickAwayListener({
  disableReactTree = false,
  mouseEvent = 'onClick',
  touchEvent = 'onTouchEnd',
  ...rest
}: ClickAwayListenerProps): JSX.Element {
  return (
    <MuiClickAwayListener
      disableReactTree={disableReactTree}
      mouseEvent={mouseEvent}
      touchEvent={touchEvent}
      {...rest}
    />
  );
}
