import AttachFileIcon from '@mui/icons-material/AttachFile';
import ErrorIcon from '@mui/icons-material/Error';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { ReactComponent as CsvThumbnail } from '../assets/csv-thumbnail.svg';
import { ReactComponent as ImageThumbnail } from '../assets/image-thumbnail.svg';
import { ReactComponent as PdfThumbnail } from '../assets/pdf-thumbnail.svg';
import Box from '../components/box';
import Icon from '../components/icon';

export interface FileItem {
  fileData?: File;
  uploadStatus?: 'pending' | 'success' | 'rejected';
}

export const getWidth = (
  uploadStatus: FileItem['uploadStatus'],
  showProgressBar: boolean,
  listType: string,
): string => {
  if (showProgressBar && uploadStatus === 'pending') {
    return '144px';
  }
  return listType === 'list' ? '210px' : '180px';
};

export const checkInProgressStatus = (
  uploadStatus: FileItem['uploadStatus'],
  showProgressBar: boolean,
) => {
  if (showProgressBar && uploadStatus === 'pending') {
    return 'disabled';
  } else if (uploadStatus === 'rejected') {
    return 'error';
  }
  return 'primary';
};

export const isRejected = (uploadStatus: FileItem['uploadStatus']) => {
  if (uploadStatus === 'rejected') {
    return 'error.dark';
  }
  return 'text.primary';
};

// Thumbnail icon rendering functions
const getThumbnailIcon = (fileType: File['type'], showProgressBar: boolean) => {
  if (fileType === 'text/csv') {
    return (
      <CsvThumbnail
        opacity={showProgressBar ? 0.32 : 1}
        style={{ width: '100%', height: '100%' }}
      />
    );
  } else if (fileType === 'application/pdf') {
    return (
      <PdfThumbnail
        opacity={showProgressBar ? 0.32 : 1}
        style={{ width: '100%', height: '100%' }}
      />
    );
  } else {
    return (
      <ImageThumbnail
        opacity={showProgressBar ? 0.32 : 1}
        style={{ width: '100%', height: '100%' }}
      />
    );
  }
};

const renderThumbnailIcon = (file: FileItem, showProgressBar: boolean) => {
  return (
    <Box sx={{ width: '48px', height: '48px' }}>
      {getThumbnailIcon(file.fileData?.type as string, showProgressBar)}
    </Box>
  );
};

export const renderThumbnail = (
  file: FileItem,
  showProgressBar: boolean,
  listType: 'list' | 'thumbnail-list',
) => {
  if (listType === 'list') {
    return (
      <Icon fontSize="xsmall">
        {file.uploadStatus !== 'rejected' ? (
          <AttachFileIcon
            sx={{ width: '100%', height: '100%' }}
            color={checkInProgressStatus(file.uploadStatus, showProgressBar)}
          />
        ) : (
          <ErrorIcon
            sx={{ width: '100%', height: '100%' }}
            color={checkInProgressStatus(file.uploadStatus, showProgressBar)}
          />
        )}
      </Icon>
    );
  }
  return (
    <>
      {file.uploadStatus !== 'rejected' ? (
        renderThumbnailIcon(file, showProgressBar)
      ) : (
        <ImageNotSupportedIcon
          sx={{ width: '100%', height: '100%' }}
          color={checkInProgressStatus(file.uploadStatus, showProgressBar)}
        />
      )}
    </>
  );
};
