import React, { SyntheticEvent, useState } from 'react';
import { styled } from '@mui/system';

import Tabs from '../../tabs';
import Content from '../content';
import DownloadButton from '../download-button';
import TabContext from '../../tab-context';
import Tab from '../../tab';
import TabPanel from '../../tab-panel';
import Divider from '../../divider';
import { DynamicTabsProps } from './types';

import styles from './styles';

const TabStyled = styled(Tab)(styles.tab);
const TabsStyled = styled(Tabs)(styles.tabs);
const TabPanelStyled = styled(TabPanel)(styles.tabPanel);
const DividerStyled = styled(Divider)(styles.divider);

export default function DynamicTabs({
  tabsConfiguration,
  labelDownloadPDF,
}: DynamicTabsProps) {
  const [value, setValue] = useState<string>(tabsConfiguration[0].id);
  const handleChange = (_: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabsStyled value={value} onChange={handleChange} aria-label="tabs">
        {tabsConfiguration.map((tabConfig) => (
          <TabStyled
            key={tabConfig.id}
            label={<div>{tabConfig.title}</div>}
            value={tabConfig.id}
          />
        ))}
      </TabsStyled>
      <DividerStyled />
      {tabsConfiguration.map((tabConfig) => (
        <TabPanelStyled key={tabConfig.id} value={tabConfig.id}>
          <Content content={tabConfig.content} />
          {tabConfig.linkToPDF && (
            <DownloadButton
              href={tabConfig.linkToPDF}
              label={labelDownloadPDF}
            />
          )}
        </TabPanelStyled>
      ))}
    </TabContext>
  );
}
