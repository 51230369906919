import React from 'react';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

import DialogTitle from '../../dialog-title';
import IconButton from '../../icon-button';
import { TitleProps } from './types';

import styles from './styles';

const DialogTitleStyled = styled(DialogTitle)(styles.dialogTitle);
const IconButtonStyled = styled(IconButton)(styles.buttonClose);

export default function Title({ children, onClose, sx }: TitleProps) {
  return (
    <DialogTitleStyled id="terms-of-service-title" sx={sx}>
      {children}
      <IconButtonStyled aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButtonStyled>
    </DialogTitleStyled>
  );
}
