import { forwardRef } from 'react';
import MuiTypography from '@mui/material/Typography';
import { TypographyProps } from './types';

/** Typography
 * Use typography to present your design and content as clearly and efficiently as possible.
 *
 * `import Typography from '@fsp-io/Typography'`
 */
const Typography = forwardRef<HTMLSpanElement, TypographyProps>(
  (props, ref) => {
    return <MuiTypography {...props} ref={ref} />;
  },
);

Typography.displayName = 'Typography';

export default Typography;
