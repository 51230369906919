import Box from '../box';
import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import styles from './styles';
import TextField from '../text-field';
import IconButton from '../icon-button';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { SignInFormProps } from './types';
import Button from '../button';
import FormControl from '../form-control';
import FormControlLabel from '../form-control-label';
import Checkbox from '../checkbox';
import CountrySelect from '../country-select';
import { Divider, Typography } from '@mui/material';
import { Theme } from '@mui/system';

const FormContainer = styled(Box)(styles.container);
const InputStyled = styled(TextField)(styles.formInput);
const CheckboxStyled = styled(FormControlLabel)(styles.checkboxInput);
const SubmitButton = styled(Button)(styles.submitBtn);

const SignInForm: FC<SignInFormProps> = ({
  FormContainerProps = {},
  EmailInputProps = {},
  PasswordInputProps = {},
  CountrySelectProps = {},
  StaySignedInProps = {},
  SubmitButtonProps = {},
  showCountrySelect = false,
  showStaySingIn = true,
  showCustomComponent = false,
  customComponent = null,
  SSOButtonProps = {},
  showSSOButton = false,
  errors = {},
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(true);

  const changePasswordVis = () => {
    setShowPassword((prevValue) => !prevValue);
  };

  return (
    <FormContainer {...FormContainerProps}>
      <FormControl sx={{ margin: '0 0 36px 0' }} error={errors?.email !== null}>
        <InputStyled
          label="Email"
          showBorder
          error={errors?.email !== null}
          helperText={errors?.email}
          {...EmailInputProps}
        />
      </FormControl>

      <FormControl
        sx={{ margin: `0 0 ${showCountrySelect ? '36px' : '44px'} 0` }}
        error={errors?.password !== null}
      >
        <InputStyled
          size="medium"
          label="Password"
          showBorder
          error={errors?.password !== null}
          helperText={errors?.password}
          inputSetting={{
            endAdornment: (
              <IconButton onClick={changePasswordVis}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
            type: showPassword ? 'password' : 'text',
          }}
          {...PasswordInputProps}
        />
      </FormControl>

      {showCountrySelect && (
        <FormControl role="listbox">
          <CountrySelect sx={styles.countrySelect} {...CountrySelectProps} />
        </FormControl>
      )}

      {showStaySingIn && (
        <FormControl>
          <CheckboxStyled
            control={<Checkbox {...StaySignedInProps.checkboxProps} />}
            label="Stay signed in"
            labelPlacement="end"
            {...StaySignedInProps.formProps}
          />
        </FormControl>
      )}

      {showCustomComponent && <>{customComponent}</>}

      <SubmitButton {...SubmitButtonProps} size="large" fullWidth={true}>
        {SubmitButtonProps.label ?? 'Sign In'}
      </SubmitButton>
      {showSSOButton && (
        <>
          <Divider sx={{ margin: '12px 0' }}>
            <Typography
              variant="caption"
              sx={(theme: Theme) => ({
                color: theme.palette["grey"][500],
              })}
            >
              OR
            </Typography>
          </Divider>
          <Button
            {...SSOButtonProps}
            size="large"
            fullWidth={true}
            sx={{ fontWeight: 'bold' }}
          >
            {SSOButtonProps.label ?? 'Sign in with SSO'}
          </Button>
        </>
      )}
    </FormContainer>
  );
};

export default SignInForm;