import { CSSObject, darken, lighten } from '@mui/material';
import { StyledComponentDefaultProps } from '../../types/common';

const LEFT_MODAL_PADDING = 24;
const RIGHT_MODAL_PADDING = 10;

export default {
  link: {
    cursor: 'pointer',
  },
  headerAlert: ({ theme }: StyledComponentDefaultProps) =>
    ({
      color: theme.palette.error.dark,
      '&.MuiPaper-root.MuiPaper-rounded': {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      },
      '& .MuiAlert-icon': {
        color: theme.palette.error.dark,
      },
      '& .MuiAlert-message': {
        maxHeight: theme.spacing(8),
        overflowY: 'auto',
        justifyContent: 'flex-start',
      },
    } as CSSObject),
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
  } as CSSObject,
  contentContainer: {
    padding: `${
      RIGHT_MODAL_PADDING * 2
    }px ${RIGHT_MODAL_PADDING}px ${RIGHT_MODAL_PADDING}px ${LEFT_MODAL_PADDING}px`,
  },
  bodyContainer: {
    marginRight: `${LEFT_MODAL_PADDING - RIGHT_MODAL_PADDING}px`,
    marginBottom: '40px',
  },
  h6: {
    fontSize: '20px',
  },
  mainText: {
    marginTop: '26px',
    fontSize: '1rem',
  },
  textField: ({ theme }: StyledComponentDefaultProps) => ({
    marginTop: '32px',

    '& .MuiFilledInput-root': {
      backgroundColor: darken(theme.palette.background.paper, 0.04),
      border: `1px solid ${lighten(theme.palette.secondary.main, 0.5)}`,

      '&.Mui-error': {
        border: `2px solid ${theme.palette.error.main}`,
      },
    },
  }),
  progressText: {
    marginTop: '35px',
    fontSize: '16px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    marginRight: '8px',
    fontSize: '14px',
    lineHeight: '24px',
  },
  submitButton: {
    fontSize: '14px',
    lineHeight: '24px',
  },
};
