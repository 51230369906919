import jwtDecode, { JwtPayload } from 'jwt-decode'

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  NormalizedCacheObject,
  concat,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import cache from './cache/in-memory-cache'

const TOKEN_STORAGE_KEY = 'okta-token-storage'

// TODO update this method when it's connected the real localStorage connection
//
export const getUserLogged = () => {
  return (
    JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY) || '{}').accessToken
      ?.accessToken || ''
  )
}

/**
 * Check if jwt token is expired
 * @returns {boolean}
 */
const isTokenExpired = (): boolean => {
  const jwtToken: string | null = localStorage.getItem(TOKEN_STORAGE_KEY)

  if (!jwtToken) {
    return true
  }

  const { exp } = jwtDecode<JwtPayload>(jwtToken)
  const expirationTime = (exp || 0) * 1000

  return Date.now() >= expirationTime
}

const authHeadersLink = setContext(async (_, { headers }) => {
  // TODO remove line below when the FE authentication is all set
  const token = getUserLogged()
  const loggedUser = { accessToken: token }
  if (loggedUser === null) {
    return null
  }

  if (isTokenExpired()) {
    // edge case
    // Reload and renew token if okta session is still valid. Redirect to login otherwise
    window.location.reload()
    return null
  }

  const { accessToken } = loggedUser
  const customerId = sessionStorage.getItem('customerId')
  const impersonateUserId = sessionStorage.getItem('impersonateUserId')
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
      // authz: `Bearer ${accessToken}`,
      ...(customerId ? { customerId } : {}),
      ...(impersonateUserId ? { userId: impersonateUserId } : {}),
    },
  }
})

const userManagementHttpLink = new HttpLink({
  uri: process.env['NX_APOLLO_URI_USER_MANAGEMENT'] ?? '',
})

const tenantManagementHttpLink = new HttpLink({
  uri: process.env['NX_APOLLO_URI_TENANT_MANAGEMENT'] ?? '',
})

const siteManagementHttpLink = new HttpLink({
  uri: process.env['NX_APOLLO_URI_SITE_MANAGEMENT'] ?? '',
})

const inspectionManagementHttpLink = new HttpLink({
  uri: process.env['NX_APOLLO_URI_INSPECTION_MANAGEMENT'] ?? '',
})

const deviceTreeManagementHttpLink = new HttpLink({
  uri: process.env['NX_APOLLO_URI_DEVICE_MANAGEMENT'],
})

const eventsManagementHttpLink = new HttpLink({
  uri: process.env['NX_APOLLO_URI_EVENTS_MANAGEMENT'] ?? '',
})

const notificationsManagementHttpLink = new HttpLink({
  uri: process.env['NX_APOLLO_URI_NOTIFICATIONS_MANAGEMENT'] ?? '',
})

const installationManagementHttpLink = new HttpLink({
  uri: process.env['NX_APOLLO_URI_SYSTEM_INSTALLATION'] ?? '',
})

const installationFilesManagementHttpLink = new HttpLink({
  uri: process.env['NX_INSTALLATION_FILES'] ?? '',
})

const authenticatedUMHttpLink = concat(authHeadersLink, userManagementHttpLink)
const authenticatedTMHttpLink = concat(
  authHeadersLink,
  tenantManagementHttpLink
)
const authenticatedSMHttpLink = concat(authHeadersLink, siteManagementHttpLink)
const authenticatedDTMHttpLink = concat(
  authHeadersLink,
  deviceTreeManagementHttpLink
)
const authenticatedEMHttpLink = concat(
  authHeadersLink,
  eventsManagementHttpLink
)
const authenticatedSIMHttpLink = concat(
  authHeadersLink,
  installationManagementHttpLink
)
const authenticatedNMHttpLink = concat(
  authHeadersLink,
  notificationsManagementHttpLink
)

const authenticatedInstallationFileHttpLink = concat(
  authHeadersLink,
  installationFilesManagementHttpLink
)

const authenticatedIMHttpLink = concat(
  authHeadersLink,
  inspectionManagementHttpLink
)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )

  if (networkError) console.error(`[Network error]: ${networkError}`)
})

const combinedLink = ApolloLink.from([
  errorLink,
  ApolloLink.split(
    (operation) => operation.getContext()['clientName'] === 'users',
    authenticatedUMHttpLink,
    ApolloLink.split(
      (operation) => operation.getContext()['clientName'] === 'tenants',
      authenticatedTMHttpLink,
      ApolloLink.split(
        (operation) => operation.getContext()['clientName'] === 'inspections',
        authenticatedIMHttpLink,
        ApolloLink.split(
          (operation) => operation.getContext()['clientName'] === 'sites',
          authenticatedSMHttpLink,
          ApolloLink.split(
            (operation) => operation.getContext()['clientName'] === 'events',
            authenticatedEMHttpLink,
            ApolloLink.split(
              (operation) =>
                operation.getContext()['clientName'] === 'installation',
              authenticatedSIMHttpLink,
              ApolloLink.split(
                (operation) =>
                  operation.getContext()['clientName'] === 'notifications',
                authenticatedNMHttpLink,
                ApolloLink.split(
                  (operation) =>
                    operation.getContext()['clientName'] ===
                    'installation-file',
                  authenticatedInstallationFileHttpLink,
                  authenticatedDTMHttpLink
                )
              )
            )
          )
        )
      )
    )
  ),
])

export const client = new ApolloClient<NormalizedCacheObject>({
  cache,
  link: combinedLink,
})
