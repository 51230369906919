import { CSSObject, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { fleetShadows } from '../../theme';
import {
  getStatusIndicatorOffsetStyles,
  getStatusIndicatorPositionStyles,
} from '../../utils/indicatorPositionStyles.utils';
import { CardStyles } from './types';

export default {
  container: ({ statusPosition, status }: CardStyles): CSSObject => {
    const positionStyles = getStatusIndicatorPositionStyles(statusPosition);
    const offset = status ? getStatusIndicatorOffsetStyles(statusPosition) : {};

    return {
      position: 'relative',
      display: 'inline-flex',
      borderRadius: '8px',
      ...offset,

      '& .FdsCard': {
        '&.MuiPaper-outlined': {
          boxShadow: 'none',
          border: (theme: Theme) =>
            `1px solid ${theme.palette.addition?.divider}`,
        },
        '&.MuiPaper-elevation': {
          border: 0,
          boxShadow: fleetShadows[2],
        },
        '&.MuiPaper-flat': {
          border: 0,
          boxShadow: 'none',
        },
      },

      '& .status-indicator': {
        ...positionStyles,
      },
    } as CSSObject;
  },
  statusIndicator: {
    position: 'absolute',
  } as SystemStyleObject,
};
