import { AdditionColors, AdditionalBackgroundColors } from "../types/colors";
import {
  CommonColors,
  TypeAction,
  TypeText,
} from "@mui/material/styles/createPalette";

export const bootstrapColors = {
  primary: {
    main: "#0038FF",
    dark: "#001665",
    light: "#7A97FF",
    contrastText: "#fff",
    containedHoverBackground: "#0027B2",
    outlinedHoverBackground: "#0038FF14",
    outlinedRestingBackground: "#0000FF52",
    selectedHover: "#DDE4FF",
  },
  secondary: {
    main: "#424242",
    dark: "#212121",
    light: "#E0E0E0",
    contrastText: "#fff",
    containedHoverBackground: "#B2B2B2",
    outlinedHoverBackground: "#00000014",
    outlinedRestingBackground: "#00000052",
    selectedHover: "#E1E1E1",
  },
  error: {
    main: "#F44336",
    dark: "#E31B0C",
    light: "#F88078",
    contrastText: "#fff",
    containedHoverBackground: "#C5170A",
    outlinedHoverBackground: "#F4433614",
    outlinedRestingBackground: "#F4433680",
    content: "#710D06",
    background: "#FDECEA",
    selectedHover: "#FDE6E4",
  },
  info: {
    main: "#2196F3",
    dark: "#0B79D0",
    light: "#64B6F7",
    contrastText: "#fff",
    containedHoverBackground: "#096AB7",
    outlinedHoverBackground: "#2196F314",
    outlinedRestingBackground: "#2196F380",
    content: "#053C68",
    background: "#E8F4FD",
    selectedHover: "#DDEFFD",
  },
  warning: {
    main: "#ffc107",
    dark: "#FFA000",
    light: "#FFD54F",
    contrastText: "#fff",
    containedHoverBackground: "#B78900",
    outlinedHoverBackground: "#FFC10714",
    outlinedRestingBackground: "#FFC10780",
    content: "#684E00",
    background: "#FFF8E6",
    selectedHover: "#FFF4D4",
  },
  success: {
    main: "#4CAF50",
    dark: "#3B873E",
    light: "#7BC67E",
    contrastText: "#fff",
    containedHoverBackground: "#357A38",
    outlinedHoverBackground: "#4CAF5014",
    outlinedRestingBackground: "#4CAF5080",
    content: "#1E4620",
    background: "#ECF7ED",
    selectedHover: "#E4F3E4",
  },
};

export const commonColors: CommonColors = {
  black: "#000",
  white: "#fff",
};

export const backgroundColors: AdditionalBackgroundColors = {
  default: "#fafafa",
  paper: "#fff",
  desktop: "#F1F3F4",
  description: "#ECEFF140",
  skeletonBackground: "#00000014",
};

export const actionColors: Partial<TypeAction> = {
  active: "#0000008A",
  hover: "#0000000a",
  selected: "#00000014",
  disabled: "#00000042",
  disabledBackground: "#0000000A",
  focus: "#00000026",
};

export const textColors: TypeText = {
  primary: "#000000DE",
  secondary: "#666",
  disabled: "#00000061",
};

export const additionColors: AdditionColors = {
  appBarBackground: "#FFF",
  deliver: "#e0e0e0",
  divider: "#0000001F",
  outlinedBorder: "#0000003B",
  standardInputLine: "#0000006B",
  filledInputBackground: "#0000000A",
  backdropOverlay: "#00000080",
  ratingActive: "#26C6DA",
  light: "#E8EBF4",
  blue100: "#2341E0",
  gray100: "#152c7314",
  snackbarBackground: "#1a237f",
  textBoxHoverBg: "#00000017",
  selectedHover: "#0000001f",
};

export const fleetPalette = {
  ...bootstrapColors,
  common: commonColors,
  background: backgroundColors,
  text: textColors,
  action: actionColors,
  addition: additionColors,
};

export default fleetPalette;
