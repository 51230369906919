import { UserRoles } from '@/__generated__/graphql'
import React from 'react'
import AccessOutlet from './AccessOutlet'
import { PATHS } from '@/constants/paths'

const ManagerPlusOutlet: React.FC = () => {
  return (
    <AccessOutlet
      accessRoles={[UserRoles.MANAGER]}
      defaultPath={PATHS.sites.index}
    />
  )
}

export default React.memo(ManagerPlusOutlet)
