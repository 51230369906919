import { forwardRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '../icon-button';
import Icon from '../icon';
import { Chip as MuiChip } from '@mui/material';
import { ChipProps, StyledChipProps } from './types';
import styles from './styles';
import clsx from 'clsx';

const StyledMuiChip = styled(MuiChip, {
  shouldForwardProp: (prop) =>
    prop !== 'lightBackground' && prop !== 'isXSmall',
})<StyledChipProps>(styles);

/** Chips are compact elements that represent an input, attribute, or action.
 *
 * `import { Chip } from '@fsp-io/shared-ui'`
 */

const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      className,
      label = 'FDS Chip',
      deleteIcon = (
        <IconButton
          sx={{
            '&.MuiIconButton-root': {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          }}
          size="medium"
        >
          <Icon fontSize="small">
            <CloseIcon sx={{ width: '100%', height: '100%' }} />
          </Icon>
        </IconButton>
      ),
      size,
      ...rest
    },
    ref,
  ) => {
    const isXSmall = size === 'xsmall';
    const forwardedSize = isXSmall ? 'small' : size;
    const forwardedClassname = useMemo(
      () => clsx({ sizeXSmall: isXSmall }, className),
      [className],
    );
    return (
      <StyledMuiChip
        className={forwardedClassname}
        label={label}
        ref={ref}
        deleteIcon={deleteIcon}
        size={forwardedSize}
        isXSmall={isXSmall}
        {...rest}
      />
    );
  },
);

Chip.displayName = 'Chip';

export default Chip;
