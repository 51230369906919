export const COLORS = {
  primary50: '#ECEEFF',
  primary100: '#D6DAFB',
  primary200: '#ADB5F6',
  primary300: '#848FF2',
  primary400: '#5B6AED',
  primary500: '#3245E9',
  primary600: '#1629CC',
  primary700: '#101F99',
  primary800: '#0B1466',
  primary900: '#050A33',

  gray50: '#FCFCFD',
  gray100: '#F9FAFB',
  gray200: '#F2F4F7',
  gray250: '#EBEDF0',
  gray300: '#D0D5DD',
  gray400: '#98A2B3',
  gray500: '#667085',
  gray600: '#475467',
  gray700: '#344054',
  gray800: '#1D2939',
  gray900: '#101828',
  gray1000: '#4F5473',

  warning50: '#FFF8E1',
  warning100: '#FFECB3',
  warning200: '#FFE082',
  warning300: '#FFD54F',
  warning400: '#FFCA28',
  warning500: '#FFC107',
  warning600: '#FFB300',
  warning700: '#FFA000',
  warning800: '#FF8F00',
  warning900: '#FF6F00',
  warningA100: '#FFE57F',
  warningA200: '#FFD740',
  warningA300: '#FFC400',
  warningA700: '#FFAB00',

  success50: '#E8F5E9',
  success100: '#C8E6C9',
  success200: '#A5D6A7',
  success300: '#81C784',
  success400: '#66BB6A',
  success500: '#4CAF50',
  success600: '#43A047',
  success700: '#388E3C',
  success800: '#2E7D32',
  success900: '#1B5E20',
  successA100: '#B9F6CA',
  successA200: '#69F0AE',
  successA300: '#00E676',
  successA700: '#00C853',

  error50: '#FBE9E7',
  error100: '#FFCCBC',
  error200: '#FFAB91',
  error300: '#FF8A65',
  error400: '#FF7043',
  error500: '#FF5722',
  error600: '#F4511E',
  error700: '#E64A19',
  error800: '#D84315',
  error900: '#BF360C',
  errorA100: '#FF9E80',
  errorA200: '#FF6E40',
  errorA300: '#FF3D00',
  errorA700: '#DD2C00',

  natural200: '#F2F4F7',
  white: '#FFFFFF',
  black: '#000000',
  phoneVerified: '#4CAF50',
  phoneNotVerified: '#EA9D00',
  activeColor: '#D0DBFF',
  cultured: '#F5F5F5',
  tagBgColor: '#270adc',
  textDark: '#181818',
  lightGrayBorder: '#E3E3E3',
  violetProgressColor: '#483EA8',
  checkboxReportColor: '#2952E1',
  notificationDetailsColor: '#F0F1F980',
  divider: '#0000001F',
  totalNotificationsCountColor: '#DEDBF0',
  newNotificationBgColor: '#EDF5FD',

  //Colors for Criticality
  critical: '#B20000',
  major: '#FF0000',
  frequentAlarm: '#FA5A00',
  alarm: '#FA8700',
  supervisory: '#FAB400',
  event: '#FAD200',
  trouble: '#FDC603',
  minor: '#54BE00',
  other: '#419002',

  eventStatusTrouble: '#0093E5',
  LightDeepBlue: 'rgba(5, 13, 84, 0.10)',
}
