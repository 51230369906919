import { forwardRef } from 'react';

import MuiStack, { StackProps as MuiStackProps } from '@mui/material/Stack';

export type StackProps = MuiStackProps;

/** Stack
 *
 * The Stack component manages layout of immediate children along the vertical or horizontal axis with optional spacing and/or dividers between each child.
 *
 * `import { Stack } from '@fsp-io/shared-ui'`
 */

const Stack = forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  return <MuiStack {...props} ref={ref} />;
});

Stack.displayName = 'Stack';

export default Stack;
