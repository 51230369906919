import { forwardRef } from 'react';

import {
  Container as MuiContainer,
  ContainerProps as MuiContainerProps,
} from '@mui/material';

export type ContainerProps = MuiContainerProps

/** The container centers your content horizontally. It's basic layout element.
 * 
 * `import { Container } from '@fsp-io/shared-ui'`
 */

const Container = forwardRef<HTMLDivElement, ContainerProps>((props, ref) => {
  return <MuiContainer {...props} ref={ref} />;
});

Container.displayName = 'Container';

export default Container;
