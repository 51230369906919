import { StyledComponentDefaultProps } from '../../types/common';

export default {
  container: () =>
    ({
      width: '100%',
      maxWidth: '340px',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: 'auto',
    } as const),
  body: ({ theme }: StyledComponentDefaultProps) => ({
    width: '100%',
    margin: 'auto',
    marginTop: '52px',
    paddingBottom: '20px',

    [theme.breakpoints?.up('sm')]: {
      marginTop: 'auto',
    },
  }),
  footer: {
    margin: 'auto 0 24px',
  },
};
