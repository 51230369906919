import { chipClasses } from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import { ChipProps, StyledChipProps } from './types';
import { StyledComponentDefaultProps } from '../../types/common';

const getLightBackgroundColor = (
  theme: Theme,
  color: ChipProps['color'] = 'default',
) => {
  if (color === 'default') {
    return theme.palette.grey?.['300'];
  }
  if (color === 'primary' || color === 'secondary') {
    return theme.palette[color].outlinedHoverBackground;
  }
  return theme.palette[color].background;
};

const getBorderColor = (
  theme: Theme,
  color: ChipProps['color'] = 'default',
  lightBackground?: boolean,
) => {
  if (color === 'default') {
    return theme.palette.addition?.outlinedBorder;
  }
  return lightBackground
    ? theme.palette[color].outlinedRestingBackground
    : theme.palette[color].main;
};

const getCloseIconColor = (
  theme: Theme,
  color: ChipProps['color'] = 'default',
) =>
  color === 'default' ? theme.palette.action.active : theme.palette[color].dark;

const styles = ({
  theme,
  color = 'default',
  lightBackground,
  isXSmall,
}: StyledChipProps & StyledComponentDefaultProps) => ({
  ...(lightBackground && {
    backgroundColor: getLightBackgroundColor(theme, color),
  }),
  ...(isXSmall && {
    height: '20px',
    padding: '2px',
    [`.${chipClasses.label}`]: {
      fontSize: '0.75rem',
      padding: '0px 6px',
    },
    [`.${chipClasses.icon}, .${chipClasses.deleteIcon}, .${chipClasses.avatar}`]:
      {
        margin: 0,
        width: '16px',
        height: '16px',
      },
  }),
  [`&.${chipClasses.filled}`]: {
    ...(color === 'default' && {
      backgroundColor: theme.palette.grey?.['300'],
    }),
    ...(lightBackground && {
      color:
        color === 'default'
          ? theme.palette.text.primary
          : theme.palette[color].dark,
    }),
  },
  [`&.${chipClasses.outlined}`]: {
    color:
      color === 'default'
        ? theme.palette.text.primary
        : theme.palette[color].dark,
    borderColor: getBorderColor(theme, color, lightBackground),
  },
  [`&.${chipClasses.filled} .${chipClasses.deleteIcon}`]: {
    ...((lightBackground || color === 'default') && {
      color: getCloseIconColor(theme, color),
    }),
  },
  [`&.${chipClasses.outlined} .${chipClasses.deleteIcon}`]: {
    color: getCloseIconColor(theme, color),
  },
});

export default styles;
