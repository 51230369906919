import React, { useState } from 'react';
import { styled } from '@mui/system';

import FormControl from '../../form-control';
import FormControlLabel from '../../form-control-label';
import Checkbox from '../../checkbox';
import Grid from '../../grid';
import Button from '../../button';
import Box from '../../box';
import { ActionsProps } from './types';

import styles from './styles';

const FormControlStyled = styled(FormControl)(styles.checkboxWrapper);

export default function ActionsTermsOfService({
  onClose,
  onAcknowledge,
  onApprove,
  onReject,
  isAcknowledgedExternal,
  labelAcknowledgement = 'I acknowledge that I have read the above and agree to the Terms of Use & Privacy Policy',
  labelApprove,
  labelClose,
  labelReject,
}: ActionsProps) {
  const [acknowledge, setAcknowledge] = useState<boolean>(false);

  return (
    <>
      {!isAcknowledgedExternal ? (
        <FormControlStyled>
          <FormControlLabel
            control={
              <Checkbox
                value={acknowledge}
                role="checkbox-acknowledge"
                onChange={(_, value) => {
                  setAcknowledge(value);
                  onAcknowledge && onAcknowledge(value);
                }}
              />
            }
            label={labelAcknowledgement}
            labelPlacement="end"
          />
        </FormControlStyled>
      ) : (
        <Box />
      )}

      <Grid container direction="row" columnSpacing={1} width="auto">
        <Grid item>
          <Button
            variant="outlined"
            color={isAcknowledgedExternal ? 'primary' : 'secondary'}
            onClick={() => {
              onReject && onReject();
              onClose && onClose();
            }}
          >
            {isAcknowledgedExternal ? labelClose : labelReject}
          </Button>
        </Grid>
        {!isAcknowledgedExternal && (
          <Grid item>
            <Button
              disabled={!acknowledge}
              variant="outlined"
              color="primary"
              onClick={() => {
                onApprove && onApprove();
                onClose && onClose();
              }}
            >
              {labelApprove}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
