import { PATHS } from './paths'

export const TITLES: { [index: string]: string } = {
  [PATHS.managerPlus.index]: 'Manager+',
  [PATHS.sites.index]: 'Sites',
  [PATHS.users.index]: 'Users',
  [PATHS.users.profile]: 'My Profile',

  [PATHS.settings.index]: 'Settings',

  [PATHS.support.index]: 'Support',
}
