import { forwardRef } from 'react';

import {
  AlertTitle as MuiAlertTitle,
  AlertTitleProps as MuiAlertTitleProps,
} from '@mui/material';

export type AlertTitleProps = MuiAlertTitleProps

/** AlertTitle
 * An AlertTitle is used to add a title to the Alert Component. It is used as a sub-component/child along with the Alert Component.
 *
 * `import { AlertTitle } from '@fsp-io/shared-ui'`
 */

const AlertTitle = forwardRef<HTMLDivElement, AlertTitleProps>((props, ref) => {
  return <MuiAlertTitle {...props} ref={ref} />;
});

AlertTitle.displayName = 'AlertTitle';

export default AlertTitle;
