import { forwardRef } from 'react';

import { Step as MuiStep, StepProps as MuiStepProps } from '@mui/material';

export type StepProps = MuiStepProps

/**
 * Step is used as Stepper item. It may contain child components such as StepButton, StepLabel, etc.
 *
 * `import { Step } from '@fsp-io/shared-ui'`
 */

const Step = forwardRef<HTMLDivElement, StepProps>((props, ref) => {
  return <MuiStep {...props} ref={ref} />;
});

Step.displayName = 'Step';

export default Step;
