import { forwardRef } from "react";

import MuiFormHelperText, {
  FormHelperTextProps as MuiFormHelperTextProps,
} from "@mui/material/FormHelperText";

export type FormHelperTextProps = MuiFormHelperTextProps

/** 
 * FormHelperText
 */

const FormHelperText = forwardRef<HTMLParagraphElement, FormHelperTextProps>(
  (props, ref) => {
    return <MuiFormHelperText {...props} ref={ref} />;
  }
);

FormHelperText.displayName = "FormHelperText";

export default FormHelperText;
