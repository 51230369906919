import ReactECharts from 'echarts-for-react';
import echarts from 'echarts';

export type EChartsOption = echarts.EChartsOption;
export interface EChartProps {
  containerID?: string;
  containerHeight?: string | number;
  chartOptions: EChartsOption;
}

/** EChartContainer provides a React container component for the javascript ECharts.
 *
 * `import { EChartContainer } from '@fsp-io/shared-ui'`
 */

export default function EChartContainer({
  containerID = 'echart-container',
  containerHeight = 400,
  chartOptions = {},
}: EChartProps): JSX.Element {
  return (
    <div style={{ height: containerHeight }} id={containerID}>
      <ReactECharts
        option={chartOptions}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
}
