export default {
  container: {
    display: "inline-flex",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    fontSize: "1rem!important",

    "& > *:first-of-type": {
      width: "100%",
      height: "100%",
    },
  },
};
