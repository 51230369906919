import { alpha } from "@mui/material/styles";
import { StyledComponentDefaultProps } from "../../types/common";

export default {
  container: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  formInput: () => ({
    "&.MuiFormControl-root": {
      "&.MuiTextField-root": {
        margin: 0,
        "& .MuiInputBase-colorPrimary": {
          height: "56px",
          borderWidth: 1,
          "& input:-webkit-autofill": {
            WebkitTransitionDelay: "9999999s",
          },
          "& input::-ms-reveal": {
            display: "none !important;",
          },
          "& input::-ms-clear": {
            display: "none !important;",
          },
        },
      },
    },
  }),
  checkboxInput: ({ theme }: StyledComponentDefaultProps) => ({
    width: "max-content",
    "&.MuiFormControlLabel-root": {
      margin: 0,
      "& rect": {
        stroke: alpha(theme.palette.common.black, 0.6),
      },
    },
  }),
  countrySelect: {
    marginBottom: "24px",
    "& button": {
      paddingRight: "8px",
      "& > span": {
        marginRight: "4px",
        "& span + span": {
          paddingLeft: "4px",
        },
      },
    },
  },
  submitBtn: () => ({
    "&.MuiButton-root": {
      fontWeight: 700,
      marginTop: "36px",
    },
  }),
};
