import { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";

import InputAdornment, { InputAdornmentProps } from "../input-adornment";
import Button from "../button";

const InputAdornmentStyled = styled(InputAdornment)({
  "& > button.MuiButton-root": {
    fontSize: "20px",
    minWidth: "20px",
    height: "20px",
    padding: "0",
    position: "absolute",
    right: "36px",
    paddingRight: "0",
  },
});

export interface ClearButtonEndAdornmentProps extends InputAdornmentProps {
  handleClear: () => void;
}


/** ClearButtonEndAdornment
 *
 * `import ClearButtonEndAdornment from '@fsp-io/ClearButtonEndAdornment'`
 * 
 * To see Usage Example, check `Select` storybook
 */

const ClearButtonEndAdornment = forwardRef<
  HTMLDivElement,
  ClearButtonEndAdornmentProps
>(({ handleClear, ...rest }: ClearButtonEndAdornmentProps, ref) => (
  <InputAdornmentStyled className="endAdornment" ref={ref} {...rest}>
    <Button
      variant="text"
      onClick={handleClear}
      data-testid="test-button-clear"
    >
      <Close color="action" fontSize="inherit" />
    </Button>
  </InputAdornmentStyled>
));

ClearButtonEndAdornment.displayName = "ClearButtonEndAdornment";

export default ClearButtonEndAdornment;
