import { forwardRef } from 'react';

import MuiTabPanel, {
  TabPanelProps as MuiTabPanelProps,
} from '@mui/lab/TabPanel';

export type TabPanelProps = MuiTabPanelProps

/** TabPanel
 *
 * `import { TabPanel } from '@fsp-io/shared-ui'`
 */

const TabPanel = forwardRef<unknown, TabPanelProps>((props, ref) => {
  return <MuiTabPanel {...props} ref={ref} />;
});

TabPanel.displayName = 'TabPanel';

export default TabPanel;
