import { SelectChangeEvent } from '@mui/material'
import { useField } from 'formik'

import Checkbox from '../checkbox'
import FormControl from '../form-control'
import InputLabel from '../input-label'
import ListItemText from '../list-item-text'
import MenuItem from '../menu-item'
import Select from '../select'

interface MultiSelectFieldProps {
  name: string
  type?: string
  label: string
  options: Array<string | { name: string; value: string }>
  loading?: boolean
  boldLabels?: boolean
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const FormikMultiSelect = ({
  name,
  label,
  options,
  loading,
  type,
  boldLabels,
  ...props
}: MultiSelectFieldProps): JSX.Element => {
  const [field, , { setValue }] = useField(name)

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    setValue(typeof value === 'string' ? value.split(',') : value)
  }

  const renderValue = (selected: unknown): React.ReactNode => {
    if (Array.isArray(selected)) {
      const selectedValues = selected as Array<string | number>
      const selectedNames = selectedValues.map((value) => {
        if (typeof options[0] === 'object') {
          const option = options.find(
            (option) => typeof option === 'object' && option.value === value
          )
          return option && typeof option === 'object' ? option.name : value
        } else {
          return value
        }
      })
      return selectedNames.join(', ')
    }
    return ''
  }

  return (
    <FormControl
      sx={{
        minWidth: 120,
        width: '100%',
      }}
      variant="filled"
    >
      <InputLabel
        id="multiple-checkbox-label"
        sx={{
          marginTop: '-5px',
          ...(boldLabels ? { fontWeight: 'bold' } : {}),
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="multiple-checkbox-label"
        size="small"
        multiple
        showBorder
        hideBackgroundColor
        label={label}
        id={`${field.name}-multiselect`}
        data-testid={`${field.name}-multiselect`}
        disabled={loading}
        {...field}
        {...props}
        renderValue={renderValue}
        value={field.value || []}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        {options.map((option) => {
          const isObject = typeof option === 'object'
          const itemName = isObject ? option.name : option
          const value = isObject ? option.value : option
          return (
            <MenuItem key={itemName} value={value}>
              <Checkbox checked={field.value.indexOf(value) > -1} />
              <ListItemText primary={itemName} />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default FormikMultiSelect
