import React, { createElement } from 'react';

import Typography from '../../typography';
import { ContentProps } from './types';

export default function Content({ content }: ContentProps) {
  if (typeof content !== 'string' && typeof content === 'function') {
    return createElement(content);
  }

  return <Typography variant="body2">{content}</Typography>;
}
