import { useEffect, useMemo, useState } from 'react'

import { useCurrentUserQuery } from '@/__generated__/graphql'

export enum ActionType {
  READ = 'read',
  MANAGE = 'manage',
  CREATE = 'create',
  UPDATE = 'update',
}

interface Permissions {
  action: ActionType
  subject: string[]
}

export enum Component {
  SITE = 'Site',
  MANAGER_PLUS = 'Manager+',
  ADMIN_PLUS = 'Admin+',
  USER = 'User',
  TENANT = 'Tenant',
  ASSIGN_USERS = 'AssignUsers',
}

const useGetPermissions = () => {
  const [permissions, setPermissions] = useState<Permissions[]>([])

  const { data } = useCurrentUserQuery({
    context: {
      clientName: 'users',
    },
  })

  // Getting user permissions
  useEffect(() => {
    if (!permissions.length && data) {
      const currentPermissions: Permissions[] = JSON.parse(
        data.currentUser.permissions
      )
      setPermissions(currentPermissions)
    }
  }, [data, permissions])
  return permissions
}

export const useAccessPermissions = (
  action: ActionType,
  component: Component
) => {
  const permissions = useGetPermissions()

  let accessAllowed = false

  if (permissions.length) {
    // checking if user has the permission to view this
    const desiredAction = permissions.find((obj) => obj.action === action)

    accessAllowed = desiredAction?.subject.includes(component) || false
  }

  return useMemo(() => accessAllowed, [accessAllowed])
}
