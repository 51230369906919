import React from 'react'

export const InfoIconLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M18 10H22V14H18V10ZM18 18H22V30H18V18ZM20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z"
      fill="#4C91D0"
    />
  </svg>
)
