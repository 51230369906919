import { forwardRef } from "react";

import MuiInputAdornment, {
  InputAdornmentProps as MuiInputAdornmentProps,
} from "@mui/material/InputAdornment";

export type InputAdornmentProps = Omit<MuiInputAdornmentProps, "classes">

const InputAdornment = forwardRef<HTMLDivElement, InputAdornmentProps>(
  (props, ref) => {
    return <MuiInputAdornment {...props} ref={ref} />;
  }
);

InputAdornment.displayName = "InputAdornment";

export default InputAdornment;
