import FullPageLoader from '@/app/components/full-page-loader';
import Layout from '@/app/components/layout/Layout';
import {
  LazyLoadedPageNotFound,
  LazyLoadedSupportPage,
} from '@/app/routes/LazyLoadedRoutes';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const SupportRoutes: React.FC = () => {
  return (
    <Layout>
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route index element={<LazyLoadedSupportPage />} />
          <Route path="*" index element={<LazyLoadedPageNotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default React.memo(SupportRoutes);
