import React, { FC, ReactNode } from 'react'

import { FleetLocalizationProvider } from '@industrial-plus/shared-ui'
import {
  FleetThemeProvider,
  fleetThemeOptions,
} from '@industrial-plus/shared-ui/theme'
import {
  StyledEngineProvider,
  ThemeOptions,
  createTheme,
} from '@mui/material/styles'

import { COLORS } from '@/constants/colors'

import TYPOGRAPHY, {
  FONT_FAMILY,
} from './font-configurations/TypographyConfigurations'

interface Colors {
  primary50: React.CSSProperties['color']
  primary100: React.CSSProperties['color']
  primary200: React.CSSProperties['color']
  primary300: React.CSSProperties['color']
  primary400: React.CSSProperties['color']
  primary500: React.CSSProperties['color']
  primary600: React.CSSProperties['color']
  primary700: React.CSSProperties['color']
  primary800: React.CSSProperties['color']
  primary900: React.CSSProperties['color']

  gray50: React.CSSProperties['color']
  gray100: React.CSSProperties['color']
  gray200: React.CSSProperties['color']
  gray250: React.CSSProperties['color']
  gray300: React.CSSProperties['color']
  gray400: React.CSSProperties['color']
  gray500: React.CSSProperties['color']
  gray600: React.CSSProperties['color']
  gray700: React.CSSProperties['color']
  gray800: React.CSSProperties['color']
  gray900: React.CSSProperties['color']

  warning50: React.CSSProperties['color']
  warning100: React.CSSProperties['color']
  warning200: React.CSSProperties['color']
  warning300: React.CSSProperties['color']
  warning400: React.CSSProperties['color']
  warning500: React.CSSProperties['color']
  warning600: React.CSSProperties['color']
  warning700: React.CSSProperties['color']
  warning800: React.CSSProperties['color']
  warning900: React.CSSProperties['color']
  warningA100: React.CSSProperties['color']
  warningA200: React.CSSProperties['color']
  warningA300: React.CSSProperties['color']
  warningA700: React.CSSProperties['color']

  success50: React.CSSProperties['color']
  success100: React.CSSProperties['color']
  success200: React.CSSProperties['color']
  success300: React.CSSProperties['color']
  success400: React.CSSProperties['color']
  success500: React.CSSProperties['color']
  success600: React.CSSProperties['color']
  success700: React.CSSProperties['color']
  success800: React.CSSProperties['color']
  success900: React.CSSProperties['color']
  successA100: React.CSSProperties['color']
  successA200: React.CSSProperties['color']
  successA300: React.CSSProperties['color']
  successA700: React.CSSProperties['color']

  error50: React.CSSProperties['color']
  error100: React.CSSProperties['color']
  error200: React.CSSProperties['color']
  error300: React.CSSProperties['color']
  error400: React.CSSProperties['color']
  error500: React.CSSProperties['color']
  error600: React.CSSProperties['color']
  error700: React.CSSProperties['color']
  error800: React.CSSProperties['color']
  error900: React.CSSProperties['color']
  errorA100: React.CSSProperties['color']
  errorA200: React.CSSProperties['color']
  errorA300: React.CSSProperties['color']
  errorA700: React.CSSProperties['color']

  natural200: React.CSSProperties['color']
  white: React.CSSProperties['color']
  black: React.CSSProperties['color']
  phoneVerified: React.CSSProperties['color']
  phoneNotVerified: React.CSSProperties['color']
  textDark: React.CSSProperties['color']
  eventStatusTrouble: React.CSSProperties['color']
  LightDeepBlue: React.CSSProperties['color']
  divider: React.CSSProperties['color']
  notificationDetailsColor: React.CSSProperties['color']
  totalNotificationsCountColor: React.CSSProperties['color']
  tagBgColor: React.CSSProperties['color']
  newNotificationBgColor: React.CSSProperties['color']
}

interface Sidebar {
  openWidth: React.CSSProperties['width']
  closedWidth: React.CSSProperties['width']
}

declare module '@mui/material/styles' {
  interface Theme {
    sidebar: Sidebar
    colors: Colors
    devicetreeBar: Sidebar
  }
}

interface CustomThemeOptions extends ThemeOptions {
  sidebar: Sidebar
  colors: Colors
}

const customTheme: CustomThemeOptions = {
  //   palette: customPalette, //TODO: Check how to add colors to theme
  typography: TYPOGRAPHY,
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          // color: COLORS.black,
          minHeight: 40,
          padding: '12px 16px !important',
          fontFamily: FONT_FAMILY,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          fontFamily: FONT_FAMILY,
          minHeight: 40,
          '.MuiTabs-indicator': {
            backgroundColor: COLORS.primary600,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontFamily: FONT_FAMILY,
          fontWeight: 400,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '12px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            fill: COLORS.primary600,
            color: COLORS.primary600,
          },
          '&.Mui-checked': {
            fill: COLORS.primary600,
            color: COLORS.primary600,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          ':disabled': {
            backgroundColor: COLORS.gray200,
            overflow: 'hidden',
          },
          padding: '0px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          fontFamily: FONT_FAMILY,
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '18px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: `${COLORS.gray800} !important`,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingLeft: '4px !important',
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 72,
      '@media (min-width: 600px)': {
        minHeight: 74,
      },
    },
  },
  sidebar: {
    openWidth: 240,
    closedWidth: 96,
  },
  colors: COLORS,
}

const combinedTheme = createTheme(fleetThemeOptions, customTheme)

interface ThemeProviderProps {
  children: ReactNode
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => (
  <FleetLocalizationProvider>
    <StyledEngineProvider injectFirst>
      <FleetThemeProvider theme={combinedTheme}>{children}</FleetThemeProvider>
    </StyledEngineProvider>
  </FleetLocalizationProvider>
)
