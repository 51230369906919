import { Theme } from "@mui/material/styles";

// Common box styles for the upload components
const defaultBox = (
  theme: Theme,
  inheritBackgroundColor?: boolean,
  error?: boolean
) => ({
  padding: "6px 12px 6px 6px",
  width: "auto",
  backgroundColor: inheritBackgroundColor
    ? "inherit!important"
    : theme.palette.background.paper,
  border: error
    ? `1.4px dashed ${theme.palette.error.dark}`
    : `1.4px dashed ${theme.palette.addition?.outlinedBorder}`,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    border: error
      ? `1.4px dashed ${theme.palette.error.dark}`
      : `1.4px dashed ${theme.palette.addition?.outlinedBorder}`,
  },
  borderRadius: "4px",
});

export const commonStyles = {
  fileUpload: (theme: Theme, inheritBackgroundColor?: boolean) => ({
    ...defaultBox(theme, inheritBackgroundColor),
  }),
  dragDropUpload: (
    theme: Theme,
    size?: string,
    inheritBackgroundColor?: boolean,
    error?: boolean
  ) => ({
    ...defaultBox(theme, inheritBackgroundColor, error),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: size,
  }),
  listItem: {
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.addition?.divider}`,
    ".MuiListItemSecondaryAction-root": {
      right: "12px",
    },
    "&:hover": {
      backgroundColor: (theme: Theme) => theme.palette.action.hover,
    },
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
  },
};
