import React, { useEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { useOktaAuth } from '@okta/okta-react'

import { PATHS } from '@/constants/paths'

interface Props {
  children: React.ReactElement
}

const SecuredRoute: React.FC<Props> = (props: Props) => {
  const { authState } = useOktaAuth()
  const location = useLocation()
  const navigate = useNavigate()

  const [navigateToLogin, setNavigateToLogin] = useState(false)

  useEffect(() => {
    if (navigateToLogin) {
      navigate(PATHS.auth.login)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateToLogin])

  if (!authState) {
    return null
  }

  if (!authState.isAuthenticated) {
    if (!navigateToLogin && location.pathname !== PATHS.auth.login) {
      setNavigateToLogin(true)
    }

    return null
  }

  return props.children
}

export default SecuredRoute
