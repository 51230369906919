import { ThemedStyledComponent } from "../types";

export default {
  dialogTitle: {
    m: 0,
    p: 2,
  },
  buttonClose: ({ theme }: ThemedStyledComponent) =>
    ({
      position: "absolute",
      right: 16,
      top: 12,
      color: theme?.palette.grey[500],
    } as const),
};
