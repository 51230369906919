import React, { useEffect, useState } from 'react'

import { onMessageListener } from 'firebase'

import { getUserLogged } from '@/graphql/client'
import {
  PushNotification,
  useNotificationProviderContext,
} from '@/providers/NotificationProvider'

const FCMNotificationContextComp: React.FC = () => {
  const [payload, setPayload] = useState<any>() //TO UPDATE: create interface
  const { setNotification, notification } = useNotificationProviderContext()

  useEffect(() => {
    if (payload && payload.data) {
      setNotification({
        ...notification,
        [payload.messageId]: payload as PushNotification,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload])

  if (window.BroadcastChannel) {
    const channel4Broadcast = new BroadcastChannel('channel4')

    channel4Broadcast.onmessage = (event) => {
      const backgroundPayload = event.data.key

      let additionalInfo = backgroundPayload.additionalInfo
      if (additionalInfo) {
        additionalInfo = JSON.parse(event.data.key)
      }

      const userLogged = getUserLogged()

      if (!userLogged) {
        return
      }

      setPayload(backgroundPayload)
    }
  }

  onMessageListener()
    .then((payload) => {
      const userLogged = getUserLogged()
      if (!userLogged) return
      // console.log('Received foreground message: ', payload)

      setPayload(payload)
    })
    .catch((err) => console.log('Notification failed: ', err)) //TO UPDATE: if needed snackbar

  return null
}

export default React.memo(FCMNotificationContextComp)
