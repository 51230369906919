import { forwardRef, ElementType } from 'react';

import {
  SvgIcon as MuiSvgIcon,
  SvgIconProps as MuiSvgIconProps,
} from '@mui/material';

export interface SvgIconProps extends MuiSvgIconProps {
  /**
   *
   * The component used for the root node. Either a string to use a HTML element or a component.
   */
  component?: ElementType;
  inheritViewBox?: boolean;
}

/** SvgIcon extends the native svg element:
 *
 * It comes with built-in accessibility.
 *
 * SVG elements should be scaled for a 24x24px viewport so that the resulting icon can be used as is,
 * or included as a child for other MUI components that use icons.
 * This can be customized with the viewBox attribute.
 * To inherit the viewBox value from the original image,
 * the inheritViewBox attribute can be used.
 *
 * By default, the component inherits the current color.
 * Optionally, you can apply one of the theme colors using the color prop.
 *
 * `import { SvgIcon } from '@fsp-io/shared-ui'`
 */

const SvgIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return <MuiSvgIcon {...props} ref={ref} />;
});

SvgIcon.displayName = 'SvgIcon';

export default SvgIcon;
