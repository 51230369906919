import { IconButton, List } from '@industrial-plus/shared-ui'
import {
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

interface DrawerProps extends MuiDrawerProps {
  open?: boolean
}

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'absolute',
    whiteSpace: 'nowrap',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    width: theme.sidebar.openWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: theme.zIndex.drawer + 2,
    '.MuiListItemIcon-root': {
      transition: 'margin-left 1s, margin-right 1s',
    },
    '& .MuiListItemIcon-root': {
      marginLeft: 12,
      marginRight: 12,
    },
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.sidebar.closedWidth,
      [theme.breakpoints.up('sm')]: {
        width: theme.sidebar.closedWidth,
      },
    }),
    ...(open && {
      [theme.breakpoints.between('sm', 'lg')]: {
        boxShadow: theme.shadows[24],
      },
    }),
  },
}))

export const ToolbarIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 8px',
  ...theme.mixins.toolbar,
  cursor: 'pointer',
}))

export const StyledList = styled(List)(({ theme }) => ({
  // selected and (selected + hover) states
  '&& .Mui-selected': {
    backgroundColor: theme.colors.gray200,
    borderRadius: '6px',
  },
  // hover states
  '& .MuiListItemButton-root:hover, && .Mui-selected:hover': {
    background: theme.colors.gray300,
    borderRadius: '6px',
  },
}))

export const SideNavUpperList = styled(StyledList)`
  flex: 1;
  padding-top: 1rem;
`

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  ':hover': {
    backgroundColor: theme.colors.gray300,
  },
}))
