// import { getUserLogged } from 'helpers';
import React, { createContext, ReactNode, useContext, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { TITLES } from '@/constants/titles'

interface RouteProps {
  path: string
  params: {
    id?: string
  }
}

interface BreadcrumbsProviderProps {
  children?: ReactNode
}

export interface CustomBreadcrumbsData {
  title: string
  to?: string
  params?: any
}

export interface ExtraIconData {
  icon: string
  to?: string
}

interface BreadcrumbsContextData {
  title: string
  setTitle(title: string): void
  loading: boolean
  setLoading(loading: boolean): void
  customTitles: CustomBreadcrumbsData[]
  setCustomTitles(titles: CustomBreadcrumbsData[] | null): void
  titleOnMobile: string
  setTitleOnMobile(title: string): void
  mobileBackButton: string
  setMobileBackButton(title: string): void
  mobileBackButtonPressed(): void
  extraIcon: ExtraIconData | null
  setExtraIcon(extraIcon: ExtraIconData | null): void
  extraIconPressed(): void
  resetBreadcrumbsStates(): void
  isSavingChanges: boolean
  setIsSavingChanges(saving: boolean): void
  mobileTitleIcon: string
  setMobileTitleIcon(icon: string): void
  mobileShowInfo: boolean
  onHandleMobileTitleIcon(): void
  isFCMNotificationStatus: boolean
  setFCMNotificationStatus(status: boolean): void
}

const BreadcrumbsContext = createContext<BreadcrumbsContextData>(
  {} as BreadcrumbsContextData
)

export const BreadcrumbsProvider = ({
  children,
}: BreadcrumbsProviderProps): JSX.Element => {
  const history = useNavigate()
  const { pathname } = useLocation()
  // const { path } = useMatch(pathname);
  const [breadcrumbsTitle, setBreadcrumbsTitle] = useState<string>(
    TITLES[pathname]
  )
  const [breadcrumbsTitleLoading, setBreadcrumbsTitleLoading] =
    useState<boolean>(false)

  const [customBreadcrumbs, setCustomBreadcrumbs] = useState<
    CustomBreadcrumbsData[]
  >([])

  const [titleOnMobile, setTitleOnMobile] = useState<string>('')
  const [mobileBackButton, setMobileBackButton] = useState<string>('')

  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false)

  const [isFCMNotificationStatus, setFCMNotificationStatus] =
    useState<boolean>(false)

  const [extraIcon, setExtraIcon] = useState<ExtraIconData | null>(null)

  const [mobileTitleIcon, setMobileTitleIcon] = useState<string>('')

  const [mobileShowInfo, setMobileShowInfo] = useState<boolean>(true)

  function onHandleMobileTitleIcon() {
    if (mobileShowInfo) {
      setMobileTitleIcon('fas fa-chevron-down')
      setMobileShowInfo(false)
    } else {
      setMobileTitleIcon('fas fa-chevron-up')
      setMobileShowInfo(true)
    }
  }

  async function setLoading(loading: boolean) {
    setBreadcrumbsTitleLoading(loading)
  }

  async function setTitle(title: string) {
    setBreadcrumbsTitleLoading(false)
    setBreadcrumbsTitle(title)
  }

  async function setCustomTitles(customBreadcrumbs: CustomBreadcrumbsData[]) {
    setBreadcrumbsTitleLoading(false)
    // const isTenant = sessionStorage.getItem('tenantName');
    // const tenantId = sessionStorage.getItem('tenantId');

    // // const location = useLocation();
    // const pathnames = pathname
    //   ? pathname.split('/').filter((path: string) => path)
    //   : ['/'];

    // if (pathnames.length === 2 && isNumber(pathname[1])) {
    //   customBreadcrumbs = [{ title: 'Try Custom' }];
    // }
    // const userInfo = getUserLogged();
    // if (
    //   userInfo?.isGlobalTechSupport &&
    //   isTenant &&
    //   tenantId &&
    //   customBreadcrumbs?.length > 0
    // ) {
    //   customBreadcrumbs = [
    //     {
    //       title: 'Tenants',
    //       to: '/tenants',
    //     },
    //     {
    //       title: isTenant,
    //       to: `/tenants/${tenantId}`,
    //     },
    //     ...customBreadcrumbs,
    //   ];
    // }
    setCustomBreadcrumbs(customBreadcrumbs)
  }

  async function mobileBackButtonPressed() {
    history(mobileBackButton)
    setMobileBackButton('')
    setTitleOnMobile('')
  }

  async function extraIconPressed() {
    if (extraIcon && extraIcon.to) {
      history(extraIcon.to)
      setExtraIcon(null)
    }
  }

  async function resetBreadcrumbsStates() {
    setCustomTitles([])
    setTitleOnMobile('')
    setExtraIcon(null)
  }

  return (
    <BreadcrumbsContext.Provider
      value={{
        title: breadcrumbsTitle,
        setTitle,
        loading: breadcrumbsTitleLoading,
        setLoading,
        customTitles: customBreadcrumbs,
        setCustomTitles,
        titleOnMobile,
        setTitleOnMobile,
        mobileBackButton,
        setMobileBackButton,
        mobileBackButtonPressed,
        extraIcon,
        setExtraIcon,
        extraIconPressed,
        resetBreadcrumbsStates,
        isSavingChanges,
        setIsSavingChanges,
        mobileTitleIcon,
        setMobileTitleIcon,
        mobileShowInfo,
        onHandleMobileTitleIcon,
        isFCMNotificationStatus,
        setFCMNotificationStatus,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export const useBreadcrumbsContext = () => {
  return useContext(BreadcrumbsContext)
}

export default BreadcrumbsContext
