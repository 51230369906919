import React from 'react'

import { Navigate, Outlet } from 'react-router-dom'

import { UserRoles, useCurrentUserQuery } from '@/__generated__/graphql'

interface Props {
  accessRoles: UserRoles[]
  defaultPath?: string
}

const AccessOutlet: React.FC<Props> = (props: Props) => {
  const { data } = useCurrentUserQuery({
    context: {
      clientName: 'users',
    },
  })
  const userRole = data?.currentUser?.user?.role

  if (!props.accessRoles.includes(userRole as UserRoles)) {
    return (
      <Navigate
        to={props.defaultPath !== undefined ? props.defaultPath : '/'}
      />
    )
  }

  return <Outlet />
}

export default React.memo(AccessOutlet)
