import React, { useState } from 'react';
import { styled } from '@mui/system';

import { TermsOfServiceProps } from './types';
import Dialog from '../dialog';
import DialogContent from '../dialog-content';
import DialogActions from '../dialog-actions';
import { TABS_CONFIGURATION } from './constants';
import EntryLink from './entry-link';
import Title from './title';
import ActionsTermsOfService from './action-terms-of-service';
import DynamicTabs from './dynamic-tabs';

import styles from './styles';

const DialogStyled = styled(Dialog)(styles.dialog);
const DialogContentStyled = styled(DialogContent)(styles.dialogContent);
const DialogActionsStyled = styled(DialogActions)(styles.dialogActions);

/** TermsOfService
 *
 * TermsOfService is used to review and/or accept/reject privacy policy, terms of usage agreements.
 *
 * `import { TermsOfService } from '@fsp-io/shared-ui'`
 */
export default function TermsOfService({
  labelEntry = 'Terms of Service',
  labelApprove = 'Accept',
  labelDownloadPDF = 'Download as PDF',
  labelReject = 'Decline',
  labelClose = 'Close',
  labelAcknowledgement,
  onAcknowledge,
  onApprove,
  onReject,
  open: isOpenExternal = false,
  title = 'Terms of Use & Privacy Policy',
  tabsConfiguration = TABS_CONFIGURATION,
  acknowledge: isAcknowledgedExternal = false,
  sxEntryLabel = {},
  sxDialog = {},
  sxDialogTitle = {},
  sxDialogContent = {},
  sxDialogActions = {},
}: TermsOfServiceProps) {
  const [open, setOpen] = useState<boolean>(isOpenExternal);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <EntryLink label={labelEntry} onClick={handleOpen} sx={sxEntryLabel} />
      <DialogStyled
        id="terms-of-service-dialog"
        onClose={handleClose}
        open={open}
        maxWidth="lg"
        sx={sxDialog}
      >
        <Title onClose={handleClose} sx={sxDialogTitle}>
          {title}
        </Title>
        <DialogContentStyled sx={sxDialogContent}>
          <DynamicTabs
            tabsConfiguration={tabsConfiguration}
            labelDownloadPDF={labelDownloadPDF}
          />
        </DialogContentStyled>
        <DialogActionsStyled sx={sxDialogActions}>
          <ActionsTermsOfService
            isAcknowledgedExternal={isAcknowledgedExternal}
            labelAcknowledgement={labelAcknowledgement}
            labelClose={labelClose}
            labelApprove={labelApprove}
            labelReject={labelReject}
            onAcknowledge={onAcknowledge}
            onApprove={onApprove}
            onClose={handleClose}
            onReject={onReject}
          />
        </DialogActionsStyled>
      </DialogStyled>
    </>
  );
}
