import { forwardRef } from 'react';

import { List as MuiList, ListProps as MuiListProps } from '@mui/material';

export type ListProps = MuiListProps

/** List
 *
 * `import { List } from '@fsp-io/shared-ui'`
 */

const List = forwardRef<HTMLUListElement, ListProps>((props, ref) => {
  return <MuiList {...props} ref={ref} />;
});

List.displayName = 'List';

export default List;
