import { forwardRef } from "react";

import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from "@mui/material";

export type DividerProps = MuiDividerProps

declare module "@mui/material/Divider" {
  interface DividerPropsVariantOverrides {
    padded: true;
    middle: false;
    fill: true;
  }
}

/**
 * A divider is a thin line that groups content in lists and layouts.
 */

const Divider = forwardRef<HTMLHRElement, DividerProps>((props, ref) => {
  return <MuiDivider {...props} ref={ref} />;
});

Divider.displayName = "Divider";

export default Divider;
