import { forwardRef, Ref } from 'react';
import { omit } from 'lodash-es';

import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { getSxStyles } from '../../utils/styles.utils';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    xsmall: true;
  }
}
export interface IconButtonProps extends MuiIconButtonProps {
  /**
   *
   * A ref that points to the TouchRipple element.
   */
  touchRippleRef?: Ref<TouchRippleActions>;
}

/** Icon Button
 *
 * `import IconButton from '@fsp-io/IconButton'`
 */

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ disableTouchRipple = true, disableRipple = true, ...props }, ref) => {
    const setHoverBg = () => {
      switch (props.color) {
        case 'primary':
          return (theme: Theme) =>
            theme.palette.primary.main;
        case 'secondary':
          return (theme: Theme) =>
            theme.palette.secondary.main;
        case 'error':
          return (theme: Theme) => theme.palette.error.main;
        case 'info':
          return (theme: Theme) => theme.palette.info.main;
        case 'success':
          return (theme: Theme) =>
            theme.palette.success.main;
        case 'warning':
          return (theme: Theme) =>
            theme.palette.warning.main;
        default:
          return (theme: Theme) => theme.palette.action.hover;
      }
    };

    const setPadding = () => {
      switch (props.size) {
        case 'large':
          return '8px';
        case 'medium':
          return '6px';
        case 'small':
          return '6px';
        default:
          return '4px';
      }
    };
    return (
      <MuiIconButton
        data-testid="test-iconButton"
        disableTouchRipple={disableTouchRipple}
        disableRipple={disableRipple}
        {...omit(props, ['sx'])}
        sx={(theme) =>
          ({
            padding: setPadding(),
            ...getSxStyles(theme, props.sx),
            '&:hover': {
              backgroundColor: setHoverBg(),
            },
          } as SystemStyleObject)
        }
        ref={ref}
      />
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
