import styled from '@emotion/styled';
import { lighten } from '@mui/material';

// * INFO: Setting Default Colors for the library component
const COLORS = {
  dateHighlight: '#533CDC',
  dateSelected: '#270ADC',
  gray100: '#DDDBDA',
  gray200: '#333333',
  dateRangeSelection: '#DEDBF0'
};

export const DateRangeWrapper = styled.div`
  border: 1px solid ${COLORS.gray100};
  display: inline-block;
  margin: 0 1rem;

  &:focus: {
    border: 1px solid ${COLORS.dateSelected};
  }

  .DateRangePicker {
    padding: 0 1rem;

    &Input {
      &_arrow {
        margin-left: 0.5rem;
        margin-right: 0.75rem;
      }

      &_calendarIcon,
      &_clearDates {
        margin: 0;
      }

      &_calendarIcon {
        &_svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .DateInput {
    width: 6.25rem;

    &_input {
      padding: 0.4rem 0 0.4rem 0.6rem;
      font-size: 0.9rem;
      border-radius: 4px;
      font-family: 'Red Hat Display', sans-serif;

      &__focused {
        border-bottom: 2px solid ${COLORS.dateSelected};
      }
    }
  }

  .CalendarMonth {
    font-family: 'Red Hat Display', sans-serif;
  }

  .CalendarDay {
    font-family: 'Red Hat Display', sans-serif;

    &__firstDayOfWeek {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &__lastDayOfWeek {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &__selected {
      &,
      &:hover {
        background: ${COLORS.dateSelected};
        color: white;
        border: 1px solid ${COLORS.dateSelected};
      }

      &_start {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &_end {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      &_span {
        &,
        &:hover {
          background: ${COLORS.dateRangeSelection};
          color: ${COLORS.gray200};
          border: 1px solid ${COLORS.dateSelected};
        }
      }
    }

    &__hovered_span {
      &,
      &:hover {
        background: ${COLORS.dateRangeSelection};
        color: ${COLORS.gray200};
        border: 1px solid ${COLORS.dateSelected};
      }
    }

    // Removing Borders
    &__default {
      border: none;
    }

    &__selected {
      &,
      &:hover {
        border: none;
      }

      &_span {
        &,
        &:hover {
          border: none;
        }
      }
    }

    &__hovered_span {
      &,
      &:hover {
        border: none;
      }
    }
  }
`;

export const StyledButton = styled.button<{isActive: boolean}>`
  color: ${props => props.isActive ? '#FFFFFF' : COLORS.gray200};
  background-color: ${props => props.isActive ? COLORS.dateSelected : 'rgb(160, 147, 235)'};
  margin: 5px;
  padding: 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: ${COLORS.dateHighlight} 0.7s;
  &:hover {
    background-color: ${COLORS.dateHighlight};
  };
`;