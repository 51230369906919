import { forwardRef } from 'react';

import MuiTableFooter, {
  TableFooterProps as MuiTableFooterProps,
} from '@mui/material/TableFooter';

export interface TableFooterProps extends MuiTableFooterProps {}

/** TableFooter
 *
 * `import { TableFooter } from '@fsp-io/shared-ui'`
 */

const TableFooter = forwardRef<HTMLTableSectionElement, TableFooterProps>(
  (props, ref) => {
    return <MuiTableFooter {...props} ref={ref} />;
  },
);

TableFooter.displayName = 'TableFooter';

export default TableFooter;
