import ReactGA from 'react-ga4'

import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: process.env.NX_API_KEY,
  authDomain: process.env.NX_AUTH_DOMAIN,
  projectId: process.env.NX_PROJECT_ID,
  storageBucket: process.env.NX_STORAGE_BUCKET,
  messagingSenderId: process.env.NX_MESSAGING_SENDER_ID,
  appId: process.env.NX_APP_ID,
  measurementId: process.env.NX_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(firebaseApp)

ReactGA.initialize(process.env.NX_MEASUREMENT_ID, {
  gaOptions: {
    cookieDomain: 'none',
  },
})

export const sendEvent = (value) => {
  logEvent(analytics, value)
}

export const sendGAPageView = (pageViewObject) => {
  ReactGA.send({
    hitType: 'pageview',
    page: pageViewObject.pathname + pageViewObject.search,
  })
  logEvent(analytics, 'page_view', {
    page_title: pageViewObject.pathname,
    page_location: pageViewObject.pathname + pageViewObject.search,
    page_path: pageViewObject.pathname,
  })
}

export const sendGAPageEvent = (pageEventObject) => {
  ReactGA.event(pageEventObject)
}

export const setGAUserId = (userId) => {
  ReactGA.set({ userId: userId })
  setUserId(analytics, userId)
}

export const fetchToken = async (setTokenFound) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.NX_VAPID_KEY,
    })

    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // console.log('current token for client: ', currentToken);
      setTokenFound(currentToken)
    } else {
      // Show permission request UI
      console.log(
        'No registration token available. Request permission to generate one.'
      )
      setTokenFound('')
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err)
    // catch error while creating client token
    setTokenFound('')
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
