import { forwardRef } from 'react';

import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { getSxStyles } from '../../utils/styles.utils';

export type SkeletonProps = MuiSkeletonProps

/** Skeleton
 * Display a placeholder preview of your content before the data gets loaded to reduce load-time frustration.
 *
 * `import { Skeleton } from '@fsp-io/shared-ui'`
 */

const Skeleton = forwardRef<HTMLSpanElement, SkeletonProps>((props, ref) => {
  return (
    <MuiSkeleton
      sx={(theme) =>
        ({
          ...getSxStyles(theme, props.sx),
        } as SystemStyleObject)
      }
      {...props}
      ref={ref}
    />
  );
});

Skeleton.displayName = 'Skeleton';

export default Skeleton;
