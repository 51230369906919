import { styled } from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';
import Tooltip, { TooltipProps } from '../tooltip';

//Custom Primary Tooltip for FDS Slider
export const SliderPrimaryTooltip = styled(
  ({ className, style, sx, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      style={style}
      sx={sx}
      arrow
    />
  ),
)(({ theme, style }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      style?.backgroundColor ?? theme.palette.primary.contrastText,
    border: style?.border ?? '1px solid',
    borderColor: style?.borderColor ?? theme.palette.primary.main,
    borderRadius: style?.borderRadius ?? 4,
    color: style?.color ?? theme.palette.primary.main,
    fontSize: style?.fontSize ?? 15,
    height: style?.height ?? 22,
    minWidth: style?.minWidth ?? 20,
    paddingTop: style?.paddingTop ?? 10,
    textAlign: style?.textAlign ?? 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    [`&::before`]: {
      border: style?.border ?? '1px solid',
      borderColor: style?.borderColor ?? theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

//Custom Secondary Tooltip for FDS Slider
export const SliderSecondaryTooltip = styled(
  ({ className, sx, style, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      style={style}
      sx={sx}
      arrow
    />
  ),
)(({ theme, style }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      style?.backgroundColor ?? theme.palette.secondary.contrastText,
    border: style?.border ?? '1px solid',
    borderColor: style?.borderColor ?? theme.palette.addition?.deliver,
    borderRadius: style?.borderRadius ?? 4,
    color: style?.color ?? theme.palette.common.black,
    fontSize: style?.fontSize ?? style?.fontSize ?? 15,
    height: style?.height ?? style?.height ?? 22,
    minWidth: style?.minWidth ?? 20,
    paddingTop: style?.paddingTop ?? 10,
    textAlign: style?.textAlign ?? 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    [`&::before`]: {
      border: style?.border ?? '1px solid',
      borderColor: style?.borderColor ?? theme.palette.addition?.deliver,
    },
    color: theme.palette.common.white,
  },
}));
