import { forwardRef } from 'react';

import MuiFormControlLabel, {
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { SystemStyleObject } from '@mui/system';
import { getSxStyles } from "../../utils/styles.utils";

export interface FormControlLabelProps extends MuiFormControlLabelProps {
  /** The label's font size*/
  size?: 'medium' | 'small' | undefined;
}

/** FormControlLabel
 *
 * `import { FormControlLabel } from '@fsp-io/shared-ui'`
 */

const FormControlLabel = forwardRef<unknown, FormControlLabelProps>(
  (props, ref) => {
    return (
      <MuiFormControlLabel
        sx={(theme) =>
          ({
            ...getSxStyles(theme, props.sx),
            ...(props.size === 'small' && {
              '& .MuiTypography-root ': {
                ...theme.typography.body2,
              },
            }),
          } as SystemStyleObject)
        }
        {...props}
        ref={ref}
      />
    );
  },
);

FormControlLabel.displayName = 'FormControlLabel';

export default FormControlLabel;
