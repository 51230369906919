import { forwardRef } from 'react';

import {
  StepConnector as MuiStepConnector,
  StepConnectorProps as MuiStepConnectorProps,
} from '@mui/material';

export type StepConnectorProps = MuiStepConnectorProps

/**
 * `import { StepConnector } from '@fsp-io/shared-ui'`
 */

const StepConnector = forwardRef<HTMLDivElement, StepConnectorProps>(
  (props, ref) => {
    return <MuiStepConnector {...props} ref={ref} />;
  },
);

StepConnector.displayName = 'StepConnector';

export default StepConnector;
