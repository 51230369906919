import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@industrial-plus/shared-ui'

interface Props {
  open: boolean
  title: string
  icon: string
  path: string
}

const NavigationItem: React.FC<Props> = (props: Props) => {
  const { open, title, icon, path } = props

  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dataTestId = `sidenav-${t(title).toLowerCase().replace(' ', '-')}`

  return (
    <Tooltip title={open ? '' : t(title)} placement="right-end">
      <ListItem
        key={t(title)}
        sx={{
          display: 'block',
          padding: '0.5rem',
        }}
      >
        <ListItemButton
          data-testid={dataTestId}
          onClick={() => navigate(path)}
          selected={location.pathname.includes(path)}
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              justifyContent: 'center',
            }}
          >
            <img
              style={{ height: '30px', width: '28px' }}
              src={icon}
              id={icon}
              alt={icon}
            />
          </ListItemIcon>
          <ListItemText primary={t(title)} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  )
}

export default React.memo(NavigationItem)
