import { COLORS } from '@/constants/colors';
import { Avatar, Typography } from '@industrial-plus/shared-ui';
import React from 'react';

interface Props {
  name?: string;
}

const NameAvatar: React.FC<Props> = (props: Props) => {
  const { name } = props;

  return (
    <Avatar
      variant="circular"
      data-cy="avatar"
      alt={name}
      sx={{
        width: 30,
        height: 30,
        bgcolor: COLORS.primary600,
      }}
    >
      <Typography variant="body1">
        {name?.charAt(0).toLocaleUpperCase()}
      </Typography>
    </Avatar>
  );
};

export default React.memo(NameAvatar);
