import React, { createContext, useContext, useState } from 'react'

export interface PushNotification {
  notification: {
    body: string
    title: string
  }
  data?: { [key: string]: any } //TO UPDATE: create interface
}

interface NotificationProviderProps {
  children: React.ReactNode
}

interface NotificationContextData {
  notification?: { [key: string]: PushNotification }
  setNotification: (input: { [key: string]: PushNotification }) => void
}

const NotificationProviderContext = createContext<NotificationContextData>(
  {} as NotificationContextData
)

export function NotificationProvider({ children }: NotificationProviderProps) {
  const [notification, setNotification] = useState<{
    [key: string]: PushNotification
  }>({})

  return (
    <NotificationProviderContext.Provider
      value={{ notification, setNotification }}
    >
      {children}
    </NotificationProviderContext.Provider>
  )
}

export default NotificationProvider

export const useNotificationProviderContext = () => {
  return useContext(NotificationProviderContext)
}
