import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { mqtt5 } from 'aws-iot-device-sdk-v2'

import { AWSCognitoCredentialsProvider } from './AWSCognitoCredentialsProvider'
import { createClient } from './createClient'

import { awsConfiguration } from '.'

enum ConnectStatus {
  NO_OP = 'no_op',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
}

enum SubscriptionStatus {
  SUBSCRIBING = 'subscribing',
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBING = 'unsubscribing',
  UNSUBSCRIBED = 'unsubscribed',
}

interface ConnectMqttClientProps {
  children: React.ReactNode
}

export type ConnectMqttClientContextType = {
  client?: mqtt5.Mqtt5Client
  subscribe: (topic: string) => Promise<void>
  unsubscribe: (topic: string) => Promise<void>
}

const connectMQTTClient = async (): Promise<mqtt5.Mqtt5Client | undefined> => {
  try {
    /** Set up the credentialsProvider */
    const provider = new AWSCognitoCredentialsProvider({
      IdentityPoolId: awsConfiguration.awsCognitoIdentityPoolId,
      Region: awsConfiguration.awsRegion,
    })
    // console.log(
    //   'provider------>',
    //   provider.getCredentials,
    //   JSON.stringify(provider.getCredentials)
    // )

    /** Make sure the credential provider fetched before setup the connection */
    await provider.refreshCredentials()

    const mqttClient = createClient(provider)

    // After MQTT Client created successfully
    // const attemptingConnect = once(mqttClient, 'attemptingConnect')
    // const connectionSuccess = once(mqttClient, 'connectionSuccess')

    mqttClient.start()

    // await attemptingConnect
    // await connectionSuccess

    return mqttClient
  } catch (error: any) {
    console.log('createMQTTClient error:', error)
    return undefined
  }
}

const disconnectMQTTClient = async (mqttClient: mqtt5.Mqtt5Client) => {
  if (mqttClient) {
    mqttClient.stop()
  }
}

export const ConnectMqttClientContext =
  createContext<ConnectMqttClientContextType>(
    {} as ConnectMqttClientContextType
  )

export const ConnectMqttClientProvider: FC<ConnectMqttClientProps> = (
  props: ConnectMqttClientProps
) => {
  const [client, setClient] = useState<mqtt5.Mqtt5Client | undefined>()
  const connectStatus = useRef<ConnectStatus>(ConnectStatus.NO_OP)

  const topics = useRef<{ [key: string]: SubscriptionStatus }>({})

  useEffect(() => {
    const connectClient = async () => {
      // console.log('connectClient 1', connectStatus, Date.now())
      if (!client && connectStatus.current === ConnectStatus.NO_OP) {
        // console.log('connectClient 2', connectStatus, Date.now())
        connectStatus.current = ConnectStatus.CONNECTING
        const mqttClient = await connectMQTTClient()

        if (mqttClient) {
          setClient(mqttClient)
          connectStatus.current = ConnectStatus.CONNECTED
        }
      }
    }

    connectClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const subscribe = useCallback(
    async (topic: string) => {
      // console.log('subscribe 1', topics.current)
      if (client) {
        const topicCurrentStatus = topics.current[topic]
        // console.log('subscribe 2', topicCurrentStatus)

        // Case: Topic already present, so not adding any new subscriptions
        if (
          [
            SubscriptionStatus.SUBSCRIBING,
            SubscriptionStatus.SUBSCRIBED,
          ].includes(topicCurrentStatus)
        ) {
          // console.log('subscribe return')
          return
        }

        topics.current[topic] = SubscriptionStatus.SUBSCRIBING

        // Case: Topic already not present
        const newSubscriptions = Object.keys(topics.current).map((top) => ({
          topicFilter: top,
          qos: mqtt5.QoS.AtMostOnce,
        }))

        // console.log({ newSubscriptions })

        await client.subscribe({
          subscriptions: newSubscriptions,
        })

        topics.current[topic] = SubscriptionStatus.SUBSCRIBED
        // console.log('subscribe success', topics.current)
      }
    },
    [client]
  )

  const unsubscribe = useCallback(
    async (topic: string) => {
      // console.log('unsubscribe', topic, topics.current)
      if (client) {
        const topicCurrentStatus = topics.current[topic]
        // console.log('unsubscribe 2', topic, { topicCurrentStatus })

        if (topicCurrentStatus !== SubscriptionStatus.SUBSCRIBED) {
          // console.log('unsubscribe return')
          return
        }

        // Removing topic from current subscriptions
        await client?.unsubscribe({
          topicFilters: [topic],
        })

        delete topics.current[topic]
      }
    },
    [client]
  )

  const data = useMemo(
    () => ({
      client,
      subscribe,
      unsubscribe,
    }),
    [client, subscribe, unsubscribe]
  )

  return (
    <ConnectMqttClientContext.Provider value={data}>
      {props.children}
    </ConnectMqttClientContext.Provider>
  )
}

export const useConnectMqttClientContext = () =>
  useContext(ConnectMqttClientContext)
