import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { FC } from 'react';
import Box from '../../box';
import styles from './styles';
import { FooterColumnProps } from './types';
import { getPositionStyles } from './utils';

const Column = styled(Box)(styles);
const baseClassName = 'footer-column';

const FooterColumn: FC<FooterColumnProps> = ({ children, position }) => {
  return (
    <Column
      className={clsx(baseClassName, `${baseClassName}--${position}`)}
      sx={getPositionStyles(position)}
    >
      {children}
    </Column>
  );
};

export default FooterColumn;
