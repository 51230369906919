import React from 'react'

import { Outlet } from 'react-router-dom'

import { useCurrentUserQuery, UserRoles } from '@/__generated__/graphql'
import FCMNotificationContextComp from '@/app/fcm/FCMNotificationContextComp'
import FCMTokenProvider from '@/app/fcm/FCMTokenProvider'
import NotificationPermission from '@/app/fcm/NotificationPermission'
import { DateFormatProvider } from '@/contexts/DateFormatContext'
import { ConnectMqttClientProvider } from '@/mqtt-connection/ConnectMqttClientContext'

import FullPageLoader from '../full-page-loader'

const BootUpLayout = () => {
  const { loading, data } = useCurrentUserQuery({
    context: {
      clientName: 'users',
    },
  })
  const userRole = data?.currentUser?.user?.role

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <ConnectMqttClientProvider>
      <DateFormatProvider>
        <FCMTokenProvider>
          {userRole !== UserRoles.ADMIN && (
            <>
              <NotificationPermission />
              <FCMNotificationContextComp />
            </>
          )}
          <Outlet />
        </FCMTokenProvider>
      </DateFormatProvider>
    </ConnectMqttClientProvider>
  )
}

export default BootUpLayout
