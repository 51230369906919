import { InputLabel } from '@mui/material';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import { forwardRef } from 'react';
import { StyledComponentDefaultProps } from '../../types/common';
import { InputMaxHeightMap } from '../../utils/input.utils';
import { getSxStyles } from '../../utils/styles.utils';

export interface SelectProps extends Omit<MuiSelectProps, 'variant' | 'size' | 'margin' | 'value'> {
  /** Set to true to remove background color */
  hideBackgroundColor?: boolean;

  /** Set to true to show a border around the Select */
  showBorder?: boolean;

  /** The size of the component. */
  size?: 'xsmall' | 'small' | 'medium';

  /** Value type, `string[]` is required to make `multiple=true` work. */
  value?: string | string[] | unknown;
}

/** Select
 *
 * `import Select from '@fsp-io/Select'`
 */

const StyledInputLabel = styled(InputLabel)(({ theme }: StyledComponentDefaultProps) => ({
  transform: 'none',
  top: 4,
  left: 14,
  color: theme.palette.text.secondary,
  '&.Mui-focused': {
    '&.MuiFormLabel-colorPrimary': {
      color: `${theme.palette.text.secondary} !important`,
    },
  },
}));

const Select = forwardRef<unknown, SelectProps>(
  (
    {
      hideBackgroundColor = false,
      showBorder = false,
      size = 'medium',
      sx = {},
      placeholder,
      value,
      required,
      ...rest
    },
    ref,
  ) => {
    const renderPlaceholderItem = () => {
      if (Array.isArray(value) || typeof value === 'string') {
        return size === 'xsmall' && !!placeholder && !value?.length;
      }
      return false;
    };

    return (
      <>
        <MuiSelect
          variant="filled"
          margin={size === 'small' ? 'dense' : 'none'}
          size={size === 'xsmall' ? 'small' : size}
          value={value}
          required={required}
          sx={theme =>
            ({
              // Conditionally apply properties
              ...(size !== 'medium' && { maxHeight: InputMaxHeightMap[size] }),
              ...(showBorder ? { border: '1px solid #D0D5DD' } : { border: '0px !important' }),
              ...(hideBackgroundColor && {
                backgroundColor: 'inherit!important',
              }),
              ...getSxStyles(theme, sx),
              ...{ padding: '9px 12px' },
            } as SystemStyleObject)
          }
          {...rest}
          ref={ref}
        />
        {renderPlaceholderItem() && (
          <StyledInputLabel>
            {placeholder}
            {required && '*'}
          </StyledInputLabel>
        )}
      </>
    );
  },
);

Select.displayName = 'Select';

export default Select;
