import { forwardRef } from 'react';
import { getSxStyles } from '../../utils/styles.utils';
import { SystemStyleObject } from '@mui/system';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

export interface AppBarProps extends Omit<MuiAppBarProps, 'color'> {
  color?:
    | 'appBarBackground'
    | 'inherit'
    | 'transparent'
    | 'primary'
    | 'secondary'
    | 'default';
}

/** AppBar
 *
 * The App Bar displays information and actions relating to the current screen.
 *
 * `import { AppBar } from '@fsp-io/shared-ui'`
 */

const AppBar = forwardRef<HTMLDivElement, AppBarProps>(
  ({ sx, color = 'primary', ...rest }, ref) => {
    return (
      <MuiAppBar
        {...rest}
        enableColorOnDark
        color={color !== 'appBarBackground' ? color : 'primary'}
        sx={(theme) =>
          ({
            ...getSxStyles(theme, sx),
            ...(color === 'appBarBackground' && {
              backgroundColor: theme.palette.addition?.appBarBackground,
              color: theme.palette.text.primary,
            }),
          } as SystemStyleObject)
        }
        ref={ref}
      />
    );
  },
);

AppBar.displayName = 'AppBar';

export default AppBar;
