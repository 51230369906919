import { CognitoIdentityCredentials } from '@aws-sdk/credential-provider-cognito-identity/dist-types/fromCognitoIdentity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import {
  AWSCredentials,
  CredentialsProvider,
} from 'aws-crt/dist.browser/browser/auth'

/**
 * AWSCognitoCredentialOptions. The credentials options used to create AWSCognitoCredentialProvider.
 */
interface AWSCognitoCredentialOptions {
  IdentityPoolId: string
  Region: string
}

export class AWSCognitoCredentialsProvider extends CredentialsProvider {
  private options: AWSCognitoCredentialOptions
  private cachedCredentials?: CognitoIdentityCredentials

  constructor(
    options: AWSCognitoCredentialOptions,
    expire_interval_in_ms?: number
  ) {
    // console.log('options ---->', options)
    super()
    this.options = options

    setInterval(
      async () => {
        await this.refreshCredentials()
      },
      expire_interval_in_ms ?? 60 * 60 * 1000
    )
  }

  override getCredentials(): AWSCredentials {
    return {
      aws_access_id: this.cachedCredentials?.accessKeyId ?? '',
      aws_secret_key: this.cachedCredentials?.secretAccessKey ?? '',
      aws_sts_token: this.cachedCredentials?.sessionToken,
      aws_region: this.options.Region,
    }
  }

  override async refreshCredentials() {
    // console.log(
    //   'Fetching Cognito credentials--->',
    //   this.options.IdentityPoolId,
    //   '---->',
    //   this.options.Region
    // )
    this.cachedCredentials = await fromCognitoIdentityPool({
      // Required. The unique identifier for the identity pool from which an identity should be retrieved or generated.

      identityPoolId: this.options.IdentityPoolId,
      clientConfig: { region: this.options.Region },
    })()
  }
}
