import { forwardRef } from 'react';

import MuiTabScrollButton, {
  TabScrollButtonProps as MuiTabScrollButtonProps,
} from '@mui/material/TabScrollButton';

export type TabScrollButtonProps = MuiTabScrollButtonProps

/** TabScrollButton
 *
 * `import { TabScrollButton } from '@fsp-io/shared-ui'`
 */

const TabScrollButton = forwardRef<HTMLDivElement, TabScrollButtonProps>(
  (props, ref) => {
    return <MuiTabScrollButton {...props} ref={ref} />;
  },
);

TabScrollButton.displayName = 'TabScrollButton';

export default TabScrollButton;
