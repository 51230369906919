import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider, {
  LocalizationProviderProps as MuiLocalizationProviderProps,
} from '@mui/lab/LocalizationProvider';
import { MuiPickersAdapter } from '@mui/lab/LocalizationProvider/LocalizationProvider';

export interface LocalizationProviderProps
  extends Omit<MuiLocalizationProviderProps, 'dateAdapter'> {
  dateAdapter?: new (...args: any) => MuiPickersAdapter<unknown>;
}

export default function FleetLocalizationProvider({
  children,
  dateAdapter,
  ...rest
}: LocalizationProviderProps) {
  return (
    <LocalizationProvider dateAdapter={dateAdapter ?? AdapterDateFns} {...rest}>
      {children}
    </LocalizationProvider>
  );
}
