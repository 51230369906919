import { Button, Stack } from '@industrial-plus/shared-ui'
import { styled } from '@mui/material/styles'

export const AlertWarningStack = styled(Stack)({
  alignItems: 'center',
  width: '100%',
  borderRadius: '0.625rem',
  background: 'rgba(100, 182, 247, 0.10)',
  boxShadow: '0px 4px 4px 1px rgba(0, 0, 0, 0.10)',
  padding: '0.5rem 1rem 0.5rem 1rem',
  marginBottom: '1rem',
})

export const CloseButton = styled(Button)({
  color: 'none',
  border: 'none',
  background: 'transparent',
  '&:hover': {
    background: 'transparent',
  },
})

export const OkayButton = styled(Button)(({ theme }) => ({
  color: theme.colors.white,
  border: `0.5px solid ${theme.colors.primary700}`,
  borderRadius: '5px',
  transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
  fontStyle: 'normal',
}))
