import { styled } from '@mui/material/styles'

interface BodyProps {
  open: boolean
}

export const Body = styled('main')<BodyProps>(({ theme, open }) => ({
  flexGrow: 1,
  backgroundColor: theme.colors.gray100,
  overflowX: 'hidden',
  width: 'auto',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  marginTop: theme.mixins.toolbar.minHeight,
  height: `calc(100vh -  ${theme.mixins.toolbar.minHeight}px)`,
  transition: theme.transitions.create(['all']),
  ...(open && {
    marginLeft: theme.sidebar.openWidth,
    [theme.breakpoints.between('sm', 'lg')]: {
      marginLeft: theme.sidebar.closedWidth,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  }),
  ...(!open && {
    marginLeft: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  }),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    marginLeft: 0,
  },
}))
