import { forwardRef } from 'react';

import { Fade as MuiFade, FadeProps as MuiFadeProps } from '@mui/material';

export type FadeProps = MuiFadeProps;

/** Fade
 *
 * The Fade transition is used by the Modal component.
 * It uses [react-transition-group](https://github.com/reactjs/react-transition-group) internally.
 *
 * `import { Fade } from '@fsp-io/shared-ui'`
 */

const Fade = forwardRef<unknown, FadeProps>(({ children, ...rest }, ref) => {
  return (
    <MuiFade {...rest} ref={ref}>
      {children}
    </MuiFade>
  );
});

Fade.displayName = 'Fade';

export default Fade;
