import React, { useMemo } from 'react'
import { useField } from 'formik'
import { CountryData } from 'react-phone-input-2'
import { FormInputProps } from './types'
import PhoneNumber from '../phone-number'

interface Props {
  countryDefaultValue?: string
  input: FormInputProps
}

const FormikPhoneNumber: React.FC<Props> = (props: Props) => {
  const [field, meta, helper] = useField(props.input.name)
  const [selectedCountryCode, setSelectedCountryCode] = React.useState<string>()

  const handleOnBlur = () => {
    helper.setTouched(true)
  }

  const selectedCountry = useMemo(() => {
    if (props.countryDefaultValue?.length) {
      return props.countryDefaultValue.toLowerCase()
    }

    return 'us'
  }, [props.countryDefaultValue])

  const inputValue = field.value as string

  const inputWithoutCountryCode = useMemo(() => {
    return inputValue.replace(`+${selectedCountryCode}`, '')
  }, [inputValue, selectedCountryCode])

  const shouldValidate = useMemo(() => {
    if (props.input.required) {
      return true
    } else if (inputWithoutCountryCode.length > 0) {
      return true
    } else {
      return false
    }
  }, [props.input.required, inputWithoutCountryCode])

  return (
    <PhoneNumber
      showLabel
      autoFormat
      copyNumbersOnly
      countryCodeEditable
      defaultErrorMessage="Phone number is incorrect"
      defaultMask="... ... ... ... .."
      enableClickOutside
      prefix="+"
      size="small"
      country={selectedCountry}
      specialLabel={props.input.label}
      placeholder=""
      fullWidth
      sx={{
        width: '100%',
      }}
      value={inputValue}
      onBlur={handleOnBlur}
      validate={shouldValidate}
      onChange={(s, e: CountryData, event, formattedValue) => {
        const dialCode = e.dialCode
        const isValidFormat = formattedValue.split(' ').length >= 2
        const formattedNumber = isValidFormat ? `+${s}` : ''

        setSelectedCountryCode(dialCode)
        helper.setValue(formattedNumber)
      }}
      onValidate={(value) => {
        if (!meta.error && !value) {
          helper.setError('Please enter valid Phone Number')
        }

        if (meta.error && value) {
          helper.setError('')
        }
      }}
      error={meta && meta.touched && meta.error ? !!meta.error : undefined}
      helperText={meta && meta.touched && meta.error ? meta.error : undefined}
    />
  )
}

export default React.memo(FormikPhoneNumber)
