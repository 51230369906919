import React from 'react'

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { DatePicker, DatePickerProps } from '@mui/lab'
import { TextField } from '@mui/material'

interface CommonDatePickerProps extends DatePickerProps {
  errorText?: string
}

const OpenPickerIcon = ({ style }: { style: React.CSSProperties }) => (
  <CalendarMonthOutlinedIcon style={style} />
)

const ICSDatePicker: React.FC<CommonDatePickerProps> = ({
  errorText,
  ...props
}) => {
  return (
    <DatePicker
      {...props}
      inputFormat="dd.MM.yyyy"
      mask="__.__.____"
      components={{
        OpenPickerIcon: () => (
          <OpenPickerIcon style={{ fontSize: '1.5rem', marginLeft: '-1rem' }} />
        ),
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          error={!!errorText}
          helperText={errorText}
          autoComplete="off"
          sx={{
            marginBottom: '1rem',
            input: {
              paddingLeft: '0.5rem',
              fontSize: '0.875rem',
            },
          }}
        />
      )}
    />
  )
}

export default ICSDatePicker
