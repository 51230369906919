import { PropertiesMap } from '@/__generated__/graphql'
import { Helpers } from '@/app/utils/helpers.utils'
import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  EventTileDetailsContainer,
  TypeContainer,
  TypeText,
} from './NotificationPopupStyled'

export interface EventTileDetailsProps {
  notificationDetails: PropertiesMap[]
}

const EventTileDetails: React.FC<EventTileDetailsProps> = ({
  notificationDetails,
}) => {
  const { t } = useTranslation()
  return (
    <EventTileDetailsContainer>
      {notificationDetails.map(({ key: detailType, value: detailValue }) => (
        <TypeContainer key={detailType}>
          <TypeText variant="body1">
            {t(
              `users.profile.notifications.details.${Helpers.formatIntoCamelCase(
                detailType
              )}`
            )}
            :
          </TypeText>
          {detailType === 'date'
            ? format(new Date(detailValue!), 'dd/MM/yyyy HH:mm a')
            : detailValue}
        </TypeContainer>
      ))}
    </EventTileDetailsContainer>
  )
}

export default EventTileDetails
