import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
} from '@industrial-plus/shared-ui';
import { styled } from '@mui/material/styles';

// App Bar
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  // borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer + 1,
  marginLeft: theme.sidebar.openWidth,
  width: `calc(100% - ${theme.sidebar.closedWidth}px)`,
  backgroundColor: theme.colors.white,
  color: theme.colors.black,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down('sm')]: {
    borderBottom: '0px',
    width: '100%',
  },

  ...(open && {
    width: `calc(100% - ${theme.sidebar.openWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.between('sm', 'lg')]: {
      width: `calc(100% - ${theme.sidebar.closedWidth}px)`,
    },
  }),
}));
