import './Loader.scss'

import clsx from 'clsx'

import { CircularProgress, Container } from '@industrial-plus/shared-ui'
import React from 'react'

export interface LoaderProps {
  className?: string
  size?: number | string
  disableGutters?: boolean
  infinite?: boolean
  overlay?: boolean
}

export function Loader(props: LoaderProps) {
  const { className, size, disableGutters, infinite, overlay } = props

  const containerClasses = clsx(className, 'Loader', {
    'Loader-infinite': infinite,
    'Loader-overlay': overlay,
  })

  return (
    <Container
      className={containerClasses}
      disableGutters={disableGutters}
      maxWidth={false}
    >
      <CircularProgress size={size} />
    </Container>
  )
}

export default Loader
