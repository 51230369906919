import React, { Suspense } from 'react'

import { Route, Routes } from 'react-router-dom'

import FullPageLoader from '@/app/components/full-page-loader'
import Layout from '@/app/components/layout/Layout'
import AdminPlusOutlet from '@/app/guards/AdminPlusOutlet'
import {
  LazyLoadedAdminPlusDashboard,
  LazyLoadedAdminPlusDetailRoutes,
  LazyLoadedMasterTemplateRoutes,
} from '@/app/routes/LazyLoadedRoutes'
import { createDescendantRoute, ROUTE_CONSTANTS } from '@/constants/paths'

const AdminPlusRoutes: React.FC = () => {
  return (
    <Layout>
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route path="*" element={<AdminPlusOutlet />}>
            <Route index element={<LazyLoadedAdminPlusDashboard />} />
            <Route
              path={createDescendantRoute(ROUTE_CONSTANTS.templates)}
              element={<LazyLoadedMasterTemplateRoutes />}
            />
            <Route
              path={createDescendantRoute(ROUTE_CONSTANTS.details)}
              element={<LazyLoadedAdminPlusDetailRoutes />}
            />
          </Route>
        </Routes>
      </Suspense>
    </Layout>
  )
}

export default React.memo(AdminPlusRoutes)
