import { forwardRef } from 'react';

import MuiTableBody, {
  TableBodyProps as MuiTableBodyProps,
} from '@mui/material/TableBody';

export interface TableBodyProps extends MuiTableBodyProps {}

/** TableBody
 *
 * `import { TableBody } from '@fsp-io/shared-ui'`
 */

const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  (props, ref) => {
    return <MuiTableBody {...props} ref={ref} />;
  },
);

TableBody.displayName = 'TableBody';

export default TableBody;
