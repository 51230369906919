import { forwardRef } from 'react'

import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material'

export interface CircularProgressProps
  extends Omit<MuiCircularProgressProps, 'color'> {
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color?: 'primary' | 'secondary'
}

/** CircularProgress inform users about the status of ongoing processes, such as loading an app, submitting a form, or saving updates
 *
 * `import { CircularProgress } from '@fsp-io/shared-ui'`
 */

const CircularProgress = forwardRef<unknown, CircularProgressProps>(
  (props, ref) => {
    return <MuiCircularProgress {...props} ref={ref} />
  }
)

CircularProgress.displayName = 'CircularProgress'

export default CircularProgress
