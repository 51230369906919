import { forwardRef } from 'react';

import { Modal as MuiModal, ModalProps as MuiModalProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import Backdrop from '../backdrop';

export type ModalProps = Omit<
    MuiModalProps,
    'closeAfterTransition' | 'components' | 'disableEscapeKeyDown'
  >

const defaultPropsOverrides: Partial<MuiModalProps> = {
  BackdropComponent: styled(Backdrop, {
    name: 'MuiModal',
    overridesResolver: (_props, styles) => {
      return styles["backdrop"];
    },
    slot: 'Backdrop',
  })({ zIndex: -1 }),
};

/** Modal
 *
 * The modal component provides a solid foundation for creating dialogs, popovers, lightboxes, or whatever else.
 * 
 * The component renders its children node in front of a backdrop component. The Modal offers important features:
 * 
 * Manages modal stacking when one-at-a-time just isn't enough.
 * Creates a backdrop, for disabling interaction below the modal.
 * It disables scrolling of the page content while open.
 * It properly manages focus; moving to the modal content, and keeping it there until the modal is closed.
 * Adds the appropriate ARIA roles automatically.

 * `import { Modal } from '@fsp-io/shared-ui'`
 */

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  ({ children, ...props }, ref) => {
    return (
      <MuiModal {...defaultPropsOverrides} {...props} ref={ref}>
        {children}
      </MuiModal>
    );
  },
);

Modal.displayName = 'Modal';

export default Modal;
