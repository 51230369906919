import { forwardRef } from 'react';

import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
} from '@mui/material';
import { getSxStyles } from '../../utils/styles.utils';
import { CSSObject } from '@emotion/react';

const listItemHeight = {
  small: '32px',
  medium: '40px',
  large: '48px',
};

export interface ListItemProps extends MuiListItemProps {
  /**
   * size of the component
   */
  size?: keyof typeof listItemHeight;
}

/** ListItem
 *
 * `import { ListItem } from '@fsp-io/shared-ui'`
 */

const ListItem = forwardRef<HTMLLIElement, ListItemProps>((props, ref) => {
  const { size, sx, ...rest } = props;

  return (
    <MuiListItem
      sx={(theme) =>
        ({
          height: size ? listItemHeight[size] : 'auto',
          ...getSxStyles(theme, sx),
        } as CSSObject)
      }
      {...rest}
      ref={ref}
    />
  );
});

ListItem.displayName = 'ListItem';

export default ListItem;
