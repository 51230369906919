import { Theme } from '@mui/material/styles';

export enum StatusType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Success = 'Success',
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}

export type InputSize = {
  /**
   * Defines size of input
   *
   * @default small
   */
  size?: 'xsmall' | 'small' | 'medium';
};

export enum Orientation {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

export enum Position {
  Left = 'Left',
  Right = 'Right',
  Top = 'Top',
  Bottom = 'Bottom',
}

export type CssAbsoluteUnit = 'cm' | 'mm' | 'in' | 'px' | 'pt' | 'pc';

export type CssRelativeUnit =
  | 'em'
  | 'ex'
  | 'ch'
  | 'rem'
  | 'vw'
  | 'vh'
  | 'vmin'
  | 'vmax'
  | '%';

export interface StyledComponentDefaultProps {
  theme: Theme;
}

export type AnyChildren =
  | JSX.Element
  | JSX.Element[]
  | string
  | '() => AnyChildren';
