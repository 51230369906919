const dateTimeDisplayOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
}

export const formatDateTime = (date: string) => {
  return new Date(date).toLocaleString('en-US', dateTimeDisplayOptions)
}
