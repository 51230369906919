import { SelectChangeEvent } from '@mui/material'
import { useField } from 'formik'
import React, { useMemo } from 'react'
import FormControl from '../form-control'
import FormHelperText from '../form-helper-text'
import InputLabel from '../input-label'
import ListItemText from '../list-item-text'
import MenuItem from '../menu-item'
import Select from '../select'

interface SingleSelectFieldProps {
  name: string
  type?: string
  label: string
  options: Array<string | { name: string; value: string }>
  loading?: boolean
  onChange?: (value: string) => void
  required?: boolean
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const FormikSingleSelect: React.FC<SingleSelectFieldProps> = ({
  name,
  label,
  options,
  loading,
  ...props
}: SingleSelectFieldProps) => {
  const [field, meta, { setValue, setTouched }] = useField(name)

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    setValue(value)
    if (props.onChange) {
      props.onChange(value as string)
    }
  }

  const handleOnBlur = () => {
    setTouched(true)
  }

  const selectOptions = useMemo(() => options, [options])

  const selectConfig = {
    ...props,
    ...field,
  }

  const errorConfig = {
    error: false,
    helperText: '',
  }

  if (meta && meta.touched && meta.error) {
    errorConfig.error = true
    errorConfig.helperText = meta.error
  }

  return (
    <FormControl
      sx={{
        minWidth: 120,
        width: '100%',
        marginTop: '1rem',
      }}
      variant="standard"
    >
      <InputLabel
        id="single-select-label"
        sx={{
          marginTop: '-1rem',
        }}
        size="small"
        required={selectConfig.required}
        shrink
      >
        {label}
      </InputLabel>
      <Select
        labelId="single-select-label"
        size="small"
        showBorder
        hideBackgroundColor
        label={label}
        id={`${field.name}-single-select`}
        data-testid={`${field.name}-single-select`}
        disabled={loading}
        {...selectConfig}
        value={field.value}
        onChange={handleChange}
        onBlur={handleOnBlur}
        MenuProps={MenuProps}
      >
        {selectOptions.map((option, index) => {
          const isObject = typeof option === 'object'
          const itemName = isObject ? option.name : option
          const value = isObject ? option.value : option
          return (
            <MenuItem
              key={itemName}
              value={value}
              data-testid={`select-option-${itemName}`}
            >
              <ListItemText primary={itemName} />
            </MenuItem>
          )
        })}
      </Select>
      {errorConfig.error && (
        <FormHelperText error data-testid={`${field.name}-helper-select`}>
          {errorConfig.helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default React.memo(FormikSingleSelect)
