import { Box } from '@industrial-plus/shared-ui'
import { styled } from '@mui/material/styles'

export const PlusContainer = styled(Box)(() => ({
  flexGrow: 0,
  width: '100vw',
  display: 'flex',
  justifyContent: 'space-between',
}))

export const GreetingsContainer = styled(Box)(() => ({
  flexGrow: 0,
  display: 'flex',
  width: '80%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  size: '1rem',
}))

export const TenantSectionContainer = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  display: 'flex',
  width: '15%',
  justifyContent: 'flex-end',
  fontSize: '1.375rem',
  color: theme.colors.primary700,
}))
