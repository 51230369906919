import { forwardRef } from "react";

import MuiFormControl, {
  FormControlProps as MuiFormControlProps,
} from "@mui/material/FormControl";

export type FormControlProps = MuiFormControlProps

/** 
 * FormControl
 */

const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  (props, ref) => {
    return <MuiFormControl {...props} ref={ref} />;
  }
);

FormControl.displayName = "FormControl";

export default FormControl;
