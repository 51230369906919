import { setBorderRadius } from "./utils";
import { StatusIndicatorStyles } from "./types";
import { Orientation, Position } from "../../types/common";

export default {
  indicator: ({
    custombgcolor,
    orientation,
    alignment,
  }: StatusIndicatorStyles) => {
    const placementStyles =
      orientation === Orientation.Horizontal
        ? {
            width: "100%",
            height: "4px",
          }
        : {
            width: "4px",
            height: "100%",
          };

    return {
      backgroundColor: custombgcolor,
      borderRadius: setBorderRadius(alignment as Position),
      ...placementStyles,
    };
  },
};
