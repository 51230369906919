import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Box } from '@industrial-plus/shared-ui'

import { useCurrentUserQuery, UserRoles } from '@/__generated__/graphql'

import {
  GreetingsContainer,
  PlusContainer,
  TenantSectionContainer,
} from './styles'

const PlusSectionHeader: React.FC = () => {
  const { t } = useTranslation()

  const { data } = useCurrentUserQuery({
    context: {
      clientName: 'users',
    },
  })
  const currentUser = data?.currentUser?.user
  const userRole = currentUser?.role
  const tenant = currentUser?.tenant

  const greetingMessage = useMemo(() => {
    const currentHour = new Date().getHours()
    let greeting = ''

    if (currentHour >= 0 && currentHour <= 11) {
      greeting = t('common.greetings.goodMorning')
    } else if (currentHour >= 12 && currentHour <= 15) {
      greeting = t('common.greetings.goodAfternoon')
    } else {
      greeting = t('common.greetings.goodEvening')
    }

    return `${greeting}, `
  }, [t])

  return (
    <PlusContainer>
      <GreetingsContainer data-testid="header-greeting-message">
        {greetingMessage}
        <Box sx={{ width: '0.3rem' }} />
        <strong> {currentUser?.firstName ?? 'User'}</strong>
      </GreetingsContainer>
      {userRole === UserRoles.MANAGER && (
        <TenantSectionContainer data-testid="header-tenant-name">
          {tenant?.name && `${tenant?.name}`}
        </TenantSectionContainer>
      )}
    </PlusContainer>
  )
}

export default React.memo(PlusSectionHeader)
