import FullPageLoader from '@/app/components/full-page-loader';
import {
  LazyLoadedForgotPassword,
  LazyLoadedLogin,
  LazyLoadedLogout,
  LazyLoadedLogoutCallback,
} from '@/app/routes/LazyLoadedRoutes';
import { PATHS, ROUTE_CONSTANTS } from '@/constants/paths';
import { Box } from '@industrial-plus/shared-ui';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const AuthRoutes: React.FC = () => {
  return (
    <Box width="100%" height="100vh">
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route path={ROUTE_CONSTANTS.login}>
            <Route index element={<LazyLoadedLogin />} />
            <Route
              path={ROUTE_CONSTANTS.callback}
              element={<LazyLoadedLogoutCallback />}
            />
          </Route>
          <Route
            path={ROUTE_CONSTANTS.forgotPassword}
            element={<LazyLoadedForgotPassword />}
          />
          <Route path={ROUTE_CONSTANTS.logout} element={<LazyLoadedLogout />} />
          <Route
            path="*"
            element={<Navigate to={PATHS.auth.login} replace />}
          />
        </Routes>
      </Suspense>
    </Box>
  );
};

export default React.memo(AuthRoutes);
