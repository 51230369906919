import { useField } from 'formik'
import React, { useState } from 'react'
import CountrySelect from '../country-select'
import { FormInputProps } from './types'

interface Props {
  input: FormInputProps
  forbiddenCountries?: { label: string; value: string }[]
}

const FormikCountrySelect: React.FC<Props> = (props: Props) => {
  const { onChange, ...rest } = props.input

  const [, meta, helper] = useField(props.input.name)

  const [selectedCountry, setSelectedCountry] = useState(
    props.input.value as string
  )

  const handleCountryChange = (countryCode: string) => {
    setSelectedCountry(countryCode)
    helper.setValue(countryCode)
  }

  const handleCountryBlur = () => {
    helper.setTouched(true)
  }

  return (
    <CountrySelect
      {...rest}
      hideBackgroundColor
      showBorder
      size="small"
      selected={selectedCountry}
      onSelect={handleCountryChange}
      onBlur={handleCountryBlur}
      value={selectedCountry}
      forbiddenCountries={props.forbiddenCountries}
      error={meta && meta.touched && !!meta.error}
      helperText={meta.error}
    />
  )
}

export default React.memo(FormikCountrySelect)
