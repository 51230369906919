import { AlertProps, alertClasses } from "@mui/material/Alert";
import { StyledComponentDefaultProps } from "../../types/common";

const styles = ({
  theme,
  color,
  variant,
  severity = "warning",
}: AlertProps & StyledComponentDefaultProps) => {
  const alertColor = color || severity;

  return {
    [`.${alertClasses.icon}`]: {
      opacity: 1,
      color:
        variant !== "filled"
          ? theme.palette[alertColor]?.main
          : theme.palette.common.white,
    },
    ...(variant !== "filled" && {
      color: theme.palette[alertColor]?.content,
    }),
    ...(variant === "outlined" && {
      borderColor: theme.palette[alertColor]?.main,
    }),
  };
};

export default styles;
