import { forwardRef } from 'react';

import { SystemStyleObject } from '@mui/system';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import { blueGrey } from '@mui/material/colors';
import { getDisplayStringForAvatar } from '../../utils/dataDisplayUtils.utils';
import { getSxStyles } from '../../utils/styles.utils';

export type AvatarProps = MuiAvatarProps;

/** The Avatar component represents a unique user or entity through a custom image, initials or icon..
 *
 * `import { Avatar } from '@fsp-io/shared-ui'`
 */

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ children, sx, ...rest }, ref) => {
    return (
      <MuiAvatar
        sx={(theme) =>
          ({
            backgroundColor: blueGrey[500],
            fontSize: '18px',
            ...getSxStyles(theme, sx),
          } as SystemStyleObject)
        }
        {...rest}
        ref={ref}
      >
        {typeof children === 'string'
          ? getDisplayStringForAvatar(children)
          : children}
      </MuiAvatar>
    );
  },
);

Avatar.displayName = 'Avatar';

export default Avatar;
