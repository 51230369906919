import { useField } from 'formik'
import TextField, { TextFieldProps, TextFieldSize } from '../text-field'
import { memo } from 'react'

const TEXTFIELD_DEFAULTS = {
  fullWidth: true,
  hideBackgroundColor: true,
  showBorder: true,
  size: 'small' as TextFieldSize,
  error: false,
  helperText: '',
}

interface Props extends TextFieldProps {
  name: string
}

const FormikTextField: React.FC<Props> = ({ name, ...otherProps }: Props) => {
  const [field, meta] = useField(name)

  const configTextfield = {
    ...TEXTFIELD_DEFAULTS,
    ...otherProps,
    ...field,
  }

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true
    configTextfield.helperText = meta.error
  }

  return <TextField {...configTextfield} />
}

export default memo(FormikTextField)
