import { forwardRef, memo } from 'react';

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  duration,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Backdrop from '../backdrop';
import Fade from '../fade';
import Paper from '../paper';

export type DialogProps = MuiDialogProps

/**
 *  Dialogs inform users about a task and can contain critical information, require decisions, or involve multiple tasks.
 *  A Dialog is a type of modal window that appears in front of app content to provide critical information or ask for a decision.
 *  Dialogs disable all app functionality when they appear, and remain on screen until confirmed, dismissed, or a required action has been taken.
 *  Dialogs are purposefully interruptive, so they should be used sparingly
 *
 * `import { Dialog } from '@fsp-io/shared-ui'`
 */

const wrappedBackdropComponent = memo(
  styled(Backdrop, {
    name: 'MuiModal',
    overridesResolver: (_, styles) => {
      return styles["backdrop"];
    },
    slot: 'Backdrop',
  })({ zIndex: -1 }),
);

const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      transitionDuration = {
        enter: duration.enteringScreen,
        exit: duration.leavingScreen,
      },
      TransitionComponent = Fade,
      PaperComponent = Paper,
      BackdropComponent = wrappedBackdropComponent,
      PaperProps = {},
      ...rest
    },
    ref,
  ) => {
    return (
      <MuiDialog
        transitionDuration={transitionDuration}
        TransitionComponent={TransitionComponent}
        PaperComponent={PaperComponent}
        BackdropComponent={BackdropComponent}
        // shadow Fleet 04
        PaperProps={{ elevation: 4, ...PaperProps }}
        {...rest}
        ref={ref}
      />
    );
  },
);

Dialog.displayName = 'Dialog';

export default Dialog;
