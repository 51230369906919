export default {
  logo: {
    display: "flex",

    "& img": {
      display: "flex",
      maxWidth: "100%",
      height: "auto",
    },
  },
};
