import { StatusType } from "../types/common";
import { useTheme } from "@mui/material/styles";

export const getStatusColor = (
  type?: StatusType,
  defaultColor?: string
): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();
  switch (type) {
    case StatusType.Secondary:
      return theme.palette?.secondary.main;

    case StatusType.Error:
      return theme.palette?.error.main;

    case StatusType.Info:
      return theme.palette?.info.main;

    case StatusType.Success:
      return theme.palette?.success.main;

    case StatusType.Warning:
      return theme.palette?.warning.main;

    default:
      return defaultColor || theme.palette?.primary.main;
  }
};
