import { forwardRef } from 'react';

import { Grid as MuiGrid, GridProps as MuiGridProps } from '@mui/material';
import { AllSystemCSSProperties, ResponsiveStyleValue } from '@mui/system';

export interface GridProps extends MuiGridProps {
  /**
   *
   * Specifies how to capitalize an element's text
   */
  textTransform?: ResponsiveStyleValue<AllSystemCSSProperties['textTransform']>;
}

/** The grid creates visual consistency between layouts while allowing flexibility across a wide variety of designs. Material Design's responsive UI is based on a 12-column grid layout.

 * `import { Grid } from '@fsp-io/shared-ui'`
 */

const Grid = forwardRef<HTMLDivElement, GridProps>(
  ({ children, ...rest }, ref) => {
    return (
      <MuiGrid {...rest} ref={ref}>
        {children}
      </MuiGrid>
    );
  },
);

Grid.displayName = 'Grid';

export default Grid;
