import { forwardRef } from 'react';

import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
} from '@mui/material';

export type DialogContentProps = MuiDialogContentProps

/** DialogContent
 *
 * DialogContent is a used a wrapper around the content within the Dialog. It may contain child components such as DialogContentText.
 *
 * `import { DialogContent } from '@fsp-io/shared-ui'`
 */

const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  (props, ref) => {
    return <MuiDialogContent {...props} ref={ref} />;
  },
);

DialogContent.displayName = 'DialogContent';

export default DialogContent;
