import {
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material'
import React, { forwardRef } from 'react'

export type ListItemButtonProps = MuiListItemButtonProps

/** ListItemButton
 *
 * `import { ListItemButton } from '@fsp-io/shared-ui'`
 */

const ListItemButton = forwardRef<HTMLDivElement, ListItemButtonProps>(
  (props, ref) => {
    return <MuiListItemButton {...props} ref={ref} />
  }
)

ListItemButton.displayName = 'ListItemButton'

export default ListItemButton
