import { lighten } from "@mui/material";
import { StyledComponentDefaultProps } from "../../types/common";

export default {
  container: ({ theme }: StyledComponentDefaultProps) => ({
    display: "inline-flex",

    "& > *": {
      marginLeft: "8px",
      paddingLeft: "8px",
      borderLeft: `1px solid ${lighten(theme.palette.common.black, 0.86)}`,
    },

    "& > *:first-of-type": {
      marginLeft: 0,
      paddingLeft: 0,
      borderLeft: "none",
    },
  }),
};
