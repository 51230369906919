import React from 'react'

export const CrossCloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M11.2 22.6667L16 17.8667L20.8 22.6667L22.6666 20.8001L17.8666 16.0001L22.6666 11.2001L20.8 9.33341L16 14.1334L11.2 9.33341L9.33329 11.2001L14.1333 16.0001L9.33329 20.8001L11.2 22.6667ZM16 29.3334C14.1555 29.3334 12.4222 28.9834 10.8 28.2834C9.17774 27.5834 7.76663 26.6334 6.56663 25.4334C5.36663 24.2334 4.41663 22.8223 3.71663 21.2001C3.01663 19.5779 2.66663 17.8445 2.66663 16.0001C2.66663 14.1556 3.01663 12.4223 3.71663 10.8001C4.41663 9.17786 5.36663 7.76675 6.56663 6.56675C7.76663 5.36675 9.17774 4.41675 10.8 3.71675C12.4222 3.01675 14.1555 2.66675 16 2.66675C17.8444 2.66675 19.5777 3.01675 21.2 3.71675C22.8222 4.41675 24.2333 5.36675 25.4333 6.56675C26.6333 7.76675 27.5833 9.17786 28.2833 10.8001C28.9833 12.4223 29.3333 14.1556 29.3333 16.0001C29.3333 17.8445 28.9833 19.5779 28.2833 21.2001C27.5833 22.8223 26.6333 24.2334 25.4333 25.4334C24.2333 26.6334 22.8222 27.5834 21.2 28.2834C19.5777 28.9834 17.8444 29.3334 16 29.3334ZM16 26.6667C18.9777 26.6667 21.5 25.6334 23.5666 23.5667C25.6333 21.5001 26.6666 18.9779 26.6666 16.0001C26.6666 13.0223 25.6333 10.5001 23.5666 8.43341C21.5 6.36675 18.9777 5.33341 16 5.33341C13.0222 5.33341 10.5 6.36675 8.43329 8.43341C6.36663 10.5001 5.33329 13.0223 5.33329 16.0001C5.33329 18.9779 6.36663 21.5001 8.43329 23.5667C10.5 25.6334 13.0222 26.6667 16 26.6667Z"
      fill="#4C91D0"
    />
  </svg>
)
