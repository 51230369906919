import { forwardRef } from "react";

import MuiRadioGroup, {
  RadioGroupProps as MuiRadioGroupProps,
} from "@mui/material/RadioGroup";

export type RadioGroupProps = MuiRadioGroupProps

/** RadioGroup
 *
 * The RadioGroup component allows users to select a single item from a set or list.
 *
 * `import { RadioGroup } from '@fsp-io/shared-ui'`
 */

const RadioGroup = forwardRef<unknown, RadioGroupProps>((props, ref) => {
  return <MuiRadioGroup {...props} ref={ref} />;
});

RadioGroup.displayName = "RadioGroup";

export default RadioGroup;
