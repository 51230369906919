import { useState, ChangeEvent, useEffect } from 'react';
import { omit } from 'lodash-es';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import ClickAwayListener from '../click-away-listener';
import LinearProgress from '../linear-progress';
import Modal from '../modal';
import Typography from '../typography';
import Paper from '../paper';
import TextField from '../text-field';
import IconButton from '../icon-button';
import Box from '../box';
import Button from '../button';
import Link from '../link';
import Alert from '../alert';
import { isEmailValid, EmailData } from '../../utils/validator.utils';

import { ForgotPasswordProps } from './types';
import styles from './styles';

const LinkStyled = styled(Link)(styles.link);
const PaperStyled = styled(Paper)(styles.paper);
const BoxBodyContainer = styled(Box)(styles.bodyContainer);
const TypographyH6Styled = styled(Typography)(styles.h6);
const TypographyMainTextStyled = styled(Typography)(styles.mainText);
const TextFieldStyled = styled(TextField)(styles.textField);
const TypographySuccessTextStyled = styled(Typography)(styles.progressText);
const BoxButtonContainerStyled = styled(Box)(styles.buttonContainer);
const CancelButtonStyled = styled(Button)(styles.cancelButton);
const SubmitButtonStyled = styled(Button)(styles.submitButton);
const BoxContentContainer = styled(Box)(styles.contentContainer);
const StyledHeaderAlert = styled(Alert)(styles.headerAlert);

/**
 * ForgotPassword is used to display a link that open a modal window of password recovery.
 *
 * `import { ForgotPassword } from '@fsp-io/shared-ui'`
 */
export default function ForgotPassword({
  labelEntry = 'Password?',
  onSubmit,
  onSignIn,
  onClose,
  onCancel,
  onEmailChange,
  loading = false,
  emailSuccessfullySent = false,
  currentEmail = '',
  errors = {},
  // modal element props
  LinkProps = {},
  ModalProps = {},
  PaperProps = {},
  IconButtonProps = {},
  bodyContainerSx = {},
  TypographyModalTitleProps = {},
  TypographyMainTextProps = {},
  TextFieldProps = {},
  TypographySuccessTextProps = {},
  buttonContainerSx = {},
  CancelButtonProps = {},
  SignInButtonProps = {},
  SubmitButtonProps = {},
}: ForgotPasswordProps) {
  const defaultEmailData: EmailData = {
    email: '',
    isValid: true,
    errorMessage: '',
  };
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<EmailData>(defaultEmailData);

  const handleClose = () => {
    onClose && onClose();
    setModalOpen(false);
    setEmailData(defaultEmailData);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    handleClose();
  };

  const handleSignInClick = () => {
    onSignIn && onSignIn();
    handleClose();
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEmailData = { ...emailData, email: event.target.value };

    setEmailData(newEmailData);
    onEmailChange && onEmailChange(newEmailData);
    TextFieldProps.onChange?.(event);
  };

  const handleOutsideTextFieldClick = () => {
    setEmailData(isEmailValid(emailData.email));
  };

  const handleSubmitClick = async () => {
    const checkedEmailData = isEmailValid(emailData.email);

    setEmailData(checkedEmailData);

    if (checkedEmailData.isValid) {
      await onSubmit();
    }
  };

  useEffect(() => {
    if (emailData.email === '' && isModalOpen) {
      setEmailData({ ...defaultEmailData, email: currentEmail });
    }
  }, [isModalOpen]);

  const typographySuccessText = (
    <>
      Instructions to reset your password have been sent to&nbsp;
      <b>{emailData.email}</b>. Please follow the instructions to sign-in.
    </>
  );

  const { disabled: submitButtonDisabled, ...submitButtonProps } =
    SubmitButtonProps;

  return (
    <>
      <LinkStyled
        aria-label="Open the forgot password modal"
        variant="subtitle2"
        {...LinkProps}
        onClick={() => setModalOpen(true)}
      >
        {labelEntry}
      </LinkStyled>

      <Modal {...ModalProps} open={isModalOpen} onClose={handleClose}>
        <Box>
          <PaperStyled elevation={6} {...PaperProps}>
            {errors.submitAction && (
              <StyledHeaderAlert color="error" severity="error">
                <Typography variant="subtitle2">
                  {errors.submitAction}
                </Typography>
              </StyledHeaderAlert>
            )}

            <BoxContentContainer>
              <BoxBodyContainer sx={bodyContainerSx}>
                <Box display="flex" justifyContent="space-between">
                  <TypographyH6Styled
                    variant="h6"
                    {...TypographyModalTitleProps}
                  >
                    {TypographyModalTitleProps.label ?? 'Recover password'}
                  </TypographyH6Styled>

                  <IconButton {...IconButtonProps} onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                {!emailSuccessfullySent && (
                  <>
                    <TypographyMainTextStyled {...TypographyMainTextProps}>
                      {TypographyMainTextProps.label ??
                        'Enter your email address and we will send instructions to reset your password.'}
                    </TypographyMainTextStyled>

                    <ClickAwayListener
                      onClickAway={handleOutsideTextFieldClick}
                    >
                      <TextFieldStyled
                        fullWidth
                        showBorder
                        label="Email"
                        value={emailData.email}
                        onChange={handleEmailChange}
                        error={!emailData.isValid}
                        helperText={emailData.errorMessage}
                        {...omit(TextFieldProps, 'inputProps')}
                        inputProps={{
                          'aria-label': 'Email field input',
                          ...TextFieldProps?.inputProps,
                        }}
                      />
                    </ClickAwayListener>

                    {loading && <LinearProgress sx={{ mt: 3 }} />}
                  </>
                )}

                {emailSuccessfullySent && (
                  <TypographySuccessTextStyled {...TypographySuccessTextProps}>
                    {TypographySuccessTextProps.label ?? typographySuccessText}
                  </TypographySuccessTextStyled>
                )}
              </BoxBodyContainer>

              <BoxButtonContainerStyled sx={buttonContainerSx}>
                <CancelButtonStyled
                  color="secondary"
                  variant="outlined"
                  aria-label="Cancel"
                  {...CancelButtonProps}
                  onClick={handleCancel}
                >
                  {CancelButtonProps.label ?? 'Cancel'}
                </CancelButtonStyled>

                {emailSuccessfullySent ? (
                  <SubmitButtonStyled
                    color="primary"
                    variant="outlined"
                    aria-label="Sign In"
                    {...SignInButtonProps}
                    onClick={handleSignInClick}
                    disabled={!emailSuccessfullySent}
                  >
                    {SignInButtonProps.label ?? 'Sign In'}
                  </SubmitButtonStyled>
                ) : (
                  <SubmitButtonStyled
                    color="primary"
                    variant="outlined"
                    aria-label="Submit"
                    type="submit"
                    {...submitButtonProps}
                    onClick={handleSubmitClick}
                    disabled={submitButtonDisabled ?? !emailData.isValid}
                  >
                    {SubmitButtonProps.label ?? 'Submit'}
                  </SubmitButtonStyled>
                )}
              </BoxButtonContainerStyled>
            </BoxContentContainer>
          </PaperStyled>
        </Box>
      </Modal>
    </>
  );
}
