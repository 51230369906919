import { forwardRef } from 'react';

import {
  MenuList as MuiMenuList,
  MenuListProps as MuiMenuListProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export type MenuListProps = MuiMenuListProps

// override styles here, as there are no CSS rule name to use component's styleOverrides property in a custom theme.
const StyledMuiMenuList = styled(MuiMenuList)({
  '&.MuiList-padding': {
    paddingBottom: '4px',
    paddingTop: '4px',
  },
  // prevent collapse of first/last menu item margin
  '&:after': {
    content: '""',
    display: 'table',
  },
  '&:before': {
    content: '""',
    display: 'table',
  },
});

/**
 * A permanently displayed menu following https://www.w3.org/TR/wai-aria-practices/#menubutton.
 * It's exposed to help customization of the `Menu` component if you
 * use it separately you need to move focus into the component manually. Once
 * the focus is placed inside the component it is fully keyboard accessible.
 *
 * `import MenuList from '@fsp-io/MenuList'`
 */
const MenuList = forwardRef<HTMLUListElement, MenuListProps>(
  (
    {
      autoFocus = false,
      autoFocusItem = false,
      disabledItemsFocusable = false,
      disableListWrap = false,
      variant = 'selectedMenu',
      ...rest
    },
    ref,
  ) => {
    return (
      <StyledMuiMenuList
        autoFocus={autoFocus}
        autoFocusItem={autoFocusItem}
        disabledItemsFocusable={disabledItemsFocusable}
        disableListWrap={disableListWrap}
        variant={variant}
        {...rest}
        ref={ref}
      />
    );
  },
);

MenuList.displayName = 'MenuList';

export default MenuList;
