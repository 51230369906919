import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react'

interface DateFormatProviderProps {
  children: ReactNode
}

export type DateFormatContextType = {
  dateTimeFormat: string
  handleChangeDateTimeFormat: (dateFormat: string, timeFormat: string) => void

  dateFormat: string
  timeFormat: string
}

export const DateFormatContext = createContext<DateFormatContextType>(
  {} as DateFormatContextType
)

export const DateFormatProvider: FC<DateFormatProviderProps> = (
  props: DateFormatProviderProps
) => {
  const savedDateFormat = 'MM/dd/yyyy'
  const savedTimeFormat = 'HH:mm:ss'

  const [dateTimeFormat, setDateTimeFormat] = useState(
    `${savedDateFormat}, ${savedTimeFormat}`
  )

  const handleChangeDateTimeFormat = (
    dateFormat: string,
    timeFormat: string
  ) => {
    setDateTimeFormat(`${dateFormat}, ${timeFormat}`)
  }

  return (
    <DateFormatContext.Provider
      value={{
        dateTimeFormat,
        handleChangeDateTimeFormat,
        dateFormat: savedDateFormat,
        timeFormat: savedTimeFormat,
      }}
    >
      {props.children}
    </DateFormatContext.Provider>
  )
}

export const useDateFormatContext = () => useContext(DateFormatContext)
