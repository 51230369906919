import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Box, IconButton, Typography } from '@industrial-plus/shared-ui'
import { SwapVert, FilterAlt } from '@mui/icons-material'
import { useTheme } from '@mui/material'

import {
  useDismissAllNotificationsMutation,
  useReadAllNotificationsMutation,
} from '@/__generated__/graphql'
import OptionsMenu from '@/app/components/options-menu/OptionsMenu'

import { FilterValues } from './NotificationInterfaces'
import { NotificationPopupFilter } from './NotificationPopupFIlter'
import {
  FiltersContainer,
  HeaderContainer,
  IconContainer,
  NotificationHeader,
  TotalCountContainer,
} from './NotificationPopupStyled'

export interface NotificationPopupHeaderProps {
  totalCount: number
  areFiltersActive: boolean
  businessUnits: (string | undefined)[]
  autronicaTypes: (string | undefined)[]
  marioffTypes: (string | undefined)[]
  sites: (string | null | undefined)[]
  handleFilter: (input: FilterValues) => void
  handleSortClick: () => void
}

export const NotificationPopupHeader: React.FC<
  NotificationPopupHeaderProps
> = ({
  totalCount,
  areFiltersActive,
  businessUnits,
  autronicaTypes,
  marioffTypes,
  sites,
  handleFilter,
  handleSortClick,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [dismissAllNotifications] = useDismissAllNotificationsMutation({
    context: {
      clientName: 'notifications',
    },
    refetchQueries: ['PushNotifications'],
    awaitRefetchQueries: true,
  })
  const [readAllNotifications] = useReadAllNotificationsMutation({
    context: {
      clientName: 'notifications',
    },
    refetchQueries: ['PushNotifications'],
    awaitRefetchQueries: true,
  })

  const extendedOptions = [
    {
      label: t('common.actions.markAllRead'),
      onClick: () => {
        readAllNotifications()
      },
    },
    {
      label: t('common.actions.dismissAll'),
      onClick: () => {
        dismissAllNotifications()
      },
    },
  ]

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsFilterVisible(!isFilterVisible)
    setAnchorEl(event.currentTarget)
  }

  const handleSort = (event: React.MouseEvent<HTMLElement>) => {
    handleSortClick()
  }

  const handleClose = () => {
    setIsFilterVisible(false)
    setAnchorEl(null)
  }

  return (
    <NotificationHeader>
      <HeaderContainer>
        <Typography sx={{ marginRight: '1rem' }}>
          {t('common.notifications.index')}
        </Typography>
        {(totalCount > 0 || areFiltersActive) && (
          <>
            <TotalCountContainer sx={{ color: theme.colors.primary600 }}>
              {totalCount.toString().padStart(2, '0')}
            </TotalCountContainer>
            <IconContainer onClick={handleSort}>
              <SwapVert
                sx={{ height: '1.5rem', color: theme.colors.primary600 }}
              />
            </IconContainer>
            <FiltersContainer onClick={handleFilterClick}>
              <FilterAlt />
              {t('common.filters')}
            </FiltersContainer>
            <NotificationPopupFilter
              open={isFilterVisible}
              handleClose={handleClose}
              anchorEl={anchorEl}
              businessUnits={businessUnits}
              autronicaTypes={autronicaTypes}
              marioffTypes={marioffTypes}
              sites={sites}
              handleFilter={handleFilter}
            />
          </>
        )}
      </HeaderContainer>
      <Box>
        <IconButton
          sx={{
            height: '1.5rem',
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent',
            },
          }}
        >
          <OptionsMenu options={extendedOptions} disabled={false} />
        </IconButton>
      </Box>
    </NotificationHeader>
  )
}
