import { forwardRef, Ref } from 'react';

import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';

import Divider from '../divider';

const menuItemHeight = {
  small: '32px',
  medium: '40px',
  large: '48px',
};

export interface MenuItemProps extends MuiMenuItemProps {
  /**
   *
   * A ref that points to the TouchRipple element.
   */
  touchRippleRef?: Ref<TouchRippleActions>;
  /**
   * Size of the component
   */
  size?: keyof typeof menuItemHeight;
  /**
   * Spacing between MenuItems
   * @default true
   */
  padded?: boolean;
}

/**
 *  MenuItem must be used a child component of the Menu or MenuList.
 *
 * `import MenuItem from '@fsp-io/MenuItem'`
 */

const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  ({ divider, size = 'medium', sx, padded = true, ...rest }, ref) => {
    return (
      <>
        <MuiMenuItem
          divider={divider}
          sx={[
            {
              height: menuItemHeight[size],
            },
            padded && {
              height: `calc(${menuItemHeight[size]} - 8px)`,
              margin: '4px',
              borderRadius: '4px',
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          {...rest}
          ref={ref}
        />
        {divider && <Divider />}
      </>
    );
  },
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
