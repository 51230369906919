import { SystemStyleObject } from '@mui/system';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { ClassAttributes, LiHTMLAttributes, forwardRef } from 'react';
import { getSxStyles } from '../../utils/styles.utils';
import Typography from '../typography';

declare module '@mui/material/Autocomplete' {
  interface AutocompletePropsSizeOverrides {
    xsmall: true;
  }
}

export interface AutocompleteProps
  extends Omit<
    MuiAutocompleteProps<any, boolean, boolean, boolean>,
    'classes'
  > {
  /**
   * Enable string Highlighting of user input. Disabled if getOptionLabel is not defined.
   * Options provided must be an Array of Strings in order for the String Highlight to function.
   */
  highlightMatchedString?: boolean;
}

/**
 *
 *  The autocomplete is a normal text input enhanced by a panel of suggested options.
 *
 * `import { Autocomplete } from '@fsp-io/shared-ui'`
 */

const MuiAutocompleteStyled = styled(MuiAutocomplete)({
  [`.${autocompleteClasses.endAdornment}`]: {
    top: 'calc(50% - 16px)',
  },
});

/**
 *
 *  The autocomplete is a normal text input enhanced by a panel of suggested options.
 *
 * `import { Autocomplete } from '@fsp-io/shared-ui'`
 */

const Autocomplete = forwardRef<unknown, AutocompleteProps>(
  (
    { highlightMatchedString, defaultValue, renderOption, sx, ...rest },
    ref
  // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const defaultValueOverride = defaultValue;

    const highlightStringRenderOptions = (
      props: JSX.IntrinsicAttributes &
        ClassAttributes<HTMLLIElement> &
        LiHTMLAttributes<HTMLLIElement>,
      option: any,
      { inputValue }: any
    ) => {
      if (rest.getOptionLabel && defaultValue) {
        defaultValue = rest.getOptionLabel(defaultValue);
      }
      const matches = match(option, inputValue, { insideWords: true });
      const parts = parse(option, matches);

      return (
        <li {...props}>
          <div>
            {parts.map((part, index) => (
              <Typography
                variant="body1"
                key={index}
                display="inline"
                sx={(theme) =>
                  ({
                    fontWeight: part.highlight ? 700 : 400,
                    color: part.highlight
                      ? theme.palette.primary.main
                      : 'inherit',
                    ...getSxStyles(theme),
                  } as SystemStyleObject)
                }
              >
                {part.text}
              </Typography>
            ))}
          </div>
        </li>
      );
    };

    const renderOptionOverride =
      highlightMatchedString &&
      rest.getOptionLabel &&
      typeof rest.options[0] === 'string'
        ? highlightStringRenderOptions
        : renderOption;

    return (
      <MuiAutocompleteStyled
        data-testid="test-autocomplete-root"
        {...rest}
        ref={ref}
        defaultValue={defaultValueOverride}
        renderOption={renderOptionOverride}
        sx={(theme) => {
          if (rest.size === 'xsmall') {
            return {
              '> div': {
                margin: '0 0 0 0',
                '> label, .MuiFormLabel-root': {
                  display: 'none',
                },
                ' > div, .MuiInputBase-root': {
                  maxHeight: 44,
                  paddingTop: 0,
                  'div, .MuiButtonBase-root': {
                    height: 'fit-content',
                    'button, .MuiButtonBase-root': {
                      padding: '0 0 0 0',
                    },
                  },
                  '> input, .MuiInputBase-input': {
                    padding: '2.5px 4px',
                  },
                },
              },
              ...getSxStyles(theme, sx),
            } as SystemStyleObject;
          } else {
            return {
              ...getSxStyles(theme, sx),
            } as SystemStyleObject;
          }
        }}
      />
    );
  }
);

Autocomplete.displayName = 'Autocomplete';

export default Autocomplete;
