import { TypographyOptions } from "../types/typography";
import { fontFamily } from "./fonts";

export const TypographyVariantMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "h6",
  subtitle2: "h6",
  body1: "p",
  body2: "p",
  body3: "p",
  inherit: "p",
  buttonLarge: "span",
  buttonMedium: "span",
  buttonSmall: "span",
  caption: "span",
  avatarLetter: "span",
  inputText1: "span",
  inputText2: "span",
  alertTitle: "span",
  tableHeader1: "span",
  tableHeader2: "span",
  inputLabel: "span",
  chip: "span",
  helperText: "span",
  badge: "span",
  tooltip: "span",
};

export const fleetTypography: TypographyOptions = {
  fontFamily,
  htmlFontSize: 16,
  fontSize: 17,
  fontWeightExtraLight: 200,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  fontWeightBlack: 900,
  h1: {
    fontFamily,
    fontSize: "6rem",
    fontWeight: 800,
    lineHeight: "8.184rem",
    letterSpacing: "-0.094rem",
  },
  h2: {
    fontFamily,
    fontSize: "3.75rem",
    fontWeight: 800,
    lineHeight: "5.115rem",
    letterSpacing: "-0.031rem",
  },
  h3: {
    fontFamily,
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: "4.092rem",
    letterSpacing: "-0.016rem",
  },
  h4: {
    fontFamily,
    fontSize: "2.125rem",
    fontWeight: 700,
    lineHeight: "2.899rem",
    letterSpacing: "-0.009rem",
  },
  h5: {
    fontFamily,
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: "2.046rem",
  },
  h6: {
    fontFamily,
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: "1.705rem",
  },
  subtitle1: {
    fontFamily,
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
  },
  subtitle2: {
    fontFamily,
    fontSize: "0.875rem",
    fontWeight: 600,
    lineHeight: "1rem",
  },
  body1: {
    fontFamily,
    fontSize: "1rem",
    fontWeight: "normal",
    lineHeight: "1.5rem",
  },
  body2: {
    fontFamily,
    fontSize: "0.875rem",
    fontWeight: "normal",
    lineHeight: "1.125rem",
  },
  body3: {
    fontFamily,
    fontSize: "0.75rem",
    fontWeight: "normal",
    lineHeight: "0.875rem",
    letterSpacing: 0,
  },
  buttonLarge: {
    fontFamily,
    fontSize: "0.938rem",
    fontWeight: 700,
    letterSpacing: "0.016rem",
    textTransform: "capitalize",
    lineHeight: "1.625rem",
  },
  buttonMedium: {
    fontFamily,
    fontSize: "0.875rem",
    fontWeight: 700,
    letterSpacing: "0.016rem",
    textTransform: "capitalize",
    lineHeight: "1.5rem",
  },
  buttonSmall: {
    fontFamily,
    fontSize: "0.813rem",
    fontWeight: 700,
    letterSpacing: "0.016rem",
    textTransform: "capitalize",
    lineHeight: "1.375rem",
  },
  caption: {
    fontFamily,
    fontSize: "0.75rem",
    fontWeight: "normal",
    letterSpacing: "0.016rem",
    lineHeight: "0.875rem",
  },
  overline: {
    fontFamily,
    fontSize: "0.75rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    letterSpacing: "0.063rem",
    textTransform: "uppercase",
  },
  avatarLetter: {
    fontFamily,
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    letterSpacing: "0.031rem",
    textTransform: "uppercase",
  },
  inputText1: {
    fontFamily,
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    letterSpacing: 0,
  },
  inputText2: {
    fontFamily,
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    letterSpacing: 0,
  },
  alertTitle: {
    fontFamily,
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.188rem",
    letterSpacing: "0.0063rem",
  },
  tableHeader1: {
    fontFamily,
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: "1.188rem",
    letterSpacing: "0.016rem",
  },
  tableHeader2: {
    fontFamily,
    fontSize: "0.875rem",
    fontWeight: 700,
    lineHeight: "1.188rem",
    letterSpacing: "0.016rem",
  },
  inputLabel: {
    fontFamily,
    fontSize: "0.75rem",
    fontWeight: "normal",
    lineHeight: "0.75rem",
  },
  chip: {
    fontFamily,
    fontSize: "0.875rem",
    fontWeight: 600,
    lineHeight: "1.125rem",
  },
  helperText: {
    fontFamily,
    fontSize: "0.75rem",
    fontWeight: "normal",
    lineHeight: "1.25rem",
    letterSpacing: "0.009rem",
  },
  badge: {
    fontFamily,
    fontSize: "0.75rem",
    fontWeight: 600,
    lineHeight: "0.875rem",
  },
  tooltip: {
    fontFamily,
    fontSize: "0.75rem",
    fontWeight: 700,
    lineHeight: "0.875rem",
    letterSpacing: "0.016rem",
  },
};

