import { forwardRef } from 'react';
import { Button as MuiButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { getSxStyles } from '../../utils/styles.utils';
import { ButtonProps } from './types';
import { SystemStyleObject } from '@mui/system';

/**
 * Buttons allow users to take actions, and make choices, with a single tap.
 */
export const FONT_FAMILY = ["Red Hat Display", 'sans-serif'].join(', ');

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color = 'primary',
      size,
      variant = 'contained',
      disableElevation = true,
      sx,
      ...rest
    },
    ref,
  ) => {
    return (
      <MuiButton
        ref={ref}
        color={color}
        size={size}
        variant={variant}
        disableElevation={disableElevation}
        sx={(theme: Theme) => getSxStyles(theme, sx) as SystemStyleObject}
        {...rest}
        //FDS design -> Following is custom change for FDS Button of type 'text'
        style={{
          border: variant === 'text' ? 'none' : '',
          fontFamily: FONT_FAMILY,
        }}
      />
    );
  },
);

Button.displayName = 'Button';

export default Button;
