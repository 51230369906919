import { forwardRef } from 'react';

import {
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
} from '@mui/material';

export interface PopperProps extends Omit<MuiPopperProps, 'dir'> {
  direction: 'ltr' | 'rtl';
}
/** A Popper can be used to display some content on top of another.
 *
 * `import { Popper } from '@fsp-io/shared-ui'`
 */

const Popper = forwardRef<HTMLDivElement, PopperProps>(
  (
    {
      direction = 'ltr',
      disablePortal = false,
      keepMounted = false,
      placement = 'bottom',
      popperOptions = {},
      transition = false,
      ...rest
    },
    ref,
  ) => {
    return (
      <MuiPopper
        dir={direction}
        disablePortal={disablePortal}
        keepMounted={keepMounted}
        placement={placement}
        popperOptions={popperOptions}
        transition={transition}
        {...rest}
        ref={ref}
      />
    );
  },
);

Popper.displayName = 'Popper';

export default Popper;
