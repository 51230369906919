import { SystemStyleObject } from '@mui/system';
import { ColumnPosition } from './types';

export const getPositionStyles = (position: ColumnPosition): SystemStyleObject => {
  const positions = {
    left: { justifyContent: 'flex-start' },
    middle: { justifyContent: 'center' },
    right: { justifyContent: 'flex-end' },
  };

  return positions[position] || {};
};
