import Box from "../box";
import { FC } from "react";
import { styled } from "@mui/material/styles";
import FooterColumn from "./FooterColumn";
import { SignInFooterProps } from "./types";
import styles from "./styles";

const FooterContainer = styled(Box)(styles.container);

const SignInFooter: FC<SignInFooterProps> = ({
  className,
  left,
  middle,
  right,
  ...rest
}) => {
  return (
    <FooterContainer className={className} {...rest}>
      <FooterColumn position="left">{left}</FooterColumn>
      <FooterColumn position="middle">{middle}</FooterColumn>
      <FooterColumn position="right">{right}</FooterColumn>
    </FooterContainer>
  );
};

export default SignInFooter;
