import { forwardRef } from 'react';

import {
  CardMedia as MuiCardMedia,
  CardMediaProps as MuiCardMediaProps,
} from '@mui/material';

export type CardMediaProps = MuiCardMediaProps;

/** CardMedia
 *
 * `import { CardMedia } from '@fsp-io/shared-ui'`
 */

const CardMedia = forwardRef<HTMLDivElement, CardMediaProps>((props, ref) => {
  return <MuiCardMedia {...props} ref={ref} />;
});

CardMedia.displayName = 'CardMedia';

export default CardMedia;
