import { forwardRef } from 'react';

import MuiTableRow, {
  TableRowProps as MuiTableRowProps,
} from '@mui/material/TableRow';

export interface TableRowProps extends MuiTableRowProps {}

/** TableRow
 *
 * `import { TableRow } from '@fsp-io/shared-ui'`
 */

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  (props, ref) => {
    return <MuiTableRow {...props} ref={ref} />;
  },
);

TableRow.displayName = 'TableRow';

export default TableRow;
