export const activeShadowAlpha = 0.08;
export const baseMargin = "1px";
export const basePadding = "0px";
export const baseTransitionDuration = "300ms";
export const baseShadowVariant = 2;

export const TrackHeightMap = {
  medium: "20px",
  small: "16px",
};

export const TrackWidthMap = {
  medium: "38px",
  small: "32px",
};

export const ThumbSizeMap = {
  medium: "18px",
  small: "14px",
};

export const ShadowRadiusMap = {
  medium: "9px",
  small: "5px",
};
