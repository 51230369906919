import fleetPalette from './constants/colors';
import fleetShadows from './constants/shadows';
import { fleetComponents } from './constants/fleetComponents';
import { fleetTypography } from './constants/typography';

export const fleetThemeOptions = {
  palette: fleetPalette,
  typography: fleetTypography,
  components: fleetComponents,
  shadows: fleetShadows,
};
