import { forwardRef } from "react";

import MuiFilledInput, {
  FilledInputProps as MuiInputProps,
} from "@mui/material/FilledInput";
import clsx from "clsx";
import { SystemStyleObject } from '@mui/system';
import { getSxStyles } from "../../utils/styles.utils";
import { InputMaxHeightMap } from '../../utils/input.utils';

export interface InputProps
  extends Omit<MuiInputProps, "variant" | "size" | "margin"> {
  /** Add Class to the Input */
  className?: string;

  /** Set to true to remove background color. */
  hideBackgroundColor?: boolean;

  /** Set to true to show a border around the Input */
  showBorder?: boolean;

  /** Set to true to show an underline on the Input  */
  showUnderline?: boolean;

  /** The Input's Size */
  size?: "xsmall" | "small" | "medium";
}

/** Input
 *
 * `import Input from '@fsp-io/Input'`
 */

const Input = forwardRef<unknown, InputProps>(
  (
    {
      className = "",
      color = "primary",
      hideBackgroundColor = false,
      showBorder = false,
      showUnderline = false,
      size = "medium",
      ...rest
    },
    ref
  ) => {
    return (
      <MuiFilledInput
        /* MUI doesn’t support the xsmall size, but Fleet does.
         * So just pass small down to make MUI happy.
         * We use CSS to apply the xsmall styling separately.
         */
        size={size === "xsmall" ? "small" : size}
        hiddenLabel={size === "xsmall" || rest.hiddenLabel}
        disableUnderline={!showUnderline || rest.disableUnderline}
        margin={size === "medium" ? "none" : "dense"}
        color={rest.error ? "error" : color}
        //This adds a class name to the Input
        className={clsx({ sizeXSmall: size === "xsmall" }, className)}
        sx={(theme) =>
          ({
            ...getSxStyles(theme, rest.sx),
            // Conditionally apply properties
            ...(size !== "medium" && { maxHeight: InputMaxHeightMap[size] }),
            ...(!showBorder && { border: "0px!important" }),
            ...(hideBackgroundColor && {
              backgroundColor: "inherit!important",
            }),
          } as SystemStyleObject)
        }
        {...rest}
        ref={ref}
      />
    );
  }
);

Input.displayName = "Input";

export default Input;
