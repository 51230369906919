import { forwardRef } from 'react';

import {
  StepLabel as MuiStepLabel,
  StepLabelProps as MuiStepLabelProps,
} from '@mui/material';

export type StepLabelProps = MuiStepLabelProps

/**
 * `import { StepLabel } from '@fsp-io/shared-ui'`
 */

const StepLabel = forwardRef<HTMLDivElement, StepLabelProps>((props, ref) => {
  return <MuiStepLabel {...props} ref={ref} />;
});

StepLabel.displayName = 'StepLabel';

export default StepLabel;
