import React, { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@industrial-plus/shared-ui'
import { useOktaAuth } from '@okta/okta-react'

import LogoutIcon from '@/assets/svg/logout.svg'
import { COLORS } from '@/constants/colors'
import { PATHS } from '@/constants/paths'

import NameAvatar from './NameAvatar'

interface Props {
  open: boolean
}

interface UserInfo {
  name?: string
  givenName?: string
  familyName?: string
  email?: string
}

const LogoutButton: React.FC<Props> = (props: Props) => {
  const { open } = props
  const { t } = useTranslation()

  const settings = ['common.profile', 'common.logout']

  const navigate = useNavigate()
  const { authState, oktaAuth } = useOktaAuth()

  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)

  // User Menu Items
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleSignOut = useCallback(() => {
    navigate(PATHS.auth.logout)
  }, [navigate])

  useEffect(() => {
    const fetchUser = async () => {
      if (!oktaAuth || !authState || !authState.isAuthenticated) {
        setUserInfo(null)
      } else {
        try {
          const oktaAuthInfo = await oktaAuth.token.getUserInfo()

          localStorage.setItem(
            'userInfo',
            JSON.stringify({
              ...oktaAuthInfo,
            })
          )

          setUserInfo({
            name: oktaAuthInfo.name,
            givenName: oktaAuthInfo.given_name,
            familyName: oktaAuthInfo.family_name,
            email: oktaAuthInfo.email,
          })
        } catch (error) {
          console.log('err in getting user info from OKTA:', error)
          //This happens when user session is expired.
          handleSignOut()
        }
      }
    }

    fetchUser()
  }, [authState, oktaAuth, handleSignOut])

  const handleProfileNavigation = () => {
    navigate(PATHS.users.profile)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    const { menuItemValue } = event.currentTarget.dataset
    switch (menuItemValue) {
      case settings[0]:
        handleProfileNavigation()
        break
      case settings[1]:
        handleSignOut()
        break

      default:
        break
    }
    setAnchorElUser(null)
  }

  // Case: Drawer not open
  if (!open) {
    return (
      <Box
        padding={2}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Tooltip title={userInfo?.name}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <NameAvatar name={userInfo?.givenName} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ ml: '2.8125rem', cursor: 'pointer' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem
              key={setting}
              data-menu-item-value={setting}
              onClick={handleCloseUserMenu}
            >
              <Typography textAlign="center">{t(setting)}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    )
  }

  // Case: Drawer open
  return (
    <Box
      padding={2}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={handleProfileNavigation}
        sx={{ cursor: 'pointer' }}
        width="calc(100% - 2rem)"
      >
        <NameAvatar name={userInfo?.givenName} />

        <Box
          mx={1}
          display="flex"
          flexDirection="column"
          width="calc(100% - 2.8125rem)"
        >
          <Typography
            variant="inputText2"
            color={COLORS.black}
            fontWeight={600}
            noWrap
          >
            {userInfo?.name}
          </Typography>
          <Typography
            variant="badge"
            lineHeight="18px"
            color={COLORS.gray500}
            noWrap
          >
            {userInfo?.email}
          </Typography>
        </Box>
      </Box>

      <Box
        height="2rem"
        width="2rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexShrink={0}
        sx={{ cursor: 'pointer' }}
        onClick={handleSignOut}
      >
        <img src={LogoutIcon} alt="Logout" height="100%" width="100%" />
      </Box>
    </Box>
  )
}

export default React.memo(LogoutButton)
