import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  Theme,
} from '@mui/material';
import { forwardRef } from 'react';
import { getSxStyles } from '../../utils/styles.utils';
import { SystemStyleObject } from '@mui/system';

export interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  color?: 'primary' | 'default';
}

/** Breadcrumbs
 *
 * The Breadcrumbs component is a set of links that help users visualize the current location within a hierarchical structure and provides navigation through it.
 *
 * `import { Breadcrumbs } from '@fsp-io/shared-ui'`
 */

const Breadcrumbs = forwardRef<HTMLElement, BreadcrumbsProps>(
  ({ sx, color, ...rest }, ref) => {
    const breadcrumbSX = (theme: Theme) =>
      ({
        ...getSxStyles(theme, sx),
        color:
          color == 'primary'
            ? theme.palette.primary.main
            : theme.palette.action.active,
        '& .MuiBreadcrumbs-separator': {
          color: theme.palette.addition?.outlinedBorder,
        },
        '& .MuiBreadcrumbs-li:last-child': {
          color: theme.palette.text.primary,
        },
      } as SystemStyleObject);

    return <MuiBreadcrumbs {...rest} sx={breadcrumbSX} ref={ref} />;
  },
);

Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;
