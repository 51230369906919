import { forwardRef } from "react";

import MuiRadio, { RadioProps as MuiRadioProps } from "@mui/material/Radio";
import { getSxStyles } from "../../utils/styles.utils";
import { Theme } from "@mui/material";
import { SystemStyleObject } from '@mui/system';

export interface RadioProps extends MuiRadioProps {
  disableHover?: boolean;
}

const defaultRadioIcon = (size: RadioProps["size"]) => (
  <svg
    width={size === "small" ? "20" : "24"}
    height={size === "small" ? "20" : "24"}
    viewBox="-1 -1 20 20"
    fill="none"
    stroke="#0000008A"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
      fill="#0000008A"
      fillOpacity="0.54"
    />
  </svg>
);

const defaultCheckedRadioIcon = (size: RadioProps["size"]) => (
  <svg
    width={size === "small" ? "20" : "24"}
    height={size === "small" ? "20" : "24"}
    viewBox="-1 -1 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
      fillOpacity="0.87"
    />
  </svg>
);

/** Radio
 *
 * The Radio component allows users to select a single item from a set or list.
 *
 * `import { Radio } from '@fsp-io/shared-ui'`
 */

const Radio = forwardRef<HTMLButtonElement, RadioProps>(
  ({ sx, disableHover = true, color = "default", ...rest }, ref) => {
    const radioSX = (theme: Theme) =>
      ({
        ...getSxStyles(theme, sx),
        p: 0,
        ...(rest.size === "small"
          ? { height: 32, width: 32 }
          : { height: 40, width: 40 }),
        svg: {
          stroke: (theme: Theme) => theme.palette.action.active,
          strokeWidth: 0,
        },
        "&.Mui-disabled": {
          svg: {
            stroke: (theme: Theme) => theme.palette.action.disabled,
          },
        },
        ...(disableHover && {
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&.Mui-checked:hover": {
            backgroundColor: "transparent",
          },
        }),
      } as SystemStyleObject);

    return (
      <MuiRadio
        color={color}
        disableRipple={true}
        checkedIcon={
          rest.checkedIcon
            ? rest.checkedIcon
            : defaultCheckedRadioIcon(rest.size)
        }
        icon={rest.icon ? rest.icon : defaultRadioIcon(rest.size)}
        {...rest}
        sx={radioSX}
        ref={ref}
      />
    );
  }
);

Radio.displayName = "Radio";

export default Radio;
