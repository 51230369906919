import React, { useState } from 'react'

import { Box } from '@industrial-plus/shared-ui'
import AlertWarning from '@/app/fcm/AlertWarning'
import { useCurrentUserQuery, UserRoles } from '@/__generated__/graphql'

import { Body } from './styles'
import Header from '../header/Header'
import SideNav from '../side-nav/SideBar'

interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useState(false)

  const { data } = useCurrentUserQuery({
    context: {
      clientName: 'users',
    },
  })

  const userRole = data?.currentUser?.user?.role

  return (
    <Box display="flex">
      {/* Header */}
      <Header open={open} />
      {/* SideNav */}
      <SideNav open={open} setOpen={setOpen} />

      {/* Main Content */}
      <Body open={open}>
        {userRole !== UserRoles.ADMIN && <AlertWarning />}
        {props.children}
      </Body>
    </Box>
  )
}

export default React.memo(Layout)
