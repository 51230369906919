import React, { SyntheticEvent } from 'react'
import { useField } from 'formik'
import { FormInputProps } from '../dynamic-formik/types'
import Checkbox, { CheckboxProps } from '../checkbox'
import FormControlLabel from '../form-control-label'
import FormHelperText from '../form-helper-text'

interface Props extends CheckboxProps {
  input: FormInputProps
}

const TEXTFIELD_DEFAULTS = {
  error: false,
  helperText: '',
}

const FormikCheckbox: React.FC<Props> = (props: Props) => {
  const [field, meta, helper] = useField(props.input.name)

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    helper.setValue(checked, true)
  }

  const checkboxProps = {
    ...TEXTFIELD_DEFAULTS,
    ...props.input,
    ...field,
  }

  // Extract helperText to avoid spreading it onto DOM elements
  const { helperText, ...restCheckboxProps } = checkboxProps

  if (meta && meta.touched && meta.error) {
    restCheckboxProps.error = true
    checkboxProps.helperText = meta.error
  }

  return (
    <>
      <FormControlLabel
        label={props.input.label}
        required={props.input.required}
        disabled={props.input.disabled}
        onChange={handleOnChange}
        checked={field.value}
        control={<Checkbox {...restCheckboxProps} />}
      />
      <FormHelperText {...checkboxProps.FormHelperTextProps}>
        {helperText}
      </FormHelperText>
    </>
  )
}

export default React.memo(FormikCheckbox)
