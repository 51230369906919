import { PATHS } from '@/constants/paths'

const CLIENT_ID = process.env['NX_CLIENT_ID']
const OKTA_DOMAIN = process.env['NX_OKTA_DOMAIN']
const ISSUER = `https://${OKTA_DOMAIN}/oauth2/default`
const CALLBACK_PATH = PATHS.auth.login_callback
const REDIRECT_URI = `${window.location.origin}${CALLBACK_PATH}`
const SCOPES = 'openid profile email groups'

export const oktaAuthConfig = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
}

export const oktaSignInConfig = {
  baseUrl: `https://${OKTA_DOMAIN}`,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
}
