import { forwardRef } from 'react';

import { Icon as MuiIcon, IconProps as MuiIconProps } from '@mui/material';

export type IconProps = MuiIconProps

declare module '@mui/material/Icon' {
  interface IconPropsSizeOverrides {
    xsmall: true;
  }
}
/** Icons allow users to take actions, and make choices, with a single tap.
 *
 * `import { Icon } from '@fsp-io/shared-ui'`
 */

const Icon = forwardRef<HTMLSpanElement, IconProps>((props, ref) => {
  return <MuiIcon {...props} ref={ref} />;
});

Icon.displayName = 'Icon';

export default Icon;
