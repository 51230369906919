import { forwardRef } from 'react';

import {
  StepIcon as MuiStepIcon,
  StepIconProps as MuiStepIconProps,
} from '@mui/material';

export type StepIconProps = MuiStepIconProps

/**
 * `import { StepIcon } from '@fsp-io/shared-ui'`
 */

const StepIcon = forwardRef<HTMLDivElement, StepIconProps>((props, ref) => {
  return <MuiStepIcon {...props} ref={ref} />;
});

StepIcon.displayName = 'StepIcon';

export default StepIcon;
