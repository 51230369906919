import * as Yup from 'yup'

import { AnyObject } from 'yup/lib/types'

import { FormInputProps, FormSectionInputProps } from './types'

type YupBoolean = Yup.BooleanSchema<
  boolean | undefined,
  AnyObject,
  boolean | undefined
>
type YupString = Yup.StringSchema<
  string | undefined,
  AnyObject,
  string | undefined
>

const getErrorMessage = (message?: string, ruleMessage?: string): string => {
  if (message?.length) {
    return message
  }

  return ruleMessage!
}

const generateValidations = (field: FormInputProps) => {
  // eslint-disable-next-line import/namespace
  let schema = Yup[field.typeValue ? field.typeValue : 'string']() // Yup.string()
  let message = ''
  let value

  for (const rule of field.validations || []) {
    switch (rule.type) {
      case 'isTrue':
        message = 'Field is true'
        schema = (schema as YupBoolean).isTrue(
          getErrorMessage(message, rule.message)
        )
        break

      case 'isEmail':
        message = 'Field should be a valid Email'
        schema = (schema as YupString)
          .email(getErrorMessage(message, rule.message))
          .trim()
        break

      case 'minLength':
        value = rule.value as number
        message = `Field should be of at least ${value} characters`
        schema = (schema as YupString)
          .min(value, getErrorMessage(message, rule.message))
          .trim()
        break

      case 'maxLength':
        value = rule.value as number
        message = `Field should contain maximum ${value} characters`
        schema = (schema as YupString)
          .max(value, getErrorMessage(message, rule.message))
          .trim()
        break
      case 'notOneOf':
        value = rule.value as string[]
        message = 'This name is not allowed'
        schema = (schema as YupString).notOneOf(
          value,
          getErrorMessage(rule.message, message)
        )
        break
      case 'regexMatching':
        schema = (schema as YupString)
          .matches(rule.value as RegExp, rule.message)
          .trim()
        break
      case 'positiveNumbers':
        schema = (schema as Yup.NumberSchema).positive(
          'Field should only contain positive numbers'
        )
        break
      default:
        message = 'Field is Required'
        schema = schema.required(getErrorMessage(message, rule.message))
        break
    }
  }

  return schema
}

export const getInputs = (form: FormSectionInputProps[]) => {
  const initialValues: { [key: string]: any } = {}

  const validationsFields: { [key: string]: any } = {}

  for (const section of form) {
    for (const field of section.inputs) {
      initialValues[field.name] = field.value

      if (!field.validations) continue

      const schema = generateValidations(field)

      validationsFields[field.name] = schema
    }
  }

  return {
    initialValues,
    validationSchema: Yup.object({ ...validationsFields }),
  }
}
