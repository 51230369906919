import { useField } from 'formik';
import React from 'react';
import FormControl from '../form-control';
import FormControlLabel from '../form-control-label';
import FormLabel from '../form-label';
import Radio from '../radio';
import RadioGroup from '../radio-group';
import { FormInputProps } from './types';

interface Props {
  input: FormInputProps;
}

const FormikRadioGroup: React.FC<Props> = (props: Props) => {
  const [field, , helper] = useField(props.input.name);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helper.setValue(event.currentTarget.value);
  };

  return (
    <FormControl>
      <FormLabel>{props.input.label}</FormLabel>
      <RadioGroup
        name={field.name}
        value={field.value}
        row
        onChange={handleOnChange}
      >
        {props.input.options?.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color="default" size="small" />}
            label={option.name}
            disabled={props.input.disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default React.memo(FormikRadioGroup);
