import Button from '../button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Box from '../box';
import { SyntheticEvent, useEffect, useState } from 'react';
import List from '../list';
import ListItem from '../list-item';
import IconButton from '../icon-button';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ListItemButton, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import ListItemText from '../list-item-text';
import ListItemIcon from '../list-item-icon';
import Icon from '../icon';
import LinearProgress from '../linear-progress';
import { FileUploadProps } from './types';
import Popper from '../popper';
import ClickAwayListener from '../click-away-listener';
import { fleetShadows } from '../../theme';
import Badge from '../badge';

import { commonStyles } from './styles';

// Common Functions
import {
  getWidth,
  checkInProgressStatus,
  isRejected,
  renderThumbnail,
  FileItem,
} from '../../utils/upload.utils';


/**
 * The Upload component enables the user to upload files of specified mime types.
 *
 * `import { FileUpload } from '@fsp-io/shared-ui'`
 */

const FileUpload = ({
  buttonLabel,
  onClear,
  onFileUpload,
  acceptType = ['.jpg'],
  multiple = true,
  showProgressBar = false,
  uploadButtonSize = 'small',
  fileItems = [],
  helperText,
  itemsToDisplay = 4,
  badgeProps,
  buttonColor = 'secondary',
  onListOpen,
  listType = 'list',
  listPlacement = 'bottom-end',
  inheritBackgroundColor = true,
}: FileUploadProps) => {
  const [fileList, setFileList] = useState<FileItem[] | []>(fileItems);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const imageType = acceptType.join(',');
  const handleFileChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const uploadedFiles: FileList = (event.target as HTMLInputElement)
      .files as FileList;
    const files = Array.from(uploadedFiles).map((fileData: File) => ({
      fileData,
      uploadStatus: 'pending',
    }));
    setFileList([...files, ...fileList] as FileItem[]);
    setOpen(true);
    setAnchorEl(
      event.currentTarget.parentElement?.parentElement as HTMLElement,
    );
  };
  useEffect(() => {
    if (fileList.length) {
      onFileUpload?.(fileList);
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  }, [fileList]);

  const onRemoveHandler = (event: SyntheticEvent<HTMLButtonElement>) => {
    const currentIndex: number = event.currentTarget.getAttribute(
      'data-index',
    ) as unknown as number;
    const newFileList: FileItem[] | [] = Array.from(fileList).filter(
      (_, index) => currentIndex != index,
    );
    setFileList([...newFileList]);
    onClear?.(event);
  };

  const handleClose = () => {
    setTimeout(() => setAnchorEl(null), 1);
    setOpen(false);
  };

  const handleOpen = (e: SyntheticEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(e.currentTarget.parentElement);
    onListOpen?.();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Badge
        variant="standard"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiBadge-anchorOriginTopRightCircular': {
            top: '4px',
            right: '4px',
            boxShadow: `${fleetShadows[3]}`,
          },
        }}
        overlap={'circular'}
        {...badgeProps}
      >
        <Box>
          <Box
            sx={(theme) => ({
              ...(commonStyles.fileUpload(
                theme,
                inheritBackgroundColor,
              ) as SystemStyleObject),
            })}
          >
            <Button
              data-testid="test-fileUpload"
              variant="outlined"
              size={uploadButtonSize}
              color={buttonColor}
              component="label"
              sx={{
                backgroundColor: (theme: Theme) =>
                  theme.palette.background.paper,
              }}
              startIcon={<FileUploadIcon fontSize="small" />}
            >
              {buttonLabel}
              <input
                hidden
                accept={imageType}
                multiple={multiple}
                type="file"
                onChange={handleFileChange}
              />
            </Button>
            <IconButton
              size="medium"
              sx={{ marginLeft: '12px' }}
              onMouseEnter={handleOpen}
              data-testid="test-arrow-down"
            >
              <Icon fontSize="medium">
                <ArrowDropDownIcon
                  sx={{
                    width: '100%',
                    height: '100%',
                    color: (theme: Theme) =>
                      disabledBtn
                        ? theme.palette.action.disabled
                        : theme.palette.secondary.main,
                  }}
                />
              </Icon>
            </IconButton>
          </Box>
          {helperText ? (
            <Typography variant="helperText">{helperText}</Typography>
          ) : null}
          <Popper
            direction={'ltr'}
            open={open}
            anchorEl={anchorEl}
            placement={listPlacement}
            style={{
              boxShadow: `${fleetShadows[4]}`,
              width: '300px',
              maxHeight: itemsToDisplay * 48,
              overflowY: 'auto',
              backgroundColor: 'white',
            }}
          >
            <List
              sx={{
                padding: 0,
                '.MuiListItem-root .MuiListItemButton-root': {
                  padding: '12px',
                },
              }}
            >
              {Array.from(fileList).map((file, index) => (
                <ListItem
                  key={index}
                  title={file.fileData?.name}
                  color={checkInProgressStatus(
                    file.uploadStatus,
                    showProgressBar,
                  )}
                  disablePadding={true}
                  sx={commonStyles.listItem}
                  secondaryAction={
                    <IconButton
                      size="xsmall"
                      onClick={onRemoveHandler}
                      data-index={index}
                      data-testid="test-clear"
                      sx={{
                        '&:hover': {
                          backgroundColor: (theme: Theme) =>
                            `${theme.palette.action.selected}`,
                        },
                      }}
                    >
                      <Icon fontSize={'xsmall'}>
                        <ClearIcon
                          sx={{
                            width: '100%',
                            height: '100%',
                            color: isRejected(file.uploadStatus),
                          }}
                        />
                      </Icon>
                    </IconButton>
                  }
                >
                  <ListItemButton>
                    <Box
                      display={'flex'}
                      sx={{
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <ListItemIcon
                        sx={{ minWidth: 'auto', marginRight: '8px' }}
                      >
                        {renderThumbnail(file, showProgressBar, listType)}
                      </ListItemIcon>
                      <ListItemText sx={{ flex: '0 0 auto' }}>
                        <Typography
                          variant="body2"
                          noWrap={true}
                          display="block"
                          width={getWidth(
                            file.uploadStatus,
                            showProgressBar,
                            listType,
                          )}
                          sx={{
                            color: isRejected(file.uploadStatus),
                          }}
                        >
                          {file.fileData?.name}
                        </Typography>
                      </ListItemText>
                      {showProgressBar && file.uploadStatus === 'pending' && (
                        <Box
                          sx={{
                            width: '100%',
                            marginLeft: '8px',
                            marginRight: '28px',
                          }}
                        >
                          <LinearProgress
                            color="primary"
                            value={0}
                            valueBuffer={0}
                            variant="query"
                          />
                        </Box>
                      )}
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Popper>
        </Box>
      </Badge>
    </ClickAwayListener>
  );
};
export default FileUpload;
