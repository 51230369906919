import { forwardRef, Ref } from 'react';

import {
  alpha,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';

import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { styled } from '@mui/material/styles';

import {
  activeShadowAlpha,
  baseMargin,
  basePadding,
  baseShadowVariant,
  baseTransitionDuration,
  ShadowRadiusMap,
  ThumbSizeMap,
  TrackHeightMap,
  TrackWidthMap,
} from '../../utils/switch.utils';

export interface SwitchProps extends MuiSwitchProps {
  /**
   *
   * A ref that points to the TouchRipple element.
   */
  touchRippleRef?: Ref<TouchRippleActions>;
}

// overriding MUI switch style to match FDS switch style
const StyledMuiSwitch = styled(MuiSwitch)<MuiSwitchProps>(({ theme }) => ({
  '&.MuiSwitch-root': {
    '& .MuiSwitch-switchBase': {
      '& + .MuiSwitch-track': {
        borderRadius: 10,
        opacity: 0.38,
      },

      '& .MuiSwitch-thumb, & > svg': {
        borderRadius: '100%',
        boxShadow: theme.shadows[baseShadowVariant],
        boxSizing: 'border-box',
      },

      '& .MuiSwitch-thumb, & > svg, & + .MuiSwitch-track': {
        transition: theme.transitions.create(['background-color', 'color'], {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeInOut,
        }),
      },

      '& > svg': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.action.disabled,
      },

      '&.Mui-checked:not(.Mui-disabled)': {
        '&.MuiSwitch-colorDefault': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.main,
          },

          '& > svg': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },

          color: theme.palette.primary.main,
        },

        '&.MuiSwitch-colorError': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.error.main,
          },

          '& > svg': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
          },

          color: theme.palette.error.main,
        },

        '&.MuiSwitch-colorInfo': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.info.main,
          },

          '& > svg': {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText,
          },

          color: theme.palette.info.main,
        },

        '&.MuiSwitch-colorPrimary': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.main,
          },

          '& > svg': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },

          color: theme.palette.primary.main,
        },

        '&.MuiSwitch-colorSecondary': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.secondary.main,
          },

          '& > svg': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          },

          color: theme.palette.secondary.main,
        },

        '&.MuiSwitch-colorSuccess': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.success.main,
          },

          '& > svg': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
          },

          color: theme.palette.success.main,
        },

        '&.MuiSwitch-colorWarning': {
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.warning.main,
          },

          '& > svg': {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
          },

          color: theme.palette.warning.main,
        },
      },

      '&.Mui-disabled': {
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.action.disabledBackground,
          opacity: 1,
        },

        '& .MuiSwitch-thumb': {
          color: theme.palette.background.default,
        },

        '& > svg': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.action.disabled,
        },
      },

      margin: baseMargin,
      padding: basePadding,
      transitionDuration: baseTransitionDuration,
    },
    overflow: 'visible',
    padding: basePadding,
  },

  '&.MuiSwitch-sizeMedium': {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '&:hover, &.Mui-focusVisible': {
          '&.MuiSwitch-colorDefault': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.medium} ${alpha(
                theme.palette.primary.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorError': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.medium} ${alpha(
                theme.palette.error.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorInfo': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.medium} ${alpha(
                theme.palette.info.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorPrimary': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.medium} ${alpha(
                theme.palette.primary.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorSecondary': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.medium} ${alpha(
                theme.palette.secondary.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorSuccess': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.medium} ${alpha(
                theme.palette.success.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorWarning': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.medium} ${alpha(
                theme.palette.warning.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },
        },

        transform: 'translateX(18px)',
      },

      '&:hover .MuiSwitch-thumb, &.Mui-focusVisible .MuiSwitch-thumb, &:hover > svg, &.Mui-focusVisible > svg':
        {
          boxShadow: `
                0 0 0 ${ShadowRadiusMap.medium} ${theme.palette.action.hover},
                ${theme.shadows[baseShadowVariant]}
              `,
        },
    },

    '& .MuiSwitch-thumb, & svg': {
      height: ThumbSizeMap.medium,
      width: ThumbSizeMap.medium,
    },

    height: TrackHeightMap.medium,
    width: TrackWidthMap.medium,
  },

  '&.MuiSwitch-sizeSmall': {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '&:hover, &.Mui-focusVisible': {
          '&.MuiSwitch-colorDefault': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.small} ${alpha(
                theme.palette.primary.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorError': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.small} ${alpha(
                theme.palette.error.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorInfo': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.small} ${alpha(
                theme.palette.info.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorPrimary': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.small} ${alpha(
                theme.palette.primary.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorSecondary': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.small} ${alpha(
                theme.palette.secondary.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorSuccess': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.small} ${alpha(
                theme.palette.success.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },

          '&.MuiSwitch-colorWarning': {
            '& .MuiSwitch-thumb, & > svg': {
              boxShadow: `
                0 0 0 ${ShadowRadiusMap.small} ${alpha(
                theme.palette.warning.main,
                activeShadowAlpha,
              )},
                ${theme.shadows[baseShadowVariant]}
              `,
            },
          },
        },

        transform: 'translateX(16px)',
      },

      '&:hover .MuiSwitch-thumb, &.Mui-focusVisible .MuiSwitch-thumb, &:hover > svg, &.Mui-focusVisible > svg':
        {
          boxShadow: `
                0 0 0 ${ShadowRadiusMap.small} ${theme.palette.action.hover},
                ${theme.shadows[baseShadowVariant]}
              `,
        },
    },

    '& .MuiSwitch-thumb, & svg': {
      height: ThumbSizeMap.small,
      width: ThumbSizeMap.small,
    },

    height: TrackHeightMap.small,
    width: TrackWidthMap.small,
  },
}));

/** Switches toggle the state of a single setting on or off.
 *
 * `import { Switch } from '@fsp-io/shared-ui'`
 */

const Switch = forwardRef<HTMLButtonElement, SwitchProps>((props, ref) => {
  return <StyledMuiSwitch {...props} ref={ref} />;
});

Switch.displayName = 'Switch';

export default Switch;
