import { forwardRef } from 'react'
import {
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
} from '@mui/material'

export interface ListItemTextProps extends MuiListItemTextProps {
  isEllipsis?: boolean
}

/** ListItemText
 *
 * `import { ListItemText } from '@fsp-io/shared-ui'`
 */

const ListItemText = forwardRef<HTMLSpanElement, ListItemTextProps>(
  ({ isEllipsis, sx, ...props }, ref) => {
    return (
      <MuiListItemText
        {...props}
        ref={ref}
        sx={{
          ...(sx || {}),
          ...(isEllipsis ? { textOverflow: 'ellipsis' } : {}),
        }}
      />
    )
  }
)

ListItemText.displayName = 'ListItemText'

export default ListItemText
