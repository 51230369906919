import { iot, mqtt5 } from 'aws-iot-device-sdk-v2'

import { AWSCognitoCredentialsProvider } from './AWSCognitoCredentialsProvider'

import { awsConfiguration } from '.'

export const createClient = (
  provider: AWSCognitoCredentialsProvider
): mqtt5.Mqtt5Client => {
  const wsConfig: iot.WebsocketSigv4Config = {
    credentialsProvider: provider,
    region: awsConfiguration.awsRegion,
  }
  // console.log('wsConfig -->', wsConfig)

  const builder: iot.AwsIotMqtt5ClientConfigBuilder =
    iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
      awsConfiguration.awsIoTEndpoint,
      wsConfig
    )
  // console.log('builder -->', builder)

  const client: mqtt5.Mqtt5Client = new mqtt5.Mqtt5Client(builder.build())
  // console.log('client-->', client)

  client.on('error', (error) => {
    console.log('Error event: ' + error.toString())
  })

  client.on('attemptingConnect', (eventData: mqtt5.AttemptingConnectEvent) => {
    console.log('Attempting Connect event')
  })

  client.on('connectionSuccess', (eventData: mqtt5.ConnectionSuccessEvent) => {
    console.log('Connection Success event')
    // console.log('Connack: ' + JSON.stringify(eventData.connack))
    // console.log('Settings: ' + JSON.stringify(eventData.settings))
  })

  client.on('connectionFailure', (eventData: mqtt5.ConnectionFailureEvent) => {
    console.log('Connection failure event: ' + eventData.error.toString())
  })

  client.on('disconnection', (eventData: mqtt5.DisconnectionEvent) => {
    // console.log('Disconnection event: ' + eventData.error.toString())
    if (eventData.disconnect !== undefined) {
      console.log('Disconnect packet: ' + JSON.stringify(eventData.disconnect))
    }
  })

  client.on('stopped', (eventData: mqtt5.StoppedEvent) => {
    // console.log('Stopped event')
  })

  return client
}

export const onMessage = (
  client: mqtt5.Mqtt5Client,
  callback: (data: mqtt5.PublishPacket) => void
) => {
  client.on(
    'messageReceived',
    (eventData: mqtt5.MessageReceivedEvent): void => {
      // console.log(
      //   'Message Received event: ',
      //   Date.now(),
      //   JSON.stringify(eventData.message)
      // )
      callback(eventData.message)
    }
  )
}
