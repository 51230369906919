import { ChangeEvent, forwardRef, useState } from 'react'

import { InputProps } from '@mui/material/Input'
import MuiTextField, {
  StandardTextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField'
import { SystemStyleObject } from '@mui/system'
import clsx from 'clsx'
import { InputMaxHeightMap } from '../../utils/input.utils'
import { getSxStyles } from '../../utils/styles.utils'

export type TextFieldInputProps = Omit<InputProps, 'disableUnderline'>

export type TextFieldSize = 'xsmall' | 'small' | 'medium'

export interface TextFieldProps
  extends Omit<MuiTextFieldProps, 'variant' | 'size' | 'margin' | 'classes'> {
  /** Set to true to remove background color. */
  hideBackgroundColor?: boolean

  /** Input Node for Child Input. */
  inputSetting?: TextFieldInputProps

  /** Set to true to show a border around the TextField.*/
  showBorder?: boolean

  /** Set to true to show an underline on the Input.  */
  showUnderline?: boolean

  /** The size of the component. */
  size?: TextFieldSize

  /** Toggles the counter visibility. Setting to `true` will display the counter and respect the characterMax if set. `false` will hide the counter and ignore `characterMax`.
   * @default false
   */
  characterCounter?: boolean

  /** Sets a counter character limit while `characterCounter` is enabled. */
  characterMax?: number

  /** Gray out input when disabled */
  grayOutWhenDisabled?: boolean

  /** Bigger and bold labels */
  boldLabels?: boolean
}

/** TextField
 *
 * `import TextField from '@fsp-io/TextField'`
 */

const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      hideBackgroundColor = false,
      inputSetting = {},
      InputProps = {},
      label = null,
      showBorder = true,
      showUnderline = false,
      size = 'medium',
      type = 'text',
      sx = {},
      hiddenLabel,
      InputLabelProps = {},
      FormHelperTextProps = {},
      helperText,
      characterCounter,
      characterMax,
      defaultValue,
      onChange,
      grayOutWhenDisabled = false,
      boldLabels = false,
      ...rest
    },
    ref
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const [value, setValue] = useState<string | number>(
      defaultValue as string | number
    )

    const handleChange = (event: ChangeEvent) => {
      const targetValue = (event.target as HTMLInputElement).value
      if (
        characterCounter &&
        characterMax &&
        targetValue?.length > characterMax
      ) {
        return
      }
      if (onChange) {
        onChange(event as ChangeEvent<HTMLInputElement>)
      }
      setValue(targetValue)
    }

    const joinInputLabelProps = {
      sx: {
        ...(showBorder ? { paddingLeft: '2px' } : { paddingLeft: '0px' }),
        ...(boldLabels
          ? {
              fontSize: '1.2rem',
              fontWeight: 'bold',
            }
          : {}),
        ...InputLabelProps.sx,
      },
      ...InputLabelProps,
      shrink: true,
    }

    const joinFormHelperTextProps = {
      sx: {
        m: '0px',
        ...(showBorder ? { pl: '14px' } : { pl: '12px' }),
        ...FormHelperTextProps.sx,
      },
      ...FormHelperTextProps,
    }

    const input = {
      ...InputProps,
      ...inputSetting,
      disableUnderline: !showUnderline,
      inputProps: {
        min: 0,
      },
    }
    const inputSx = input.sx

    input.sx = (theme) =>
      ({
        ...getSxStyles(theme, inputSx),
        // Conditionally apply properties
        ...(!rest.multiline &&
          size !== 'medium' && { maxHeight: InputMaxHeightMap[size] }),
        ...(showBorder
          ? { border: '1px solid #D0D5DD' }
          : { border: '0px !important' }),
        ...{ borderRadius: '6px', padding: '9px 12px' },
        ...(hideBackgroundColor
          ? { backgroundColor: 'inherit !important' }
          : { backgroundColor: '#F9FAFB' }),
        ...(rest.disabled === true && grayOutWhenDisabled === true
          ? { backgroundColor: theme.palette.grey?.['200'] }
          : {}),
      } as SystemStyleObject)

    input.className = clsx({ sizeXSmall: size === 'xsmall' }, input.className)

    // remove the label if the size is xsmall
    const _label = size === 'xsmall' ? null : label
    const _hiddenLabel = hiddenLabel ?? !_label

    const getHelperText = () => {
      return (
        <>
          {helperText}
          {characterCounter && (
            <span style={{ float: 'right', padding: '0px 14px' }}>
              {value ? value?.toString().length : '0'}
              {!!characterMax && `/${characterMax}`}
            </span>
          )}
        </>
      )
    }

    return (
      <MuiTextField
        variant="standard"
        /* MUI doesn’t support the xsmall size, but Fleet does.
         * So just pass small down to make MUI happy.
         * We use CSS to apply the xsmall styling separately.
         */
        size={size === 'xsmall' ? 'small' : size}
        type={type}
        margin={size !== 'medium' ? 'none' : 'dense'}
        color={rest.error ? 'error' : rest.color}
        hiddenLabel={_hiddenLabel}
        InputProps={input}
        InputLabelProps={joinInputLabelProps}
        FormHelperTextProps={joinFormHelperTextProps}
        helperText={getHelperText()}
        onChange={handleChange}
        value={value}
        sx={(theme) =>
          ({
            ...getSxStyles(theme, sx),
            overflow: 'hidden',
            ...(boldLabels
              ? {
                  '.MuiInputBase-root': {
                    marginTop: '1.4rem',
                  },
                }
              : {}),
          } as SystemStyleObject)
        }
        label={_label}
        {...rest}
        ref={ref}
      />
    )
  }
)

TextField.displayName = 'TextField'

export default TextField
