export interface FilterValues {
  businessUnit: string
  type: string
  siteName: string
  from: Date | null
  to: Date | null
}

export enum NotificationType {
  DISABLED = 'Disabled',
  WIFI = 'Wifi',
  ALARM = 'Alarm',
}

export enum NotificationStatus {
  READ = 'Read',
  NEW = 'New',
}

export enum Properties {
  SITE_NAME = 'siteName',
}
