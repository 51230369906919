import React, { forwardRef } from 'react';
import { Box, Card as MuiCard } from '@mui/material';
import { styled } from '@mui/system';
import StatusIndicator from '../status-indicator';
import { Orientation, Position } from '../../types/common';
import { CardProps } from './types';
import styles from './styles';

const CardContainerStyled = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) =>
    !Array<PropertyKey>('statusPosition', 'status').includes(propName),
})(styles.container);

/** Card
 *
 * Cards contain content and actions about a single subject.
 *
 * `import { Card } from '@fsp-io/shared-ui'`
 */

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ status, statusPosition = Position.Left, ...rest }, ref) => {
    return (
      <CardContainerStyled statusPosition={statusPosition} status={status}>
        {!!status && (
          <StatusIndicator 
            sx={{ ...styles.statusIndicator }}
            type={status}
            orientation={
              statusPosition === Position.Bottom ||
              statusPosition === Position.Top
                ? Orientation.Horizontal
                : Orientation.Vertical
            }
          />
        )}
        <MuiCard {...rest} ref={ref} className="FdsCard" />
      </CardContainerStyled>
    );
  },
);

Card.displayName = 'Card';

export default Card;
