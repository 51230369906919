import LogoAsset from '@/assets/svg/ifps-logo.svg'
import { LOGO_URI } from '@/constants/const'
import { Logo } from '@industrial-plus/shared-ui'
import React from 'react'

interface Props {
  height?: string
  width?: string
  marginBottom?: string
  marginTop?: string
  useLogoLink?: boolean
}

const IFPSLogo: React.FC<Props> = (props: Props) => {
  const { height, width, marginBottom, marginTop, useLogoLink } = props

  return (
    <Logo
      alt="logo"
      href={useLogoLink ? LOGO_URI : undefined}
      target="_blank"
      src={LogoAsset}
      sx={{
        height,
        width,
        marginBottom,
        marginTop,
      }}
    />
  )
}

IFPSLogo.defaultProps = {
  height: '5rem',
  width: '5rem',
  useLogoLink: false,
}

export default React.memo(IFPSLogo)
