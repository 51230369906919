import CountryCode from './CountryFlag';
import { CountryFlagProps } from './types';

/**
 * The CountryFlag component allows display country flag by country code or country name
 *
 * `import CountryFlag from '@fsp-io/CountryFlag'`
 */

export default function CountryFlag(props: CountryFlagProps) {
  const { countryCode, countryName = '' } = props;

  if (!countryCode && !countryName) {
    return null;
  }

  return <CountryCode {...props} />;
}
