import { forwardRef } from 'react';

import MuiTableSortLabel, {
  TableSortLabelProps as MuiTableSortLabelProps,
} from '@mui/material/TableSortLabel';

export interface TableSortLabelProps extends MuiTableSortLabelProps {}

/** TableSortLabel
 *
 * `import { TableSortLabel } from '@fsp-io/shared-ui'`
 */

const TableSortLabel = forwardRef<HTMLSpanElement, MuiTableSortLabelProps>(
  (
    { active = false, direction = 'asc', hideSortIcon = false, ...rest },
    ref,
  ) => {
    return (
      <MuiTableSortLabel
        active={active}
        direction={direction}
        hideSortIcon={hideSortIcon}
        {...rest}
        ref={ref}
      />
    );
  },
);

TableSortLabel.displayName = 'TableSortLabel';

export default TableSortLabel;
