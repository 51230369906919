import React from 'react'
import { Box } from '@industrial-plus/shared-ui'
import { useTheme } from '@mui/material/styles'
import { Routes, Route, Navigate } from 'react-router-dom'
import UserProfileTabs from './UserProfileTabs'
import { LazyLoadedUserProfile, LazyLoadedUserNotifications } from '@/app/routes/LazyLoadedRoutes'
import { ROUTE_CONSTANTS } from '@/constants/paths'

const UserProfileTabPaths: React.FC = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <UserProfileTabs />
      </Box>

      <Routes>
        <Route
          path={ROUTE_CONSTANTS.profile}
          element={<LazyLoadedUserProfile />}
        />
        <Route
          path={ROUTE_CONSTANTS.notifications}
          element={<LazyLoadedUserNotifications />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_CONSTANTS.profile} replace />}
        />
      </Routes>
    </Box>
  )
}

export default React.memo(UserProfileTabPaths)
