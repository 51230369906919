import { forwardRef } from 'react';

import MuiTableContainer, {
  TableContainerProps as MuiTableContainerProps,
} from '@mui/material/TableContainer';

export interface TableContainerProps extends MuiTableContainerProps {}

/** TableContainer
 *
 * `import { TableContainer } from '@fsp-io/shared-ui'`
 */

const TableContainer = forwardRef<HTMLDivElement, TableContainerProps>(
  (props, ref) => {
    return <MuiTableContainer {...props} ref={ref} />;
  },
);

TableContainer.displayName = 'TableContainer';

export default TableContainer;
