import { forwardRef, Ref } from 'react';

import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';

export interface TabsProps extends MuiTabsProps {
  /**
   *
   * A ref that points to the TouchRipple element.
   */
  touchRippleRef?: Ref<TouchRippleActions>;
  /**
   *
   * Invert the indicator on the tab. Default set to bottom for "horizontal" orientation and right for "vertical" orientation.
   */
  invertIndicator?: boolean;

  /**
   * indicator variant
   */
  indicatorVariant?: 'standard' | 'line';

  /**
   * indicator width
   */
  indicatorWidth?: 'content' | 'full';
}

const indicatorInvertStyle = {
  bottom: 'unset',
  top: 0,
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
};

/**
 * Tabs organize and allow navigation between groups of content that are related and at the same level of hierarchy.
 *
 * `import { Tabs } from '@fsp-io/shared-ui'`
 *
 * `import { Tab } from '@fsp-io/shared-ui'`
 *
 * `import { TabContext } from '@fsp-io/shared-ui'`
 *
 * `import { TabList } from '@fsp-io/shared-ui'`
 *
 * `import { TabPanel } from '@fsp-io/shared-ui'`
 *
 * `import { TabScrollButton } from '@fsp-io/shared-ui'`
 */
const Tabs = forwardRef<HTMLButtonElement, TabsProps>(
  (
    {
      invertIndicator,
      indicatorVariant = 'standard',
      indicatorWidth = 'content',
      ...props
    },
    ref,
  ) => {
    const rootClassNames = `${
      indicatorWidth === 'full'
        ? 'MuiTab-indicator__full'
        : 'MuiTab-indicator__content'
    }`;
    const indicatorClassNames = `${
      indicatorVariant === 'line'
        ? 'MuiTab-indicator__line'
        : 'MuiTab-indicator__standard'
    }`;
    return (
      <MuiTabs
        {...props}
        ref={ref}
        className={[rootClassNames, indicatorClassNames].join(' ')}
        TabIndicatorProps={{
          ...(invertIndicator ? { style: indicatorInvertStyle } : undefined),
          ...props.TabIndicatorProps,
        }}
      />
    );
  },
);

Tabs.displayName = 'Tabs';

export default Tabs;
