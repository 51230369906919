import MuiTabContext, {
  TabContextProps as MuiTabContextProps,
} from '@mui/lab/TabContext';

export type TabContextProps = MuiTabContextProps;

/** TabContext
 *
 * `import { TabContext } from '@fsp-io/shared-ui'`
 */
export default function TabContext(props: TabContextProps) {
  return <MuiTabContext {...props} />;
}
