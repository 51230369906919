import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box } from '@mui/material'
import { OptionContainer } from './OptionsMenuStyled'

export interface Option {
  label: string
  icon?: JSX.Element
  onClick: () => void
}

const OptionsMenu: React.FC<{ options: Option[]; disabled?: boolean }> = ({
  options,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton
        aria-label="options"
        aria-controls="options-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
        data-testid="table-options-icon"
        sx={{
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            data-testid={`table-options-menu-${option.label
              .toLowerCase()
              .replace(' ', '-')}`}
            onClick={() => {
              handleClose()
              option.onClick()
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {option.icon && <OptionContainer>{option.icon}</OptionContainer>}
            <Box sx={{ width: '0.6rem' }} />
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
export default OptionsMenu
