import React, { Suspense } from 'react'

import { Route, Routes } from 'react-router-dom'

import FullPageLoader from '@/app/components/full-page-loader'
import Layout from '@/app/components/layout/Layout'
import {
  LazyLoadedPageNotFound,
  LazyLoadedSettingsPage,
} from '@/app/routes/LazyLoadedRoutes'

const SettingsRoutes: React.FC = () => {
  return (
    <Layout>
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route index element={<LazyLoadedSettingsPage />} />
          <Route path="*" index element={<LazyLoadedPageNotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  )
}

export default React.memo(SettingsRoutes)
