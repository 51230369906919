import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { fetchToken } from 'firebase'

import { useSnackbar } from '@/providers/snackbar/SnackbarProvider'

import { useFCMTokenProviderContext } from './FCMTokenProvider'
import { NotificationPermissionTypes } from './types'

const NotificationPermission: React.FC = () => {
  const { fcmToken, setFcmToken } = useFCMTokenProviderContext()
  const { showSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    if (!fcmToken) {
      const Notification = window.Notification
      let wasQuestioned = false

      const requestNotificationPermissions = async () => {
        if (Notification) {
          if (Notification.permission === NotificationPermissionTypes.default) {
            wasQuestioned = true
          }

          const permission = await Notification.requestPermission()

          if (permission === NotificationPermissionTypes.granted) {
            fetchToken(setFcmToken)
          } else if (permission === NotificationPermissionTypes.denied) {
            console.log(t('common.notifications.permissionUnabled'))
          }

          if (wasQuestioned) {
            //  console.log('User was asked. New permission is: ' + permission)
          }

          // Handle Permission changes
          navigator.permissions
            .query({ name: 'notifications' })
            .then((permissionStatus) => {
              permissionStatus.onchange = () => {
                const isLocalFCMTokenFound = localStorage.getItem('fcmToken')

                if (
                  permissionStatus.state ===
                    NotificationPermissionTypes.granted &&
                  !fcmToken &&
                  !isLocalFCMTokenFound
                ) {
                  fetchToken(setFcmToken)
                }
              }
            })
            .catch((error) => {
              showSnackbar(t('common.notifications.error') + error, 'error')
            })
        }
      }

      requestNotificationPermissions()
    }
  }, [fcmToken, setFcmToken, showSnackbar, t])

  return null
}

export default React.memo(NotificationPermission)
