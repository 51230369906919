import { forwardRef } from 'react';

import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { AllSystemCSSProperties, ResponsiveStyleValue } from '@mui/system';

export interface LinkProps extends MuiLinkProps {
  /**
   * Specifies how to capitalize an element's text
   */
  textTransform?: ResponsiveStyleValue<AllSystemCSSProperties['textTransform']>;
  /**
   * The target attribute specifies where to open the linked document:
   */
  target?: string;
}

/** The Link component allows you to easily customize anchor elements with your theme colors and typography styles.
 *
 * `import { Link } from '@fsp-io/shared-ui'`
 */

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  return <MuiLink {...props} ref={ref} />;
});

Link.displayName = 'Link';

export default Link;
