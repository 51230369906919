import Box from '../box';
import { SignInProps } from './types';
import { styled } from '@mui/material/styles';
import styles from './styles';
import React, { FC, useMemo } from 'react';
import clsx from 'clsx';

const Container = styled(Box)(styles.container);
const Body = styled(Box)(styles.body);
const Footer = styled(Box)(styles.footer);

const baseClassName = 'sign-in';

/** This pattern is to add a full UI sign-in to a FE app.
 *
 * ***Imports***
 *
 * ```import React, { useState, useCallback } from "react";```
 * ```import { Box } from "@fsp-io/shared-ui";             ```
 * ```import { Copyright } from "@fsp-io/shared-ui";```
 * ```import { ForgotPassword } from "@fsp-io/shared-ui";```
 * ```import { Logo } from "@fsp-io/shared-ui";```
 * ```import { TermsOfService } from "@fsp-io/shared-ui";```
 * ```import { Typography } from "@fsp-io/shared-ui";```
 * ```import { SignIn } from "@fsp-io/shared-ui";```
 * ```import { SignInFooter } from "@fsp-io/shared-ui";```
 * ```import { SingInFormErrors, SignInForm } from "@fsp-io/shared-ui";```
 * ```import { isEmailValid } from "@fsp-io/shared-ui";```
 */

const SignIn: FC<SignInProps> = ({
  children,
  footer,
  className,
  sx,
  ...rest
}) => {
  const classNames = useMemo(
    () => ({
      component: clsx(baseClassName, className),
      body: `${baseClassName}__body`,
      footer: `${baseClassName}__footer`,
    }),
    [className],
  );

  return (
    <Container sx={sx} className={classNames.component} {...rest}>
      <Body className={classNames.body}>{children}</Body>
      {footer && <Footer className={classNames.footer}>{footer}</Footer>}
    </Container>
  );
};

export default SignIn;
