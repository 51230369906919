import {
  CssBaseline as MuiCssBaseline,
  CssBaselineProps as MuiCssBaselineProps,
} from '@mui/material';

export type CssBaselineProps = MuiCssBaselineProps;

/** CssBaseline
 *
 * This component can be used to kickstart an elegant, consistent, and simple baseline to build upon.
 *
 * `import { CssBaseline } from '@fsp-io/shared-ui'`
 */
export default function CssBaseline(props: CssBaselineProps): JSX.Element {
  return <MuiCssBaseline {...props} />;
}
