import { ThemedStyledComponent } from "./types";

export default {
  action: {
    cursor: "pointer",
  },
  dialog: ({ theme }: ThemedStyledComponent) =>
    ({
      "& .MuiDialogContent-root": {
        padding: theme?.spacing(2),
      },
      "& .MuiDialogActions-root": {
        padding: theme?.spacing(1),
      },
    } as const),
  dialogActions: {
    justifyContent: "space-between",
  },
  dialogContent: {
    padding: "0px !important",
  },
};
