import React, { useContext } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { TITLES } from '@/constants/titles'
import BreadcrumbsContext, {
  CustomBreadcrumbsData,
} from '@/providers/BreadcrumbsProvider'
import {
  Breadcrumbs,
  LinkProps,
  Skeleton,
  Typography,
} from '@industrial-plus/shared-ui'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Link, useTheme } from '@mui/material'

interface LinkRouterProps extends LinkProps {
  to: string
  replace?: boolean
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink} />
)

const ShowLoader: React.FC = () => {
  return (
    <Skeleton
      animation="wave"
      role="progressbar"
      aria-busy="true"
      width={100}
    />
  )
}

interface KeyProps {
  title: string
}

const LastTypography: React.FC<KeyProps> = (props: KeyProps) => {
  const theme = useTheme()
  const { title } = props

  return (
    <Typography key={title} color={theme.colors.gray900}>
      {title}
    </Typography>
  )
}

interface TopBarBreadCrumbsProps {
  maxItems?: number
}

export const TopBarBreadCrumbs = ({
  maxItems,
}: TopBarBreadCrumbsProps): JSX.Element => {
  const { loading, customTitles } = useContext(BreadcrumbsContext)

  const location = useLocation()
  const theme = useTheme()

  const pathnames = location.pathname
    ? location.pathname.split('/').filter((path: string) => path)
    : ['/']

  const handleCustomTitlesPresentUI = (
    item: CustomBreadcrumbsData,
    index: number
  ) => {
    const isLast = index === customTitles.length - 1
    const linkNotPresent = customTitles.length > 0 && !item.to

    if (loading) {
      return <ShowLoader key={item.title} />
    } else if (isLast || linkNotPresent) {
      return <LastTypography key={item.title} title={item.title} />
    } else {
      return (
        <LinkRouter
          to={item.to ? item.to : ''}
          key={item.to}
          color={theme.colors.gray300}
          underline="hover"
          data-testid={`breadcrumbs-${item.title
            .toLowerCase()
            .replace(' ', '-')}`}
        >
          {item.title}
        </LinkRouter>
      )
    }
  }

  const handleCustomTitleAbsentUI = (path: string, index: number) => {
    const isLast = index === pathnames.length - 1
    const to = `/${pathnames.slice(0, index + 1).join('/')}`

    if (loading) {
      return <ShowLoader key={path} />
    } else if (isLast) {
      return <LastTypography key={TITLES[to]} title={TITLES[to]} />
    } else {
      return (
        <LinkRouter
          to={to}
          key={path}
          color={theme.colors.gray300}
          underline="hover"
        >
          {TITLES[`/${path}`] ? TITLES[`/${path}`] : `${path}`}
        </LinkRouter>
      )
    }
  }

  return (
    <Breadcrumbs
      maxItems={maxItems}
      separator={<ArrowForwardIosIcon fontSize="small" />}
      aria-label="breadcrumbs"
      sx={{ flexGrow: 1 }}
    >
      {customTitles?.length &&
        customTitles.map((item: CustomBreadcrumbsData, index: number) => {
          return handleCustomTitlesPresentUI(item, index)
        })}
      {(!customTitles || customTitles.length === 0) &&
        pathnames.map((path: string, index: number) => {
          return handleCustomTitleAbsentUI(path, index)
        })}
    </Breadcrumbs>
  )
}
