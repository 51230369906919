import { ReactNode } from 'react';
import { Theme, ThemeProvider, createTheme } from '@mui/material/styles';
import { fleetThemeOptions } from './fleetThemeOptions';

export type ThemeProviderProps = {
  /** Content to render with the specified theme */
  children?: ReactNode;

  /** Pass an optional custom theme that extends Fleet's theme, which is the default */
  theme?: Theme;
};

const fleetTheme = createTheme(fleetThemeOptions);

export default function FleetThemeProvider({
  children,
  theme = fleetTheme,
}: ThemeProviderProps): JSX.Element {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
