import { forwardRef } from 'react';

import {
  Backdrop as MuiBackdrop,
  BackdropProps as MuiBackdropProps,
} from '@mui/material';

export type BackdropProps = MuiBackdropProps

/** The backdrop component is used to provide emphasis on a particular element or parts of it.
 *
 * `import { Backdrop } from '@fsp-io/shared-ui'`
 */

const Backdrop = forwardRef<unknown, BackdropProps>((props, ref) => {
  return <MuiBackdrop {...props} ref={ref} />;
});

Backdrop.displayName = 'Backdrop';

export default Backdrop;
