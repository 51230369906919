import React from 'react';
import { styled } from '@mui/system';

import Button from '../../button';
import Link from '../../link';
import { DownloadButtonProps } from './types';

import styles from './styles';

const LinkStyled = styled(Link)(styles.downloadLink);
const ButtonStyled = styled(Button)(styles.downloadButton);

export default function DownloadButton({ label, href }: DownloadButtonProps) {
  return (
    <ButtonStyled variant="outlined">
      <LinkStyled href={href} target="_blank" rel="noopener" underline="none">
        {label}
      </LinkStyled>
    </ButtonStyled>
  );
}
