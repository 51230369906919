import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDistanceToNow } from 'date-fns'
import { useTheme } from '@mui/material'
import {
  ExpandMoreOutlined,
  ExpandLessOutlined,
  Wifi,
  NotificationsNoneOutlined,
} from '@mui/icons-material'
import { Box, Typography, IconButton } from '@industrial-plus/shared-ui'
import {
  BusinessUnit,
  PushNotification,
  PropertiesMap,
  useDismissNotificationMutation,
  useReadNotificationMutation,
} from '@/__generated__/graphql'
import {
  EventTileContainer,
  EventTileExpandedContainer,
  EventTileFooterContainer,
  EventTileHeaderContainer,
  EventTileStatusContainer,
  EventTileTitleContainer,
  EventTileTitleText,
  EventTileDescription,
  StatusIconContainer,
  TimestampContainer,
  StyledDescriptionBox,
} from './NotificationPopupStyled'
import EventTileDetails from './EventTileDetails'
import AutronicaLogo from '@/assets/png/autronica_logo.png'
import MarioffLogo from '@/assets/png/marioff_logo.png'
import { NotificationType, NotificationStatus } from './NotificationInterfaces'

export interface EventTileProps {
  notificationData: PushNotification
}

const EventTile: React.FC<EventTileProps> = ({ notificationData }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isDismissed, setIsDismissed] = useState(false)

  const [dismissNotification] = useDismissNotificationMutation({
    variables: {
      id: notificationData.id,
    },
    context: {
      clientName: 'notifications',
    },
    refetchQueries: ['PushNotifications'],
    awaitRefetchQueries: true,
  })

  const [readNotification] = useReadNotificationMutation({
    variables: {
      id: notificationData.id,
    },
    context: {
      clientName: 'notifications',
    },
    refetchQueries: ['PushNotifications'],
    awaitRefetchQueries: true,
  })

  const handleDismiss = () => {
    setIsDismissed(true)
    dismissNotification()
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
    readNotification()
  }

  const getStatusIcon = (type: NotificationType) => {
    switch (type) {
      case NotificationType.DISABLED:
        return t('common.disabledShortcut')
      case NotificationType.WIFI:
        return <Wifi sx={{ height: '1.2rem' }} />
      case NotificationType.ALARM:
        return <NotificationsNoneOutlined sx={{ height: '1.2rem' }} />
      default:
        return <NotificationsNoneOutlined sx={{ height: '1.2rem' }} />
    }
  }

  return (
    <EventTileContainer
      isExpanded={isExpanded}
      isNew={notificationData.status === NotificationStatus.NEW}
    >
      <EventTileHeaderContainer>
        <EventTileTitleContainer>
          {notificationData.businessUnit === BusinessUnit.Autronica ? (
            <img
              src={AutronicaLogo}
              alt={`${t('common.businessUnit.autronica')} ${t('common.logo')}`}
              style={{ height: '1.5rem' }}
            />
          ) : (
            <img
              src={MarioffLogo}
              alt={`${t('common.businessUnit.marioff')} ${t('common.logo')}`}
              style={{ height: '1.5rem' }}
            />
          )}
          <EventTileTitleText
            variant={
              notificationData.status === NotificationStatus.NEW
                ? 'subtitle1'
                : 'body2'
            }
          >
            {notificationData.title}
          </EventTileTitleText>
        </EventTileTitleContainer>

        <EventTileStatusContainer>
          {notificationData.status === NotificationStatus.NEW && (
            <Typography
              variant="body2"
              sx={{
                height: '0.75rem',
                color: theme.colors.primary600,
                marginRight: '5rem',
              }}
            >
              {t('common.new')}
            </Typography>
          )}

          <StatusIconContainer type={notificationData.type}>
            {getStatusIcon(notificationData.type as NotificationType)}
          </StatusIconContainer>
        </EventTileStatusContainer>
      </EventTileHeaderContainer>
      <Box sx={{ marginLeft: '3rem' }}>
        <StyledDescriptionBox>
          <EventTileDescription>
            {notificationData.description}
          </EventTileDescription>
          <IconButton
            onClick={toggleExpand}
            sx={{ width: '2rem', height: '2rem', marginTop: '-2rem' }}
          >
            {isExpanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
          </IconButton>
        </StyledDescriptionBox>
        <EventTileExpandedContainer isExpanded={isExpanded}>
          {isExpanded && (
            <EventTileDetails
              notificationDetails={
                notificationData.propertiesMap as PropertiesMap[]
              }
            />
          )}
        </EventTileExpandedContainer>
        <EventTileFooterContainer>
          <Box display="flex" justifyContent="flex-end">
            {!isDismissed && (
              <Typography
                variant="body2"
                color={theme.colors.primary500}
                onClick={handleDismiss}
                style={{ cursor: 'pointer' }}
              >
                {t('common.dismiss')}
              </Typography>
            )}
          </Box>
          <TimestampContainer>
            <Typography variant="body2" color={theme.colors.primary300}>
              {formatDistanceToNow(new Date(notificationData.createdAt), {
                addSuffix: true,
              })}
            </Typography>
          </TimestampContainer>
        </EventTileFooterContainer>
      </Box>
    </EventTileContainer>
  )
}

export default EventTile
