import { Box, Typography, InputLabel } from '@industrial-plus/shared-ui'
import { styled, Badge } from '@mui/material'
import { NotificationType } from './NotificationInterfaces'

export const EventTileContainer = styled(Box)<{
  isExpanded: boolean
  isNew: boolean
}>(({ isExpanded, isNew, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem 0.8rem',
  height: isExpanded ? 'auto' : '9.5rem',
  backgroundColor: isNew ? theme.colors.newNotificationBgColor : 'transparent',
}))

export const EventTileHeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const EventTileTitleContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  minWidth: '15rem',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '1.5rem',
}))

export const EventTileTitleText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginLeft: '1rem',
  alignItems: 'center',
  height: '1.5rem',
  fontSize: '1rem',
  color: theme.colors.primary500,
}))

export const EventTileStatusContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  minWidth: '15rem',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '1.5rem',
}))

export const StatusIconContainer = styled(Box)<{ type: string }>(
  ({ type, theme }) => ({
    display: 'flex',
    borderRadius: '1.7rem',
    width: '1.7rem',
    height: '1.7rem',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:
      type === 'Disabled' ? theme.colors.gray400 : theme.colors.error500,
    color: theme.colors.white,
    marginRight: '0.3rem',
  })
)

export const EventTileDetailsContainer = styled(Box)(({ theme }) => ({
  mb: 2,
  padding: '1rem',
  backgroundColor: theme.colors.notificationDetailsColor,
  display: 'flex',
  flexDirection: 'column',
  minWidth: '31rem',
  height: 'auto',
  marginBottom: '1.5rem',
}))

export const EventTileExpandedContainer = styled(Box)<{ isExpanded: boolean }>(
  ({ isExpanded }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isExpanded ? 'space-between' : 'flex-end',
    alignItems: 'start',
    width: '28rem',
    marginBottom: isExpanded ? '0.5rem' : '0rem',
    marginTop: isExpanded ? '-0.5rem' : '0rem',
  })
)

export const EventTileFooterContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const EventTileDescription = styled(Typography)(({ theme }) => ({
  width: '27rem',
  marginTop: '0.4rem',
  marginBottom: '0.4rem',
  height: '4rem',
  fontSize: '1rem',
  justifyContent: 'space-between',
  weight: '400',
  lineHeight: '1.5rem',
}))

export const TimestampContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  minWidth: '6rem',
  justifyContent: 'flex-start',
  marginLeft: 'auto',
  marginRight: '3rem',
}))

export const StatusIcon = styled(Box)<{ type: NotificationType }>(
  ({ theme, type }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    backgroundColor:
      type === NotificationType.DISABLED
        ? theme.colors.gray400
        : theme.colors.errorA300,
    color: theme.palette.common.white,
    borderRadius: '50%',
  })
)

export const TypeContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`

export const TypeText = styled(Typography)`
  font-weight: 700;
  margin-right: 1rem;
`

export const NotificationHeader = styled(Box)(({ theme }) => ({
  padding: '1rem',
  boxShadow: `-4px 3px 2px  ${theme.colors.divider}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: 'inherit',
}))

export const TotalCountContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.totalNotificationsCountColor,
  borderRadius: '4px',
  padding: '2px 0.5rem',
  marginRight: '1rem',
  height: '1.5rem',
}))

export const IconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.totalNotificationsCountColor,
  borderRadius: '4px',
  marginRight: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '1.5rem',
  '&:hover': {
    cursor: 'pointer',
  },
}))

export const FiltersContainer = styled(Box)(({ theme }) => ({
  marginRight: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '1.5rem',
  color: theme.colors.primary600,
  '&:hover': {
    cursor: 'pointer',
  },
}))

export const HeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 30rem;
`

export const StyledInputLabel = styled(InputLabel)(() => ({
  fontSize: '0.75rem',
  lineHeight: '0.875rem',
  fontWeight: '400',
}))

export const StyledDateLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  fontWeight: '400',
}))

export const StyledButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '0.375rem',
  padding: '1rem',
}))

export const StyledTitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '.MuiBadge-badge': {
    fontSize: '0.6rem',
    maxWidth: '0.5rem',
    borderRadius: '50%',
    transform: 'scale(0.85) translate(-13px, 12px) !important',
    backgroundColor: theme.colors.primary500,
  },
}))

export const StyledNoNotificationsBox = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '20rem',
  paddingTop: '2rem',
}))

export const StyledDescriptionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: '0.2rem',
}))
