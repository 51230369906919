import { forwardRef } from 'react';

import MuiTablePagination, {
  TablePaginationProps as MuiTablePaginationProps,
} from '@mui/material/TablePagination';

export type TablePaginationProps = MuiTablePaginationProps;

/** TablePagination
 *
 * `import { TablePagination } from '@fsp-io/shared-ui'`
 */

const TablePagination = forwardRef<HTMLElement, TablePaginationProps>(
  (props, ref) => {
    return <MuiTablePagination {...props} ref={ref} />;
  },
);

TablePagination.displayName = 'TablePagination';

export default TablePagination;
