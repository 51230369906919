import React from 'react'

import { styled } from '@mui/material/styles'
import Loader from '@/app/components/loader/Loader'
import { Box, Stack } from '@industrial-plus/shared-ui'

import IfpsLogo from '../ifps-logo'

const StyledStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  height: 98vh;
  box-sizing: border-box;
  justify-content: center;
`

const FullPageLoader: React.FC = () => (
  <StyledStack>
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <IfpsLogo marginBottom="2rem" marginTop="4rem" />
    </Box>
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop={10}
    >
      <Loader />
    </Box>
  </StyledStack>
)

export default React.memo(FullPageLoader)
