import { SelectChangeEvent } from '@mui/material'
import { styled } from '@mui/material/styles'
import { SystemStyleObject } from '@mui/system'
import React, { useMemo } from 'react'
import { CountryCode } from 'react-country-flags-lazyload'
import countryList from 'react-select-country-list'

import { InputMaxHeightMap } from '../../utils/input.utils'
import { getSxStyles } from '../../utils/styles.utils'
import CountryFlag from '../country-flag'
import CountrySelectStyles, { wrapperMenuStyles } from './styles'
import { CountrySelectProps } from './types'

import Box from '../box'
import ClearButtonEndAdornment from '../clear-button-end-adornment'
import FormControl from '../form-control'
import FormHelperText from '../form-helper-text'
import Input from '../input'
import InputLabel from '../input-label'
import MenuItem from '../menu-item'
import Select from '../select'

/**
 * The CountrySelect component allow select country
 *
 * It inherits properties from Select
 *
 */

const CountrySelectStyled = styled(Select)(CountrySelectStyles)

export default function CountrySelect({
  selected = '',
  countries = [],
  customLabels,
  showBorder = false,
  size = 'medium',
  onSelect,
  labelId,
  label,
  helperText,
  required,
  error,
  disabled,
  sx = {},
  onClear,
  sortingByLabel,
  onBlur,
  forbiddenCountries,
  ...rest
}: CountrySelectProps) {
  const options = useMemo(() => {
    let list = countryList().getData()

    if (forbiddenCountries) {
      const forbiddenCountryCodes = forbiddenCountries.map(
        (country) => country.value
      )
      list = list.filter(
        (country) => !forbiddenCountryCodes.includes(country.value)
      )
    }

    const filteredCountries =
      (countries.length > 0
        ? list.filter(({ value }) => countries.includes(value))
        : list) || []
    return customLabels && Object.keys(customLabels).length > 0
      ? filteredCountries.map((country) => ({
          value: country.value,
          label: customLabels?.[country.value]
            ? customLabels[country.value]
            : country.label,
        }))
      : filteredCountries
  }, [countries, customLabels, forbiddenCountries])

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onSelect?.(event.target.value as string)
  }

  const countryOptions = useMemo(() => {
    if (sortingByLabel) {
      return [...options].sort((a, b) =>
        sortingByLabel === 'asc'
          ? a.label.localeCompare(b.label)
          : b.label.localeCompare(a.label)
      )
    }

    return options
  }, [sortingByLabel, options])

  return (
    <>
      {label && (
        <InputLabel id={labelId} style={{ fontSize: 'small' }}>
          {label}
        </InputLabel>
      )}
      <FormControl
        variant="filled"
        error={error}
        required={required}
        size={size === 'xsmall' ? 'small' : size}
        disabled={disabled}
        fullWidth
      >
        <CountrySelectStyled
          input={
            <Input
              showBorder={showBorder}
              size={size}
              data-testid="country-select"
              hiddenLabel={!label}
              endAdornment={
                selected &&
                onClear && (
                  <ClearButtonEndAdornment
                    position="end"
                    handleClear={onClear}
                  />
                )
              }
            />
          }
          onBlur={onBlur}
          onChange={handleChange}
          showBorder={showBorder}
          value={selected}
          sx={(theme) =>
            ({
              ...getSxStyles(theme, sx),
              height: InputMaxHeightMap[size],
            } as SystemStyleObject)
          }
          {...rest}
        >
          {countryOptions.map(
            (option) =>
              option.value &&
              option.label && (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  data-testid={`select-country-${option.value}`}
                >
                  <Box sx={wrapperMenuStyles}>
                    <CountryFlag countryCode={option.value as CountryCode} />
                  </Box>
                  <Box component="span" sx={{ pl: 0.6 }}>
                    {option.label}
                  </Box>
                </MenuItem>
              )
          )}
        </CountrySelectStyled>
        {helperText && (
          <FormHelperText data-testid="select-country-helper">
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </>
  )
}
