import { forwardRef } from 'react';

import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table';

export interface TableProps extends MuiTableProps {}

/** Table
 *
 * Tables display information in a way that's easy to scan, so that users can look for patterns and insights.
 *
 * `import { Table } from '@fsp-io/shared-ui'`
 */

const Table = forwardRef<HTMLTableElement, TableProps>((props, ref) => {
  return <MuiTable {...props} ref={ref} />;
});

Table.displayName = 'Table';

export default Table;
