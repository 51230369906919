import React from 'react'

import { Stack, Typography } from '@industrial-plus/shared-ui'
import { useTheme } from '@mui/material'

import IFPSMiniLogo from '@/assets/svg/ifps-logo-mini.svg'

import { StyledIconButton, ToolbarIcon } from './styles'
import Symbol from '../symbol/Symbol'

interface Props {
  open: boolean
  onToggle?: () => void
}

const Toolbar: React.FC<Props> = (props: Props) => {
  const { open, onToggle } = props
  const theme = useTheme()

  return (
    <ToolbarIcon onClick={onToggle} aria-label="toggle">
      <Stack direction="row" alignItems="center" mr={open ? 'auto' : 0}>
        <img src={IFPSMiniLogo} alt="logo" height={32} />
        {open ? (
          <Typography variant="h6" fontWeight={700} ml={1}>
            IFPS
          </Typography>
        ) : (
          <Symbol
            iconName="chevron_right"
            color="secondary"
            sx={{
              bgcolor: theme.colors.gray200,
              borderRadius: '100px',
            }}
          />
        )}
      </Stack>
      {open && (
        <StyledIconButton
          onClick={onToggle}
          color="primary"
          size="large"
          sx={{ bgcolor: theme.colors.gray200 }}
        >
          {theme.direction === 'rtl' ? (
            <Symbol iconName="chevron_right" color="secondary" />
          ) : (
            <Symbol iconName="chevron_left" color="secondary" />
          )}
        </StyledIconButton>
      )}
    </ToolbarIcon>
  )
}

export default React.memo(Toolbar)
