import isEmail from 'validator/lib/isEmail'

export interface EmailData {
  email: string
  isValid: boolean
  errorMessage: string
}

export function isEmailValid(email: string): EmailData {
  const emailData = {
    email: email.trim(),
    isValid: true,
    errorMessage: '',
  }

  switch (true) {
    case emailData.email === '': {
      emailData.isValid = false
      emailData.errorMessage = 'Email required'

      return emailData
    }
    case !isEmail(emailData.email): {
      emailData.isValid = false
      emailData.errorMessage = 'Wrong email format'

      return emailData
    }
    default: {
      return emailData
    }
  }
}

export const validateIp = (ip: string): boolean => {
  const ipRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/
  if (!ipRegex.test(ip)) return false

  const ipParts = ip.split('.')
  for (let i = 0; i < ipParts.length; i++) {
    const part = parseInt(ipParts[i])
    if (part < 0 || part > 255) return false
  }

  return true
}
