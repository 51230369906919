import { forwardRef } from 'react';

import {
  StepButton as MuiStepButton,
  StepButtonProps as MuiStepButtonProps,
} from '@mui/material';

export type StepButtonProps = MuiStepButtonProps;

/**
 * `import { StepButton } from '@fsp-io/shared-ui'`
 */

const StepButton = forwardRef<HTMLButtonElement, StepButtonProps>(
  (props, ref) => {
    return <MuiStepButton {...props} ref={ref} />;
  },
);

StepButton.displayName = 'StepButton';

export default StepButton;
