/* eslint-disable @typescript-eslint/no-explicit-any */
import { Formik, FormikHelpers, FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button'
import CircularProgress from '../circular-progress'
import DialogActions from '../dialog-actions'
import DialogContent from '../dialog-content'
import FormikMultiSelect from '../formik-multi-select'
import FormikSingleSelect from '../formik-single-select'
import FormikTextArea from '../formik-textarea'
import FormikTextField from '../formik-textfield'
import FormikCheckbox from '../formik-checkbox'
import Grid from '../grid'
import LinearProgress from '../linear-progress'
import Stack from '../stack'
import Typography from '../typography'
import FormikCountrySelect from './FormikCountrySelect'
import FormikPhoneNumber from './FormikPhoneNumber'
import FormikRadioGroup from './FormikRadioGroup'
import { Form } from './styles'
import { FormInputProps, FormSectionInputProps } from './types'
import { getInputs } from './utils'
import FormLabel from '../form-label'

interface Props {
  loading: boolean
  onClose: () => void
  form: FormSectionInputProps[]
  onSubmit: (
    values: {
      [key: string]: any
    },
    formikHelpers: FormikHelpers<{
      [key: string]: any
    }>
  ) => void | Promise<any>
  addButtonText?: string
  boldLabels?: boolean
  centerButtons?: boolean
  showActionAsButtons?: boolean
  isSmallScreen?: boolean
}

const DynamicFormik: React.FC<Props> = (props: Props) => {
  const { initialValues, validationSchema } = getInputs(props.form)
  const { t } = useTranslation()
  const closeDialog = () => {
    props.onClose()
  }

  const buttonProps = props.showActionAsButtons
    ? {
        size: 'small' as const,
        sx: {
          margin: '0.5rem',
        },
      }
    : {
        size: 'medium' as const,
        sx: {},
      }

  const uiHandler = (
    inputProps: FormInputProps,
    formikProps: FormikProps<{ [key: string]: any }>
  ) => {
    const { value, label, options, onChange, hideField, ...otherProps } =
      inputProps

    switch (otherProps.type) {
      case 'textarea':
        return (
          <FormikTextArea
            label={label as string}
            value={value as string | number}
            {...otherProps}
          />
        )
      case 'multi-select':
        return (
          <FormikMultiSelect
            label={label as string}
            options={options as { name: string; value: string }[]}
            boldLabels={props.boldLabels}
            {...otherProps}
          />
        )
      case 'single-select':
        return (
          <FormikSingleSelect
            label={label as string}
            options={options as { name: string; value: string }[]}
            onChange={(value: string) => {
              if (onChange) {
                onChange(value, formikProps)
              }
            }}
            {...otherProps}
          />
        )
      case 'choose-country':
        return (
          <FormikCountrySelect
            input={inputProps}
            forbiddenCountries={inputProps.forbiddenCountries}
          />
        )
      case 'phone-number':
        return (
          <FormikPhoneNumber
            countryDefaultValue={formikProps.values['country']}
            input={inputProps}
          />
        )
      case 'radio':
        return <FormikRadioGroup input={inputProps} />
      case 'checkbox':
        return <FormikCheckbox input={inputProps} />
      case 'labelText':
        return (
          <FormLabel sx={inputProps?.InputLabelProps?.sx ?? {}}>
            {label}
          </FormLabel>
        )
      case 'email':
      case 'password':
      case 'text':
      default:
        return <FormikTextField value={value} label={label} {...otherProps} />
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        return (
          <>
            <DialogContent dividers>
              <Form
                onSubmit={formikProps.handleSubmit}
                onReset={formikProps.handleReset}
              >
                <Grid container paddingX={2} marginBottom={2}>
                  {props.form.map((section, index) => (
                    <Grid item container spacing={2} key={index}>
                      <Stack
                        direction="row"
                        width="100%"
                        spacing={1}
                        marginTop={4}
                      >
                        {section.icon}
                        {section.title && (
                          <Typography variant="subtitle1" pt={1}>
                            {section.title}
                          </Typography>
                        )}
                      </Stack>
                      {section.inputs.map((input, index) => {
                        if (input.hideField && input.hideField(formikProps)) {
                          return null
                        }

                        return (
                          <Grid item key={index} xs={input.xs} sm={input.sm}>
                            {uiHandler(input, formikProps)}
                          </Grid>
                        )
                      })}
                    </Grid>
                  ))}
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions
              sx={
                props?.isSmallScreen || props.centerButtons
                  ? { justifyContent: 'center' }
                  : { justifyContent: 'right' }
              }
            >
              <Button
                onClick={closeDialog}
                variant={props.showActionAsButtons ? 'outlined' : 'text'}
                color={props.showActionAsButtons ? 'secondary' : 'primary'}
                disabled={props.loading}
                data-testid="modal-action-cancel"
                {...buttonProps}
              >
                {t('common.cancel')}
              </Button>
              {props.loading && <LinearProgress /> && (
                <CircularProgress data-testid="modal-loader-success" />
              )}
              <Button
                disabled={
                  !formikProps.isValid ||
                  !formikProps.dirty ||
                  formikProps.isSubmitting ||
                  props.loading
                }
                type="submit"
                variant={props.showActionAsButtons ? 'contained' : 'text'}
                data-testid="modal-action-submit"
                onClick={() => formikProps.handleSubmit()}
                {...buttonProps}
              >
                {t(props.addButtonText!)}
              </Button>
            </DialogActions>
          </>
        )
      }}
    </Formik>
  )
}

DynamicFormik.defaultProps = {
  addButtonText: 'Create',
}

export default React.memo(DynamicFormik)
