import { styled } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { getSxStyles } from '../../utils/styles.utils';
import Typography from '../typography';
import styles from './styles';
import { CopyRightTextAlign, CopyrightProps } from './types';

const CopyrightStyled = styled(Typography)(styles);
const baseClassName = 'copyright';

/**
 * Copyright
 *
 * `import { Copyright } from '@fsp-io/shared-ui'`
 */
const Copyright: FC<CopyrightProps> = ({
  text,
  textAlign,
  textRight,
  sx,
  className,
  variant,
}) => {
  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <CopyrightStyled
      variant={variant}
      sx={(theme) => ({ ...getSxStyles(theme, sx) } as SystemStyleObject)}
      className={clsx(baseClassName, className)}
    >
      {textAlign === CopyRightTextAlign.LEFT ? (
        <>
          {text} {currentYear} © {textRight}
        </>
      ) : (
        <>
          © {currentYear} {text}
        </>
      )}
    </CopyrightStyled>
  );
};

Copyright.defaultProps = {
  variant: 'body2',
};

export default Copyright;
