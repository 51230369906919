import { forwardRef } from 'react';

import MuiTextareaAutosize, {
  TextareaAutosizeProps as MuiTextareaAutosizeProps,
} from '@mui/base/TextareaAutosize';

export type TextareaAutosizeProps = MuiTextareaAutosizeProps

/** TextareaAutosize
 *
 * A textarea component for React which grows with content.
 *
 * `import TextareaAutosize from '@fsp-io/TextareaAutosize'`
 */

const TextareaAutosize = forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>(
  (
    {
      minRows = 1, // Default value added here, since ThemeConfig doesn't support default values for TextareaAutosize
      ...rest
    },
    ref,
  ) => {
    return <MuiTextareaAutosize minRows={minRows} {...rest} ref={ref} />;
  },
);

TextareaAutosize.displayName = 'TextareaAutosize';

export default TextareaAutosize;
