import { forwardRef } from 'react';

import MuiBadge, { BadgeProps as MuiBadgeProps } from '@mui/material/Badge';

export interface BadgeProps extends MuiBadgeProps {
  /**
   * Children icon size for proper badge alignment. This property only works with `overlap` set to `"rectangular"`.
   */
  iconSize?: 'large' | 'medium' | 'small' | 'xsmall';
}

/** Badge generates a small badge on its child
 *
 * `import { Badge } from '@fsp-io/shared-ui'`
 */

const Badge = forwardRef<HTMLSpanElement, BadgeProps>((props, ref) => {
  const { iconSize, ...rest } = props;
  return <MuiBadge className={iconSize} {...rest} ref={ref} />;
});

Badge.displayName = 'Badge';

export default Badge;
