export default {
  common: {
    version: 'Version:',
    character: {
      minimum: 'Minimum 2 characters',
      maximum32: 'Maximum 32 characters',
      maximum320: 'Maximum 320 characters',
      alphaNumeric: 'Only alphanumeric characters are allowed',
    },
    back: '< Back',
    businessUnit: {
      autronica: 'Autronica',
      marioff: 'Marioff',
    },
    businessUnitIndex: 'Business Unit',
    carrier: 'Carrier',
    dismiss: 'Dismiss',
    disabledShortcut: 'D',
    email: 'Email',
    password: 'Password',
    country: 'Country',
    phoneNumber: 'Phone Number',
    role: 'Role',
    profile: 'Profile',
    new: 'New',
    logo: 'logo',
    logout: 'Logout',
    total: 'Total',
    types: {
      alarm: 'Type of Alarm',
      device: 'Device Type',
    },
    names: {
      site: 'Site Name',
      system: 'System Name',
      device: 'Device Name',
    },
    zone: {
      detection: 'Detection Zone',
    },
    cancel: 'Cancel',
    noData: 'No Data Found',
    noDataAvailable: 'No data available for the selected date range',
    unknownError: 'Unknown error occurred',
    errorOccurred: 'Error occured',
    emptyResult: 'No results found, clear or adjust your filters.',
    createdSuccessfully: 'created successfully',
    updatedSuccessfully: 'updated successfully',
    submit: 'Submit',
    timestamp: 'Timestamp',
    upload: 'Upload',
    close: 'Close',
    yes: 'Yes',
    no: 'No',
    from: 'From',
    to: 'To',
    filter: 'Filter',
    apply: 'Apply',
    actions: {
      markAllRead: 'Mark all read',
      dismissAll: 'Dismiss all',
    },
    filters: 'Filters',
    unassign: 'Unassign',
    assign: 'Assign',
    notifications: {
      index: 'Notifications',
      panel: 'Notifications Panel',
      permissionUnabled: 'Unable to get permission to notify.',
      error: 'Error querying notification permissions:',
    },
    greetings: {
      goodMorning: 'Good morning',
      goodAfternoon: 'Good afternoon',
      goodEvening: 'Good evening',
    },
    date: 'Date',
    sec: 'sec',
    count: 'count',
    good: 'Good',
    warning: 'Warning',
    critical: 'Critical',
    pump: 'Pump',
    trend: 'trend',
    bar: 'bar',
    pressure: 'Pressure',
    low: 'low',
    okay: 'Okay',
    save: 'Save',
    delete: 'Delete',
    tenants: 'Tenants',
  },

  components: {
    common: {},
    table: {
      footer: {
        mobile: 'Mobile Number',
        verify: 'Need to Verify',
        verified: 'Verified',
      },
    },
    calendar: {
      currentMonth: 'Current Month',
      lastMonth: 'Last Month',
      last3Months: 'Last 3 Months',
      last6Months: 'Last 6 Months',
      last12Months: 'Last 12 Months',
      last18Months: 'Last 18 Months',
    },
    notifications: {
      alertWarning:
        'Please enable browser notification for ICS plus notifications to work as expected.',
      notificationsDisabled:
        'ICS plus notifications will not work as expected, because the browser notifications are disabled.',
      notificationRegistrationFailed:
        'Device registration failed for Notification',
    },
  },

  auth: {
    left: {
      guarding: 'Guarding Lives, Securing Futures',
      tomorrows: "Tomorrow's Safety",
      todays: "Today's Solutions",
    },

    login: {
      index: 'Login',
      signed: 'Keep me signed in',
      agree: 'Agree to our',
      privacy: {
        policy: 'Privacy Policy',
        checking: 'You must accept the privacy policy by checking the box.',
      },
      forgot: {
        password: 'Forgot Password?',
      },
      terms: 'Terms of Service',
      rightsReserved: 'All Rights Reserved',
      redirect: 'Redirect to Login Page',
      email: {
        wrongFormat: 'Wrong email format',
        required: 'Email is required',
      },
      password: {
        required: 'Password is required',
      },
      validCredentials: 'Please enter valid credentials!',
    },

    reset: {
      password: 'Reset Password',
      email: 'Reset via email',
      backLogin: 'Back to Login',
    },
  },

  users: {
    index: 'Users',
    user: 'User',
    create: 'Create User',
    add: 'Add User',
    empty: {
      list: 'No users were found. Please create a user.',
    },
    profile: {
      index: 'My Profile',
      changePassword: 'Change Password',
      notifications: {
        index: 'Notifications',
        enable: 'Enable Notifications',
        reset: 'Reset',
        push: 'Push',
        error: 'Error updating notification settings',
        modalIndex: 'Reset Notifications',
        topResetMessage: 'Are you Sure!',
        bottomResetMessage: 'you want to reset your notification settings?',
        empty: 'No notifications yet',
        types: {
          disablements: 'Disablements',
          alarms: 'Alarms',
          connectivity: 'Connectivity',
          faults: 'Faults',
          events: 'Events',
          inspections: 'Inspections',
        },
        tooltips: {
          Alarms:
            'An alarm requires immediate attention. (For example: A fire alarm triggered by smoke detection.)',
          Faults:
            'Indicates a malfunction or potential issue with the system.(example: A faulty unit on the Loop.)',
          Connectivity:
            'Gateway connection status (example: Gateway lost IP connection with the panel.)',
          Events:
            'Events are non-critical system activities or changes that are logged for monitoring purposes. (Example: A scheduled system update was completed successfully)',
          Disablements:
            'This notifications are triggered when a Loop, Point or Detection Zone is Disabled or Enabled (ex. If the time of enable is exceeded, the disablement will trigger a Fault)',
          Inspections:
            'Inspections refer to routine checks to ensure the system is functioning correctly (Example: Monthly maintenance inspection completed.)',
        },
        filter: {
          notificationType: 'Notification Type',
          clearFilter: 'Clear Filter',
          fromDateLessThanTo:
            'From date should be less than or equal to To date',
          toDateGreaterThanFrom: 'To date should be greater than From date',
        },
        details: {
          siteName: 'Site Name',
          systemName: 'System Name',
          eventType: 'Event Type',
          deviceType: 'Device Type',
          deviceName: 'Device Name',
          detectionZoneName: 'Detection Zone Name',
          criticality: 'Criticality',
          signalName: 'Signal Name',
          signalDescription: 'Signal Description',
          unitName: 'Unit Name',
          signalValue: 'Signal Value',
          tenantId: 'Tenant ID',
          siteId: 'Site ID',
          eventTimestamp: 'Event Timestamp',
          businessUnit: 'Business Unit',
          location: 'Location',
          notificationType: 'Notification Type',
          inspectionType: 'Inspection Type',
          inspectionStatus: 'Inspection Status',
          pumpUnitName: 'Pump Unit Name',
          completedDate: 'Completed Date',
          version: 'Version',
          gatewayName: 'Gateway Name',
          lastHeartbeatAt: 'Last Heartbeat at',
          date: 'Date',
        },
      },
      home: 'Home',
    },
    details: {
      sitesAssigned: 'No of Sites Assigned',
    },
  },

  managerPlus: {
    index: 'Manager+',
    dashboard: {
      index: 'Dashboard',
      content: 'Manager+ Dashboard',
      sitesStatus: 'Sites Status',
      needAttention: 'Need Attention',
      normal: 'Normal',
      sites: 'Sites',
      noSitesFound: 'No sites were found for the tenant.',
      table: {
        header: {
          sites: 'Sites',
          total: 'Total',
          statusChangedTime: 'Status Changed Time',
          status: 'Status',
          issues: 'Issues',
          contactNumber: 'Contact Number',
          contactEmail: 'Contact Email',
          connectivityIssues: 'Connectivity<br /> Issues',
          activeAlarms: 'Active<br /> Alarms',
          pumpUnitIssues: 'Pump Unit<br /> Issues',
          loopIssues: 'Loop<br /> Issues',
          disabledLoops: 'Disabled<br /> Loops',
        },
        tooltip: {
          connectivityIssues: 'Connectivity Issues',
          gateways: 'Gateways',
          systems: 'Systems',
          totalActiveAlarms: 'Total Active Alarms',
          needAttention: 'Need Attention',
          totalpumpUnit: 'Total PumpUnits',
          totalLoops: 'Total Loops',
          disabledLoops: 'Disabled Loops',
        },
      },
    },
    inspections: {
      index: 'Inspections',
      singularForm: 'Inspection',
      templatesAndInspections: 'Templates and Inspections',
      templates: {
        index: 'Templates',
        noTemplate: 'No templates were found. Please create a template.',
        add: 'Add template',
        list: {
          headers: {
            inspectionType: 'Inspection Type',
            name: 'Template Name',
            createdAt: 'Created date',
            createdBy: 'Created by',
            actions: 'Actions',
          },
        },
        createTemplate: {
          title: 'New Template',
          name: {
            index: 'Template Name*',
          },
          checklist: {
            noChecklists:
              'No checklists were found. Please create a checklist.',
          },
        },
      },
      list: {
        headers: {
          inspectionType: 'Inspection Type',
          templateName: 'Template Name',
          siteName: 'Site',
          unitName: 'Unit',
          scheduledDate: 'Scheduled Date',
          version: 'Version',
          status: 'Status',
          createdBy: 'Created by',
          actions: 'Actions',
        },
        total: 'Inspections [ Total: ',
        noInspection: 'No inspections were found. Please create a inspection.',
        add: 'Create Inspection',
        delete: {
          header: 'Delete Inspection',
          confirmation: 'Are you sure you want to delete this inspection?',
          success: 'Inspection deleted',
        },
        create: {
          index: 'Create Inspection',
          site: {
            index: 'Site',
            required: 'Site is required',
          },
          success: 'Inspection created successfully',
          unit: {
            index: 'Unit',
            required: 'Unit is required',
            noUnits: 'No unit found',
          },
          inspectionType: {
            index: 'Inspection Type',
            required: 'Inspection type is required',
          },
          templateName: {
            index: 'Template Name',
            required: 'Template name is required',
          },
          date: {
            index: 'Date',
            required: 'Date is required',
            notPast: 'Date cannot be in the past',
          },
          comment: 'Comment',
          submit: 'Create Inspection',
          error: 'Error creating inspection:',
        },
        calendar: {
          createTemplate: 'Create template',
          legend: {
            created: 'Created',
            inProgress: 'In progress',
            completed: 'Completed',
          },
          error: 'Error loading inspections',
        },
      },
      all: 'All Inspections',
    },
    reports: {
      index: 'Reports',
      content: 'Manager+ Reports',
      noReport: 'No reports were found.',
      headers: {
        inspectionType: 'Inspection type',
        templateName: 'Template name',
        site: 'Site',
        unit: 'Unit',
        completedDate: 'Completed date',
        completedBy: 'Completed by',
        actions: 'Actions',
      },
      download: {
        index: 'Download',
        linkNotAvailable: 'Download link is not available',
        error: 'Error downloading the report: ',
      },
      share: {
        index: 'Share',
        modalIndex: 'Share Inspection',
        emailId: 'Email ID',
        send: 'Send',
        cancel: 'Cancel',
        placeholder: 'Enter Email address',
        emailRequired: 'Email is required',
        invalidEmail: 'Wrong email format',
        maxLength: 'Email should have max 255 characters',
        success: 'Report shared successfully',
        tooltip: 'Add more than one email by separating emails with a comma',
        info: 'Info',
      },
      fileView: {
        index: 'PDF View',
        inspection: 'Inspection',
        completedBy: 'Completed By:',
        completedDate: 'Completed Date:',
        siteName: 'Site Name:',
        tenantName: 'Tenant Name:',
        checklist: 'Checklist',
        comments: 'Comments',
        version: 'Version',
        person: 'Person',
      },
    },
  },

  adminPlus: {
    index: 'Admin+',
    title: 'Master Inspection Templates',
    master: {
      index: 'Master Templates',
      createTemplate: {
        title: 'Create Master Template',
        name: {
          index: 'Master Template Name*',
        },
        checklist: {
          noChecklists: 'No checklists were added. Please add a checklist.',
        },
      },
      totalTemplates: 'Total Templates',
      createButton: 'Create Master Template',
      NoDataMessage:
        'No master templates were found; please create a master template.',
      list: {
        view: 'View',
        archive: 'Archive',
        editTemplate: 'Edit Template',
        assignMasterTemplate: 'Assign Master Template',
        templateType: 'Template Type',
        templateName: 'Template Name',
        createdBy: 'Created By',
        createdAt: 'Created At',
        assignedTenants: 'Assigned Tenants',
        actions: 'Actions',
      },
      assign: {
        index: 'Assign Master Template',
        minimumWarning: 'Please select tenants to assign',
        success: 'Template Assigned Successfully',
        list: {
          tenantName: 'Tenant Name',
        },
      },
      archiveReason: {
        archiveTemplate: 'Archive Template',
        reasonQuestion: ' What is the reason for Archive Template?',
        placeholder: 'Please write reason...',
        submit: 'Submit',
        cancel: 'Cancel',
        yes: 'Yes',
        confirming: 'Are you sure!',
        wantTo: 'Do you want to archive this template?',
        reasonRequired: 'Please provide a reason.',
        reasonMaxWords: 'Reason should not exceed 300 words.',
        reasonMinWords: 'Reason must contain at least 7 words.',
        result: {
          success: 'Template archived successfully',
          error: 'An error occurred while archiving the template:',
        },
      },
    },
    assigned: {
      index: 'Assigned Templates',
      tenantName: 'Tenant Name',
      actions: 'Actions',
      NoDataMessage: 'No assigned templates were found.',
      unassign: {
        index: 'Unassign Templates',
        minimumWarning: 'Please select templates to unassign',
        success: 'Template(s) unassigned successfully',
        list: {
          templateType: 'Template Type',
          templateName: 'Template Name',
          createdBy: 'CreatedBy',
        },
      },
    },
    archived: {
      index: 'Archived Templates',
      NoDataMessage: 'No archived templates were found.',
      list: {
        templateType: 'Template Type',
        templateName: 'Template Name',
        archivedBy: 'Archived By',
        archivedAt: 'Archived At',
      },
    },
  },

  inspections: {
    inspectionTypes: {
      index: 'Inspection Type',
      weekly: 'Weekly Inspection',
      monthly: 'Monthly Inspection',
      custom: 'Custom Inspection',
      isRequired: 'Type is required',
    },
    createTemplate: {
      name: {
        isRequired: 'Name is required',
        maxLength: 'Name should have max 255 characters',
        placeholder: 'ex. Weekly Inspection',
      },
      checklist: {
        index: 'Check List',
        add: 'Add Checklist',
        addStep: 'Add Step',
        name: {
          isRequired: 'Checklist name is required',
          placeholder: 'ex: Pump Station Inspection',
          maxLength: 'Checklist name should have max 255 characters',
        },
        minimumOne: 'Template should contain minimum one checklist',
        steps: {
          index: 'Steps',
          step: 'Step',
          addImage: 'Add image',
          imageError:
            'Please select a valid image file (JPG, JPEG, PNG) under 5MB.',
          name: {
            isRequired: 'Step name is required',
            placeholder:
              'ex: Verify pressure gauge readings are within acceptable range',
            maxLength: 'Step name should have max 255 characters',
          },
          description: {
            index: 'Description',
            placeholder: 'ex: Description',
          },
        },
      },
      result: {
        success: 'Template created successfully',
        fail: 'Template creation failed',
        error: 'Error creating template:',
      },
    },
  },

  sites: {
    index: 'Sites',
    site: 'Site',
    create: 'Create Site',
    add: 'Add Site',
    notFound: 'No site found',
    addressInformation: 'Address Information',
    emailInformation: 'Email Information',
    phoneNumberInformation: 'Phone Number Information',
    information: 'information',
    dashboard: {
      index: 'Dashboard',
      gateway: 'Gateways',
      units: 'System Components',
      connectivity: 'Connectivity Status',
      activeAlarmStatus: 'Active Alarm Status',
      alarmsHeatmap: 'Alarms Heatmap',
      activeFrequentAlarm: 'Active Frequent Alarms',
    },
    assignedUser: {
      assignUser: 'Assign User',
      assignedUsers: 'Assigned Users',
    },
    unassignUser: {
      index: ' Unassign User',
      title: 'You are about Unassign User?',
      confirm: 'Are you sure you want to Unassign',
    },
    events: {
      index: 'Events',
      eventHistory: {
        index: 'Events History',
        deviceID: 'Device ID',
        eventValue: 'Event Value',
        eventType: 'Event Type',
        created: 'Created',
        deviceStatusChanged: 'Device Status Changed',
        lastUserActivity: 'Last User Activity',
        block: 'Block',
      },
    },
    alarms: {
      index: 'Alarms',
      active: 'Active',
      nonActive: 'Non active',
    },
    systems: {
      index: 'Systems',
      add: 'Add System',
      notadded: 'System not added',
      notFound: 'No system found',
      addSuccessfully: 'System added successfully',
      noSystem: 'No system were found. Please add system',
      showAllSystems: 'Show all systems',
      showUnassignedSystems: 'Show unassigned systems',
      typeRequired: 'Type is required',
      list: {
        archive: 'Archive',
        assignedGateway: 'Assigned Gateway',
        status: 'Status',
        systemName: 'System Name',
        type: 'Type',
        gateway: 'Gateway',
        connectionStatus: 'Connection Status',
        businessId: 'Business ID',
        actions: 'Actions',
        assigned: 'Assigned',
        unassigned: 'Unassigned',
      },
      installations: {
        tabName: 'Installations',
        loops: 'Loops',
        loop: 'Loop',
        loopsListEmptyNoLoops: 'No loops were found.',
        loopsListEmptyAddLoop: 'Please add a loop.',
        loopsListLoading: 'Loading...',
        addLoop: 'Add Loop',
        deleteLoop: 'Delete Loop',
        save: 'Save',
        noDevices: 'No devices were found. Please add a device.',
        uploadTemplateButton: 'Upload Installation File',
        downloadTemplateButton: 'Download Installation Template',
        addDevice: 'Add Device',
        editDevice: 'Edit Device',
        deleteDevice: 'Delete Device',
        loopsListMaxLoopsInfo:
          'Maximum number of loops per system is 6. You cannot add more loops here.',
        lock: 'Lock',
        unlock: 'Unlock',
        delete: 'Delete',
        rename: 'Rename',
        commission: 'Commission',
        download: 'Download',
        edit: 'Edit',
        device: 'Device',
        serialNumber: 'Serial Number',
        tagName: 'TAG Name',
        tagNumber: 'Tag Number',
        detectionZone: 'Detection Zone',
        class: 'Class',
        actions: 'Actions',
        status: 'Status',
        heat: 'Heat',
        flash: 'Flash',
        sounder: 'Sounder',
        commissioned: 'Commissioned On {{day}}',
        downloadsQuantity: '{{number}} Downloads',
        renameLoop: 'Rename Loop',
        validation: {
          uniqueLoop: 'Loop name should be unique',
          uniqueTag: 'Tag should be unique',
        },
        confirmation: {
          deleteDevice: 'Do you want to remove a device {{tag}}?',
          deleteLoop:
            'Removing loop {{name}} will remove all devices in that loop. Are you sure?',
        },
        snackbar: {
          deleteDeviceSuccess: 'Device deleted',
          deleteLoopSuccess: 'Loop deleted',
          downloadFileNotAvailableError: 'Template not available',
          downloadLinkNotAvailable: 'Link not available',
          downloadTemplateError: 'Error during template download:',
          createDeviceError: 'Error creating device:',
          addLoopError: 'Error creating loop:',
          updateDeviceError: 'Error updating device:',
          updateLoopError: 'Error updating loop: ',
          deleteDeviceError: 'Error while deleting device: ',
          deleteLoopError: 'Error while deleting loop: ',
          loopDataFileNotAvailable: 'Loop data file not available',
          loopDataFileDownload: 'Error during loop data file download:',
        },
        uploadFile: {
          dragDrop: 'Drag & drop to upload',
          browser: 'or browse Installation file',
          invalidFileType: 'Wrong file type. Please upload .csv file',
          invalidFile: 'Invalid file: ',
          fileError: 'File contains error',
          fileErrors: 'File contains errors',
          tooLarge: 'Files larger than 1 MB are not accepted',
          success: 'Template uploaded successfully',
          compressedFile:
            'This file is compressed. Please upload a plain text .csv file',
        },
      },

      systemConfiguration: {
        configurations: 'Configurations',
        devices: 'Devices',
        noConfig: 'No config was found. Please upload configuration file',
        uploadConfig: 'Upload Config',
      },
      gateway: {
        index: 'Gateway',
        add: 'Add Gateway',
      },
      uploadConfiguration: {
        dragDrop: 'Drag & drop to upload',
        browserConfig: 'or browse configurations',
        dragFile: 'Drop the file here ...',
        fileSizeIssue: 'File larger than 5 MBs are not accepted',
        nameRequired: 'Name is required',
        configName: 'Configuration name*',
        sameFile: 'Same file cannot be uploaded again',
        failed: 'Failed to upload file',
        diffSystem: 'Different System Config file cannot be uploaded here',
      },
      addSystem: {
        systemName: 'System Name *',
        systemID: 'System ID',
        systemType: 'System Type *',
      },
      addConnections: {
        assignGateway: 'Assign Gateway',
        connect: 'Connect',
        gateway: 'Gateway*',
        connectToGateway: 'Connect to Gateway',
        gatewayRequired: 'Gateway is required',
        invalidIP: 'Invalid IP Address',
        cantConnect: 'Cannot connect system to gateway',
        connectSuccessfully: 'System connected to gateway sucessfully',
        addGateway: 'Add Gateway',
        gatewayNotAdded: 'Gateway not added',
        gatewayAddedSucessfully: 'Gateway added sucessfully',
        gatewayExists:
          'Gateway with Client ID: {{nodeDeviceId}} already exists in ICS+, so it cannot be created again!',
        imeiExists: 'Gateway with IMEI {{imei}} already exists in ICS+!',
        warning: 'Warning: {{message}}',
        error: 'Error while adding gateway: {{message}}',
      },
      deleteConnections: {
        unassignGateway: 'Unassign Gateway',
        removeConnection: 'Remove connection',
        index: 'Are you sure you want to delete this connection?',
        unassignFromGateway: 'Unassign from gateway',
        cannotRemoveConnection: 'Cannot remove connection',
        connectionRemovedSucessfully: 'Connection removed sucessfully',
      },
      editGateway: {
        gatewayNotFind: 'Gateway not find',
        gatewayUpdatedSuccessfully: 'Gateway updated successfully',
        gatewayProperties: ' Gateway Properties',
      },
    },
    devices: {
      index: 'Devices',
    },
    systemAnalytics: {
      index: 'System Analytics',
      falseActivationRisk: {
        index: 'False Activation Risk',
        averageStandByPumpingTime: 'Average stand by Pumping Time',
        standbyPumpingFrequency: 'Standby Pumping Frequency',
        activationTimeLimit: 'Activation time limit',
        standByFrequency: 'Stand by frequency',
        noStandByPumpingOccurrence: 'No Standby pumping occurrence',
      },
      pumpMonitoring: {
        index: 'Pump Condition Monitoring',
        selectPumpUnit: 'Select Pump Unit (EPU)',
        allPumps: 'All pumps',
      },
    },

    siteIncidents: {
      index: 'Site Incidents',
    },
    siteWalkTests: {
      index: 'site Walk Tests',
    },
    siteStatus: {
      normal: 'Normal',
      needAttention: 'Need Attention',
      index: 'Site Status',
      lastUpdate: 'Last update',
      noDate: '--/--/----, --:--:--',
    },
  },

  support: {
    index: 'Support Page',
  },

  setting: {
    index: 'Settings',
  },

  pageNotFound: {
    index: 'Page Not Found...',
  },
}
