import { forwardRef } from 'react';

import {
  CardActions as MuiCardActions,
  CardActionsProps as MuiCardActionsProps,
} from '@mui/material';

export type CardActionsProps = MuiCardActionsProps

/** CardActions
 *
 * `import { CardActions } from '@fsp-io/shared-ui'`
 */

const CardActions = forwardRef<HTMLDivElement, CardActionsProps>(
  (props, ref) => {
    return <MuiCardActions {...props} ref={ref} />;
  },
);

CardActions.displayName = 'CardActions';

export default CardActions;
