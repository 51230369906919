import { forwardRef } from 'react';

import {
  StepContent as MuiStepContent,
  StepContentProps as MuiStepContentProps,
} from '@mui/material';

import Collapse from '../collapse';

export type StepContentProps = MuiStepContentProps

/**
 * `import { StepContent } from '@fsp-io/shared-ui'`
 */

const StepContent = forwardRef<HTMLDivElement, StepContentProps>(
  ({ TransitionComponent = Collapse, ...rest }, ref) => {
    return (
      <MuiStepContent
        TransitionComponent={TransitionComponent}
        {...rest}
        ref={ref}
      />
    );
  },
);

StepContent.displayName = 'StepContent';

export default StepContent;
