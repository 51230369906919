import { FC } from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import Typography from '../typography';
import FdsIcon from '../icon';
import Box from '../box';
import { MetadataItemProps } from './types';
import styles from './styles';

export const baseClassName = 'metadata-item';

const MetadataItemContainer = styled(Box)(styles.container);
const IconContainer = styled(FdsIcon)(styles.iconContainer);

const MetadataItem: FC<MetadataItemProps> = ({
  Icon,
  customIcon,
  sx = {},
  className = '',
  IconContainerProps = {},
  contentProps = {},
  children,
  ...rest
}) => {
  return (
    <MetadataItemContainer
      className={clsx(baseClassName, className)}
      sx={sx}
      {...rest}
    >
      {(customIcon || Icon) && (
        <IconContainer color="action" {...IconContainerProps}>
          {customIcon || (Icon && <Icon />)}
        </IconContainer>
      )}
      <Typography variant="caption" {...contentProps}>
        {children}
      </Typography>
    </MetadataItemContainer>
  );
};

export default MetadataItem;
