import { forwardRef, Ref } from 'react';

import { SystemStyleObject } from '@mui/system';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { Theme } from '@mui/material/styles';
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';
import { getSxStyles } from '../../utils/styles.utils';

export interface TabProps extends MuiTabProps {
  /**
   *
   * A ref that points to the TouchRipple element.
   */
  touchRippleRef?: Ref<TouchRippleActions>;
}

/** Tab
 *
 * `import { Tab } from '@fsp-io/shared-ui'`
 */
const Tab = forwardRef<HTMLDivElement, TabProps>(({ sx, ...rest }, ref) => {
  return (
    <MuiTab
      sx={(theme: Theme) =>
        ({
          ...getSxStyles(theme, sx),
          ...theme.typography.body2,
          textTransform: 'capitalize',
        } as SystemStyleObject)
      }
      {...rest}
      ref={ref}
    />
  );
});

Tab.displayName = 'Tab';

export default Tab;
