import clsx from 'clsx'
import { FC, FocusEvent, useCallback, useRef } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'
import { BoxStyled, StyledLabel } from './styles'
import { PhoneNumberProps } from './types'
import getResultOfValidateHandler from './utils/getResultOfValidateHandler'
import isValidDefault from './utils/isValidDefault'

/**
 * The PhoneNumber used for the select phone number.
 *
 * `import PhoneNumber from '@fsp-io/PhoneNumber'`
 */
const PhoneNumber: FC<PhoneNumberProps> = ({
  alwaysDefaultMask = false,
  autoFormat = true,
  containerClass = '',
  country = 'us',
  size,
  countryCodeEditable = false,
  disabled = false,
  disableCountryCode = false,
  defaultErrorMessage = 'Phone number is incorrect',
  disableDropdown = false,
  onBlur,
  placeholder = '+1 (212) 555-1212',
  preferredCountries = ['us'],
  validate = false,
  isValid,
  onValidate,
  fullWidth = false,
  specialLabel,
  sx = {},
  helperText,
  error,
  required,
  inputProps,
  showLabel = false,
  ...restProps
}) => {
  const validRef = useRef<boolean>()

  const stripCountryCode = (phoneNumber: string, countryCode: string) => {
    return phoneNumber.replace(countryCode, '').trim()
  }

  const handleValidation = useCallback(
    (
      valueArg: string,
      countryArg: object,
      countriesArg: object[],
      hiddenAreaCodesArg: object[]
    ) => {
      const dialCode = (countryArg as any)?.dialCode || ''
      const userEnteredPhoneNumber = stripCountryCode(valueArg, `+${dialCode}`)

      if (!userEnteredPhoneNumber || !/^[0-9]+$/.test(userEnteredPhoneNumber)) {
        validRef.current = true
        return true
      }

      let resultOfHandler: string | boolean = isValidDefault()
      validRef.current = true

      if (validate) {
        resultOfHandler = getResultOfValidateHandler(
          isValid === undefined ? null : isValid,
          userEnteredPhoneNumber,
          countryArg,
          countriesArg,
          hiddenAreaCodesArg
        )
        validRef.current =
          typeof resultOfHandler === 'string' ? false : resultOfHandler
        onValidate && onValidate(validRef.current)
      }

      return resultOfHandler
    },
    [validate, isValid, onValidate]
  )

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>, data: object | unknown) => {
      onBlur && onBlur(event, data, Boolean(validRef.current))
    },
    [onBlur]
  )

  return (
    <BoxStyled size={size} sx={sx} error={error} showLabel={showLabel}>
      {showLabel && (
        <StyledLabel size="small" shrink>
          {specialLabel}
          {required && <span aria-hidden="true">&nbsp;*</span>}
        </StyledLabel>
      )}
      <PhoneInput
        containerClass={clsx({
          [containerClass]: !!containerClass,
        })}
        alwaysDefaultMask={alwaysDefaultMask}
        autoFormat={autoFormat}
        country={country}
        countryCodeEditable={countryCodeEditable}
        disabled={disabled}
        disableCountryCode={disableCountryCode}
        disableDropdown={disableDropdown}
        placeholder={placeholder}
        preferredCountries={preferredCountries}
        inputProps={inputProps}
        defaultErrorMessage={defaultErrorMessage}
        onBlur={handleBlur}
        inputStyle={{
          width: fullWidth ? '100%' : undefined,
          cursor: disabled ? 'pointer' : undefined,
          ...restProps.inputStyle,
        }}
        {...restProps}
        isValid={handleValidation}
      />
      {/* {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )} */}
    </BoxStyled>
  )
}

export default PhoneNumber
