import { forwardRef } from 'react';

import {
  CardActionArea as MuiCardActionArea,
  CardActionAreaProps as MuiCardActionAreaProps,
} from '@mui/material';

export type CardActionAreaProps = MuiCardActionAreaProps

/** CardActionArea
 *
 * `import { CardActionArea } from '@fsp-io/shared-ui'`
 */

const CardActionsArea = forwardRef<HTMLButtonElement, CardActionAreaProps>(
  (props, ref) => {
    return <MuiCardActionArea {...props} ref={ref} />;
  },
);

CardActionsArea.displayName = 'CardActionsArea';

export default CardActionsArea;
