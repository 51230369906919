import { Components } from "@mui/material/styles";
import { TypographyVariantMapping, fleetTypography } from "./typography";
import fleetPalette from "./colors";

export const fleetComponents: Components = {
  MuiCssBaseline: {
    defaultProps: {
      enableColorScheme: false,
    },
  },
  MuiAccordion: {
    defaultProps: {
      defaultExpanded: false,
      disabled: false,
      disableGutters: false,
      square: false,
    },
    styleOverrides: {
      root: {
        "&.MuiAccordion-root": {
          padding: "8px 0",
        },
        "&.MuiAccordion-rounded": {
          borderRadius: "8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper": {
          marginLeft: "18px",
          "& .MuiSvgIcon-root": {
            marginLeft: "0px",
          },
        },
        "& .MuiAccordionSummary-content": {
          alignItems: "center",
          "&.Mui-expanded": {
            margin: "12px 0",
          },
          ".MuiTypography-subtitle2": {
            marginTop: 0,
          },
        },
      },
    },
  },
  MuiAccordionActions: {
    defaultProps: {
      disableSpacing: false,
    },
  },
  MuiAvatar: {
    defaultProps: {
      variant: "rounded",
    },
    styleOverrides: {
      root: {
        "&.MuiAvatar-rounded": {
          borderRadius: "8px",
        },
      },
    },
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      autoHideDuration: null,
      disableWindowBlurListener: false,
      open: false,
      TransitionProps: {},
    },
  },
  MuiSnackbarContent: {
    defaultProps: {
      elevation: 3,
    },
    styleOverrides: {
      root: {
        backgroundColor: fleetPalette.primary?.dark,
        paddingTop: 7,
        paddingBottom: 7,
      },
      action: {
        paddingLeft: 24,
      },
    },
  },
  MuiBackdrop: {
    defaultProps: {
      invisible: false,
      open: false,
    },
  },
  MuiBadge: {
    defaultProps: {
      color: "primary",
    },
    styleOverrides: {
      colorError: {
        "&.MuiBadge-dot": {
          borderColor: fleetPalette.error?.contrastText,
          borderRadius: "50%",
          borderStyle: "solid",
          borderWidth: 2,
        },
      },
      colorInfo: {
        "&.MuiBadge-dot": {
          borderColor: fleetPalette.info?.contrastText,
          borderRadius: "50%",
          borderStyle: "solid",
          borderWidth: 2,
        },
      },
      colorPrimary: {
        "&.MuiBadge-dot": {
          borderColor: fleetPalette.primary?.contrastText,
          borderRadius: "50%",
          borderStyle: "solid",
          borderWidth: 2,
        },
      },
      colorSecondary: {
        "&.MuiBadge-dot": {
          borderColor: fleetPalette.secondary?.contrastText,
          borderRadius: "50%",
          borderStyle: "solid",
          borderWidth: 2,
        },
      },
      colorSuccess: {
        "&.MuiBadge-dot": {
          borderColor: fleetPalette.success?.contrastText,
          borderRadius: "50%",
          borderStyle: "solid",
          borderWidth: 2,
        },
      },
      colorWarning: {
        "&.MuiBadge-dot": {
          borderColor: fleetPalette.warning?.contrastText,
          borderRadius: "50%",
          borderStyle: "solid",
          borderWidth: 2,
        },
      },
      dot: {
        minHeight: "12px",
        minWidth: "12px",
        ".MuiBadge-root.xsmall &, .MuiBadge-root.small &, .MuiBadge-root.medium &, .MuiBadge-root.large &":
          {
            "&.MuiBadge-overlapRectangular": {
              transform: "scale(1)",
            },
          },
        "&.MuiBadge-anchorOriginTopRightRectangular": {
          top: "3%",
          right: "3%",
          ".MuiBadge-root.xsmall &": {
            top: -6,
            right: -6,
          },
          ".MuiBadge-root.small &": {
            top: -5,
            right: -5,
          },
          ".MuiBadge-root.medium &": {
            top: -4,
            right: -4,
          },
          ".MuiBadge-root.large &": {
            top: -3,
            right: -3,
          },
        },
        "&.MuiBadge-anchorOriginBottomRightRectangular": {
          right: "3%",
          bottom: "3%",
          ".MuiBadge-root.xsmall &": {
            right: -6,
            bottom: -6,
          },
          ".MuiBadge-root.small &": {
            right: -5,
            bottom: -5,
          },
          ".MuiBadge-root.medium &": {
            right: -4,
            bottom: -4,
          },
          ".MuiBadge-root.large &": {
            right: -3,
            bottom: -3,
          },
        },
        "&.MuiBadge-anchorOriginTopLeftRectangular": {
          top: "3%",
          left: "3%",
          ".MuiBadge-root.xsmall &": {
            top: -6,
            left: -6,
          },
          ".MuiBadge-root.small &": {
            top: -5,
            left: -5,
          },
          ".MuiBadge-root.medium &": {
            top: -4,
            left: -4,
          },
          ".MuiBadge-root.large &": {
            top: -3,
            left: -3,
          },
        },
        "&.MuiBadge-anchorOriginBottomLeftRectangular": {
          bottom: "3%",
          left: "3%",
          ".MuiBadge-root.xsmall &": {
            bottom: -6,
            left: -6,
          },
          ".MuiBadge-root.small &": {
            bottom: -5,
            left: -5,
          },
          ".MuiBadge-root.medium &": {
            bottom: -4,
            left: -4,
          },
          ".MuiBadge-root.large &": {
            bottom: -3,
            left: -3,
          },
        },
      },
      standard: {
        borderRadius: 4,
        "&.MuiBadge-overlapRectangular": {
          transform: "scale(1)",
          top: "initial",
          right: "initial",
          bottom: "initial",
          left: "initial",
        },
        "&.MuiBadge-anchorOriginTopRightRectangular": {
          bottom: "80%",
          left: "80%",
          ".MuiBadge-root.xsmall &": {
            bottom: "calc(100% - 6px)",
            left: "calc(100% - 6px)",
          },
          ".MuiBadge-root.small &": {
            bottom: "calc(100% - 8px)",
            left: "calc(100% - 8px)",
          },
          ".MuiBadge-root.medium &": {
            bottom: "calc(100% - 10px)",
            left: "calc(100% - 10px)",
          },
          ".MuiBadge-root.large &": {
            bottom: "calc(100% - 12px)",
            left: "calc(100% - 12px)",
          },
        },
        "&.MuiBadge-anchorOriginBottomRightRectangular": {
          top: "80%",
          left: "80%",
          ".MuiBadge-root.xsmall &": {
            top: "calc(100% - 6px)",
            left: "calc(100% - 6px)",
          },
          ".MuiBadge-root.small &": {
            top: "calc(100% - 8px)",
            left: "calc(100% - 8px)",
          },
          ".MuiBadge-root.medium &": {
            top: "calc(100% - 10px)",
            left: "calc(100% - 10px)",
          },
          ".MuiBadge-root.large &": {
            top: "calc(100% - 12px)",
            left: "calc(100% - 12px)",
          },
        },
        "&.MuiBadge-anchorOriginTopLeftRectangular": {
          right: "80%",
          bottom: "80%",
          ".MuiBadge-root.xsmall &": {
            right: "calc(100% - 6px)",
            bottom: "calc(100% - 6px)",
          },
          ".MuiBadge-root.small &": {
            right: "calc(100% - 8px)",
            bottom: "calc(100% - 8px)",
          },
          ".MuiBadge-root.medium &": {
            right: "calc(100% - 10px)",
            bottom: "calc(100% - 10px)",
          },
          ".MuiBadge-root.large &": {
            right: "calc(100% - 12px)",
            bottom: "calc(100% - 12px)",
          },
        },
        "&.MuiBadge-anchorOriginBottomLeftRectangular": {
          top: "80%",
          right: "80%",
          ".MuiBadge-root.xsmall &": {
            top: "calc(100% - 6px)",
            right: "calc(100% - 6px)",
          },
          ".MuiBadge-root.small &": {
            top: "calc(100% - 8px)",
            right: "calc(100% - 8px)",
          },
          ".MuiBadge-root.medium &": {
            top: "calc(100% - 10px)",
            right: "calc(100% - 10px)",
          },
          ".MuiBadge-root.large &": {
            top: "calc(100% - 12px)",
            right: "calc(100% - 12px)",
          },
        },
      },
    },
  },
  MuiBottomNavigation: {
    defaultProps: {
      showLabels: false,
    },
  },
  MuiBottomNavigationAction: {
    defaultProps: {
      showLabel: false,
    },
  },
  MuiButtonBase: {
    defaultProps: {
      centerRipple: false,
      disabled: false,
      disableRipple: true,
      disableTouchRipple: true,
      focusRipple: true,
      LinkComponent: "a",
    },
  },
  MuiButton: {
    defaultProps: {
      size: "medium",
    },
    styleOverrides: {
      sizeSmall: { ...fleetTypography.buttonSmall },
      sizeMedium: { ...fleetTypography.buttonMedium },
      sizeLarge: { ...fleetTypography.buttonLarge },
    },
  },
  MuiCard: {
    defaultProps: {
      raised: false,
    },
  },
  MuiCardActions: {
    defaultProps: {
      disableSpacing: false,
    },
    styleOverrides: {
      root: { paddingLeft: "16px", paddingRight: "16px" },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      disableTypography: false,
    },
  },
  MuiBreadcrumbs: {
    defaultProps: {
      expandText: "Show path",
      itemsAfterCollapse: 1,
      itemsBeforeCollapse: 1,
      maxItems: 8,
      separator: "/",
    },
    styleOverrides: {
      root: {
        color: fleetPalette.action?.active,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        "&:hover": {
          backgroundColor: fleetPalette.action?.hover,
        },
        "&.Mui-checked": {
          fill: fleetPalette.primary?.main,

          "&:hover": {
            backgroundColor: fleetPalette.primary?.outlinedHoverBackground,
          },
        },
        "&.Mui-disabled": {
          fill: fleetPalette.action?.disabledBackground,
        },
        "&.MuiCheckbox-colorWarning": {
          "&.Mui-checked": {
            fill: fleetPalette.warning?.main,
          },
          "&.Mui-disabled": {
            fill: fleetPalette.action?.disabledBackground,
          },
          "&:hover": {
            backgroundColor: fleetPalette.warning?.outlinedHoverBackground,
          },
        },
        "&.MuiCheckbox-colorSuccess": {
          "&.Mui-checked": {
            fill: fleetPalette.success?.main,
          },
          "&.Mui-disabled": {
            fill: fleetPalette.action?.disabledBackground,
          },
          "&:hover": {
            backgroundColor: fleetPalette.success?.outlinedHoverBackground,
          },
        },
        "&.MuiCheckbox-colorInfo": {
          "&.Mui-checked": {
            fill: fleetPalette.info?.main,
          },
          "&.Mui-disabled": {
            fill: fleetPalette.action?.disabledBackground,
          },
          "&:hover": {
            backgroundColor: fleetPalette.info?.outlinedHoverBackground,
          },
        },
        "&.MuiCheckbox-colorError": {
          "&.Mui-checked": {
            fill: fleetPalette.error?.main,
          },
          "&.Mui-disabled": {
            fill: fleetPalette.action?.disabledBackground,
          },
          "&:hover": {
            backgroundColor: fleetPalette.error?.outlinedHoverBackground,
          },
        },
      },
      colorSecondary: {
        "&.Mui-checked": {
          fill: fleetPalette.secondary?.main,
        },
        "&.Mui-disabled": {
          fill: fleetPalette.action?.disabledBackground,
        },
        "&:hover": {
          backgroundColor: fleetPalette.secondary?.outlinedHoverBackground,
        },
      },
    },
  },
  MuiDivider: {
    defaultProps: {
      absolute: false,
      flexItem: false,
      light: false,
      orientation: "horizontal",
      textAlign: "center",
      variant: "fullWidth",
    },
    styleOverrides: {
      root: {
        "&.MuiDivider-light": {
          "::before, ::after": {
            borderColor: fleetPalette.addition.filledInputBackground,
          },
        },
        "& .MuiDivider-wrapper": {
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
    },
    variants: [
      {
        props: { variant: "padded" },
        style: {
          marginLeft: "16px",
          marginRight: "16px",
        },
      },
    ],
  },
  MuiDrawer: {
    defaultProps: {
      anchor: "left",
      elevation: 2,
      hideBackdrop: false,
      ModalProps: {},
      open: false,
      PaperProps: {},
      variant: "temporary",
    },
  },
  MuiFab: {
    defaultProps: {
      color: "default",
      disabled: false,
      disableFocusRipple: true,
      disableRipple: true,
      disableTouchRipple: true,
      size: "medium",
      variant: "circular",
    },
    styleOverrides: {
      extended: {
        borderRadius: 10,
      },
    },
    variants: [
      {
        props: { variant: "circular", size: "large" },
        style: {
          borderRadius: 10,
          height: 56,
          padding: 16,
          width: 56,
        },
      },
      {
        props: { variant: "circular", size: "medium" },
        style: {
          borderRadius: 8,
          height: 44,
          padding: 12,
          width: 44,
        },
      },
      {
        props: { variant: "circular", size: "small" },
        style: {
          borderRadius: 6,
          height: 34,
          padding: 8,
          width: 34,
        },
      },
    ],
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: "inherit",
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        "&.MuiFormLabel-colorSecondary.Mui-focused": {
          color: fleetPalette.secondary?.dark,
        },
        "&.MuiFormLabel-colorWarning.Mui-focused": {
          color: fleetPalette.warning?.dark,
        },
        "&.MuiFormLabel-colorError.Mui-focused": {
          color: fleetPalette.error?.dark,
        },
        "&.MuiFormLabel-colorSuccess.Mui-focused": {
          color: fleetPalette.success?.dark,
        },
        "&.MuiFormLabel-colorInfo.Mui-focused": {
          color: fleetPalette.info?.dark,
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: fleetPalette.addition?.filledInputBackground,
        border: 2,
        borderStyle: "solid",
        borderRadius: 4,
        borderColor: fleetPalette.addition?.outlinedBorder,
        "&.Mui-focused": {
          borderColor: fleetPalette.primary?.main,
          backgroundColor: fleetPalette.addition?.filledInputBackground,
        },
        "&.Mui-error": {
          borderColor: fleetPalette.error?.main,
          "&:focus-within": {
            borderColor: fleetPalette.error?.dark,
          },
        },
        "&.MuiInputBase-colorError": {
          border: 2,
          borderStyle: "solid",
          borderRadius: 4,
          borderColor: fleetPalette.error?.main,
          "&:focus-within": {
            borderColor: fleetPalette.error?.dark,
          },
        },
        "&.MuiInputBase-colorWarning": {
          border: 2,
          borderStyle: "solid",
          borderRadius: 4,
          borderColor: fleetPalette.warning?.main,
          "&:focus-within": {
            borderColor: fleetPalette.warning?.dark,
          },
        },
        "&.MuiInputBase-colorSuccess": {
          border: 2,
          borderStyle: "solid",
          borderRadius: 4,
          borderColor: fleetPalette.success?.main,
          "&:focus-within": {
            borderColor: fleetPalette.success?.dark,
          },
        },
        "&.MuiInputBase-colorInfo": {
          backgroundColor: fleetPalette.addition?.filledInputBackground,
          border: 2,
          borderStyle: "solid",
          borderRadius: 4,
          borderColor: fleetPalette.info?.main,
          "&:focus-within": {
            borderColor: fleetPalette.info?.dark,
          },
        },
        "&.Mui-disabled": {
          borderColor: fleetPalette.action?.disabled,
          backgroundColor: fleetPalette.action?.disabledBackground,
        },
      },
      colorSecondary: {
        backgroundColor: fleetPalette.secondary?.outlinedHoverBackground,
        border: 2,
        borderStyle: "solid",
        borderRadius: 4,
        borderColor: fleetPalette.addition?.outlinedBorder,
        "&:focus-within": {
          borderColor: fleetPalette.secondary?.dark,
        },
      },
    },
  },
  MuiGrid: {
    defaultProps: {
      columns: 12,
      container: false,
      direction: "row",
      item: false,
      lg: false,
      md: false,
      sm: false,
      spacing: 0,
      wrap: "wrap",
      xl: false,
      xs: false,
      zeroMinWidth: false,
    },
  },
  MuiLink: {
    defaultProps: {
      color: "primary",
      underline: "always",
      variant: "inherit",
    },
    variants: [
      {
        props: { color: "primary" },
        style: {
          color: fleetPalette.primary?.dark,
        },
      },
    ],
    styleOverrides: {
      root: {
        "&.MuiLink-underlineAlways": {
          textDecorationColor: fleetPalette.primary?.dark,
        },
      },
    },
  },
  MuiImageList: {
    defaultProps: {
      cols: 2,
      gap: 4,
      rowHeight: "auto",
      variant: "standard",
    },
  },
  MuiImageListItem: {
    defaultProps: {
      cols: 1,
      rows: 1,
    },
  },
  MuiImageListItemBar: {
    defaultProps: {
      actionPosition: "right",
      position: "bottom",
    },
  },
  MuiList: {
    defaultProps: {
      dense: false,
      disablePadding: false,
    },
  },
  MuiListItemButton: {
    defaultProps: {
      alignItems: "center",
      autoFocus: false,
      dense: false,
      disabled: false,
      disableGutters: false,
      divider: false,
      selected: false,
    },
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: fleetPalette.action?.selected,
        },
        "&.Mui-selected:hover": {
          backgroundColor: fleetPalette.action?.hover,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        "&.MuiPaper-rounded": {
          borderRadius: "8px",
        },
      },
    },
    defaultProps: {
      elevation: 2,
      square: false,
      variant: "elevation",
    },
  },
  MuiPopover: {
    defaultProps: {
      anchorOrigin: { horizontal: "left", vertical: "top" },
      anchorReference: "anchorEl",
      elevation: 2,
      marginThreshold: 16,
      open: false,
      PaperProps: {},
      transformOrigin: { horizontal: "left", vertical: "top" },
      transitionDuration: "auto",
      TransitionProps: {},
    },
  },
  MuiRadio: {
    defaultProps: {
      color: "default",
    },
    styleOverrides: {
      root: {
        fill: fleetPalette.primary?.main,
        stroke: fleetPalette.addition.standardInputLine,
        "&.Mui-checked": {
          stroke: fleetPalette.primary?.main,
        },
        "&.Mui-disabled": {
          fill: fleetPalette.action?.disabled,
          stroke: fleetPalette.action?.disabled,
        },
        "&.MuiRadio-colorError": {
          fill: fleetPalette.error?.main,
          stroke: fleetPalette.addition.standardInputLine,
          "&.Mui-checked": {
            stroke: fleetPalette.error?.main,
          },
          "&.Mui-disabled": {
            fill: fleetPalette.action?.disabled,
            stroke: fleetPalette.action?.disabled,
          },
        },
        "&.MuiRadio-colorWarning": {
          fill: fleetPalette.warning?.main,
          stroke: fleetPalette.addition.standardInputLine,
          "&.Mui-checked": {
            stroke: fleetPalette.warning?.main,
          },
          "&.Mui-disabled": {
            fill: fleetPalette.action?.disabled,
            stroke: fleetPalette.action?.disabled,
          },
        },
        "&.MuiRadio-colorSuccess": {
          fill: fleetPalette.success?.main,
          stroke: fleetPalette.addition.standardInputLine,
          "&.Mui-checked": {
            stroke: fleetPalette.success?.main,
          },
          "&.Mui-disabled": {
            fill: fleetPalette.action?.disabled,
            stroke: fleetPalette.action?.disabled,
          },
        },
        "&.MuiRadio-colorInfo": {
          fill: fleetPalette.info?.main,
          stroke: fleetPalette.addition.standardInputLine,
          "&.Mui-checked": {
            stroke: fleetPalette.info?.main,
          },
          "&.Mui-disabled": {
            fill: fleetPalette.action?.disabled,
            stroke: fleetPalette.action?.disabled,
          },
        },
        "&.MuiRadio-colorDefault": {
          fill: fleetPalette.text?.secondary,
          "&.Mui-checked": {
            color: fleetPalette.text?.secondary,
            stroke: fleetPalette.text?.secondary,
          },
          "&.Mui-disabled": {
            fill: fleetPalette.action?.disabled,
            stroke: fleetPalette.action?.disabled,
          },
        },
      },
      colorSecondary: {
        fill: fleetPalette.secondary?.main,
        "&.Mui-checked": {
          color: fleetPalette.secondary?.dark,
          stroke: fleetPalette.secondary?.dark,
        },
        "&.Mui-disabled": {
          fill: fleetPalette.action?.disabled,
          stroke: fleetPalette.action?.disabled,
        },
      },
    },
  },
  MuiPagination: {
    defaultProps: {
      boundaryCount: 1,
      color: "standard",
      count: 1,
      defaultPage: 1,
      disabled: false,
      hideNextButton: false,
      hidePrevButton: false,
      shape: "circular",
      showFirstButton: false,
      showLastButton: false,
      siblingCount: 1,
      size: "medium",
      variant: "text",
    },
  },
  MuiPaginationItem: {
    defaultProps: {
      color: "standard",
      disabled: false,
      selected: false,
      shape: "circular",
      size: "medium",
      type: "page",
      variant: "text",
    },
  },
  MuiSkeleton: {
    defaultProps: {
      animation: "pulse",
      variant: "text",
    },
    styleOverrides: {
      root: {
        "&.MuiSkeleton-root": {
          backgroundColor: fleetPalette.background.skeletonBackground,
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        "&:focus": {
          backgroundColor: "initial",
        },
      },
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: "primary",
      disabled: false,
      disableRipple: true,
      edge: false,
      required: false,
      size: "medium",
    },
  },
  MuiSpeedDial: {
    defaultProps: {
      direction: "up",
      FabProps: {},
      hidden: false,
      open: false,
    },
    styleOverrides: {
      fab: {
        borderRadius: 10,
      },
    },
  },
  MuiSpeedDialAction: {
    defaultProps: {
      delay: 0,
      FabProps: {},
      open: false,
      tooltipOpen: false,
      tooltipPlacement: "left",
    },
  },
  MuiToggleButton: {
    defaultProps: {
      color: "standard",
      disabled: false,
      disableFocusRipple: true,
      disableRipple: true,
      fullWidth: false,
      selected: false,
      size: "medium",
    },
    styleOverrides: {
      root: {
        "&.MuiToggleButton-sizeSmall": {
          minWidth: 32,
          minHeight: 32,
          fontSize: "0.75rem",
          padding: 7,
          svg: {
            width: 16,
            height: 16,
          },
        },
        "&.MuiToggleButton-sizeMedium": {
          minWidth: 40,
          minHeight: 40,
          lineHeight: "initial",
          fontSize: "0.875rem",
          padding: 7,
          svg: {
            width: 24,
            height: 24,
          },
        },
        "&.MuiToggleButton-sizeLarge": {
          minWidth: 48,
          minHeight: 48,
          lineHeight: "initial",
          fontSize: "1rem",
          padding: 8,
          svg: {
            width: 28,
            height: 28,
          },
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        button: {
          color: fleetPalette.text?.primary,
        },
      },
    },
  },
  MuiTable: {
    defaultProps: {
      padding: "normal",
      size: "medium",
      stickyHeader: false,
    },
  },
  MuiTableCell: {
    defaultProps: {
      align: "inherit",
    },
  },
  MuiTablePagination: {
    defaultProps: {
      labelRowsPerPage: "Rows per page:",
      rowsPerPageOptions: [10, 20, 50, 100],
      SelectProps: {},
      showFirstButton: false,
      showLastButton: false,
    },
  },
  MuiTableRow: {
    defaultProps: {
      hover: false,
      selected: false,
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: false,
      components: {},
      componentsProps: {},
      describeChild: false,
      disableFocusListener: false,
      disableHoverListener: false,
      disableInteractive: false,
      disableTouchListener: false,
      enterDelay: 100,
      enterNextDelay: 0,
      enterTouchDelay: 700,
      followCursor: false,
      leaveDelay: 0,
      leaveTouchDelay: 1500,
      placement: "bottom",
      PopperProps: {},
    },
    styleOverrides: {
      tooltip: {
        backgroundColor: fleetPalette.background?.paper,
        color: fleetPalette.text?.primary,
        WebkitFilter:
          "drop-shadow(0px 1px 1px rgba(25, 32, 72, 0.2)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.31))",
        fontSize: "12px",
        lineHeight: "14px",
      },
      arrow: {
        color: fleetPalette.background?.paper,
      },
      tooltipPlacementLeft: {
        "& .MuiTooltip-arrow": {
          marginRight: "-0.6em!important",
        },
      },
      tooltipPlacementTop: {
        "& .MuiTooltip-arrow": {
          marginBottom: "-0.6em!important",
        },
      },
      tooltipPlacementRight: {
        "& .MuiTooltip-arrow": {
          marginLeft: "-0.6em!important",
        },
      },
      tooltipPlacementBottom: {
        "& .MuiTooltip-arrow": {
          marginTop: "-0.6em!important",
        },
      },
    },
  },
  MuiIcon: {
    defaultProps: {
      baseClassName: "material-icons-round",
      color: "inherit",
      fontSize: "medium",
    },
    styleOverrides: {
      root: {
        fontSize: "18px!important",
      },
      fontSizeSmall: {
        fontSize: "15px!important",
      },
      fontSizeLarge: {
        fontSize: "21px!important",
      },
      fontSizeInherit: {
        fontSize: "inherit!important",
      },
    },
    variants: [
      {
        props: { fontSize: "xsmall" },
        style: {
          fontSize: "12px!important",
        },
      },
    ],
  },
  MuiStack: {
    defaultProps: {
      direction: "column",
      spacing: 0,
    },
  },
  MuiContainer: {
    defaultProps: {
      disableGutters: false,
      fixed: false,
      maxWidth: "lg",
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorSecondary: {
        color: fleetPalette.secondary?.main,
      },
    },
    defaultProps: {
      color: "primary",
      disableShrink: false,
      size: 40,
      thickness: 3.6,
      value: 0,
      variant: "indeterminate",
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        "&.MuiLinearProgress-colorSecondary:not(.MuiLinearProgress-buffer)": {
          backgroundColor: fleetPalette.addition.deliver,
        },
        "& .MuiLinearProgress-colorSecondary": {
          backgroundColor: fleetPalette.addition.deliver,
        },
        "& .MuiLinearProgress-barColorSecondary": {
          backgroundColor: fleetPalette.secondary?.main,
        },
      },
    },
    defaultProps: {
      color: "primary",
      valueBuffer: 0,
      value: 0,
      variant: "indeterminate",
    },
  },
  MuiMenu: {
    defaultProps: {
      autoFocus: true,
      disableAutoFocusItem: false,
      MenuListProps: {},
      open: false,
      transitionDuration: "auto",
      TransitionProps: {},
      variant: "selectedMenu",
    },
    styleOverrides: {
      list: {
        "&.MuiList-padding": {
          paddingBottom: "4px",
          paddingTop: "4px",
        },
        // prevent collapse of first/last menu item margin
        "&:after": {
          content: '""',
          display: "table",
        },
        "&:before": {
          content: '""',
          display: "table",
        },
      },
    },
  },
  MuiMenuItem: {
    defaultProps: {
      autoFocus: false,
      dense: false,
      disableGutters: false,
      divider: false,
    },
    styleOverrides: {
      root: {
        lineHeight: 1.2,
        color: fleetPalette.text?.primary,
        "&.Mui-selected": {
          backgroundColor: fleetPalette.action?.selected,
        },
        "&.Mui-selected:hover": {
          backgroundColor: fleetPalette.action?.hover,
        },
        "&.Mui-focusVisible": {
          backgroundColor: fleetPalette.action?.focus,
        },
        "&.Mui-selected.Mui-focusVisible": {
          backgroundColor: fleetPalette.action?.focus,
        },
      },
      divider: {
        borderBottom: "none",
        "& + .MuiDivider-root": {
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      align: "inherit",
      gutterBottom: false,
      noWrap: false,
      paragraph: false,
      variant: "body1",
      variantMapping: TypographyVariantMapping,
    },
  },
  MuiModal: {
    defaultProps: {
      open: false,
      closeAfterTransition: false,
      components: {},
      componentsProps: {},
      disableAutoFocus: false,
      disableEnforceFocus: false,
      disableEscapeKeyDown: false,
      disablePortal: false,
      disableRestoreFocus: false,
      disableScrollLock: false,
      hideBackdrop: false,
      keepMounted: false,
    },
  },
  MuiAppBar: {
    defaultProps: {
      color: "primary",
      enableColorOnDark: false,
      position: "fixed",
    },
  },
  MuiDialog: {
    defaultProps: {
      open: false,
      disableEscapeKeyDown: false,
      fullScreen: false,
      fullWidth: false,
      maxWidth: "sm",
      scroll: "paper",
    },
    styleOverrides: {
      paperWidthMd: {
        maxWidth: 960,
      },
      paperWidthLg: {
        maxWidth: 1280,
      },
      paperWidthXl: {
        maxWidth: 1920,
      },
    },
  },
  MuiDialogActions: {
    defaultProps: {
      disableSpacing: false,
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        color: fleetPalette.text?.primary,
      },
    },
  },
  MuiDialogContent: {
    defaultProps: {
      dividers: false,
    },
    styleOverrides: {
      root: {
        paddingBottom: 8,
        ".MuiDialogTitle-root + &": {
          paddingTop: 8,
        },
        "&.MuiDialogContent-root": {
          padding: "5px 20px 20px 24px",
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontWeight: "bold",
        "&.MuiDialogTitle-root": {
          padding: "16px 16px 5px 24px",
        },
      },
    },
  },
  MuiAlert: {
    defaultProps: {
      closeText: "Close",
      role: "alert",
    },
    styleOverrides: {
      root: {
        "&.MuiPaper-rounded": {
          borderRadius: 4,
        },
      },
      icon: {
        alignItems: "center",
      },
      message: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        ...fleetTypography.subtitle2,
      },
      action: {
        alignItems: "center",
        paddingTop: 0,
        marginRight: 0,
      },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        margin: 0,
        ...fleetTypography.subtitle1,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          color: fleetPalette.text?.primary,
          fontWeight: 700,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        borderRadius: "4px 4px 0 0",
      },
      root: {
        "&.MuiTab-indicator": {
          "&__content": {
            ".MuiTab-root": {
              margin: "0 16px",
              padding: "12px 0",
            },
          },
          "&__full": {
            ".MuiTab-root": {
              margin: 0,
              padding: "12px 16px",
            },
          },
          "&__line": {
            ".MuiTabs-indicator": {
              height: "2px",
              borderRadius: 0,
            },
          },
          "&__standard": {
            ".MuiTabs-indicator": {
              height: "4px",
            },
          },
        },
        ".MuiTab-root": {
          minWidth: "auto",
        },
      },
      vertical: {
        "&.MuiTab-indicator": {
          "&__content": {
            ".MuiTab-root": {
              margin: "15px 16px",
              minHeight: "auto",
              padding: 0,
            },
          },
          "&__line": {
            ".MuiTabs-indicator": {
              width: "2px",
              borderRadius: 0,
            },
          },
          "&__standard": {
            ".MuiTabs-indicator": {
              width: "4px",
            },
          },
        },
        ".MuiTabs-indicator": {
          borderRadius: "4px 0 0 4px",
        },
        ".MuiTab-root": {
          padding: 0,
          "&.Mui-selected > .MuiBox-root": {
            paddingTop: 0,
          },
          "&.Mui-selected > .MuiBox-root:after": {
            marginTop: 0,
            width: 0,
          },
        },
      },
    },
  },
  MuiStepper: {
    defaultProps: {
      activeStep: 0,
      alternativeLabel: false,
      nonLinear: false,
      orientation: "horizontal",
    },
    styleOverrides: {
      vertical: {
        "&.MuiStepper-alternativeLabel": {
          alignItems: "center",
        },
      },
    },
  },
  MuiStep: {
    defaultProps: {
      /**
       * `false` is not used here, because in Mui Step component props are merged with default theme props
       * and there is check for undefined for active/completed/disabled
       * active/completed/disabled/last can be set based on context values passed from Stepper
       */
      active: undefined,
      completed: undefined,
      expanded: false,
      disabled: undefined,
      last: undefined,
    },
    styleOverrides: {
      vertical: {
        "&.MuiStep-alternativeLabel": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      vertical: {
        "&.MuiStepConnector-alternativeLabel": {
          position: "initial",
          marginLeft: 0,
          top: 0,
          left: 0,
          right: 0,
        },
      },
    },
  },
  MuiStepContent: {
    defaultProps: {
      transitionDuration: "auto",
    },
    styleOverrides: {
      root: {
        ".MuiStep-alternativeLabel &": {
          border: "none",
        },
      },
    },
  },
  MuiStepIcon: {
    defaultProps: {
      active: true,
      completed: true,
      error: true,
    },
  },
  MuiStepLabel: {
    defaultProps: {
      componentsProps: {},
      error: false,
    },
    styleOverrides: {
      root: {
        "&.MuiStepLabel-alternativeLabel": {
          ".MuiTypography-root": {
            textAlign: "center",
            display: "block",
          },
        },
      },
      label: {
        "&.Mui-active": {
          fontWeight: 600,
        },
        "&.Mui-completed": {
          fontWeight: 600,
        },
      },
    },
  },
  MuiChip: {
    defaultProps: {
      color: "default",
      variant: "filled",
      size: "medium",
    },
    styleOverrides: {
      root: {
        borderRadius: 4,
      },
      avatar: {
        fontSize: 14,
        fontWeight: 600,
        color: fleetPalette.common.white,
        borderRadius: "50%",
        backgroundColor: fleetPalette.text.primary,
        "&.MuiChip-avatarSmall": {
          fontSize: 10,
        },
        "&.MuiChip-avatarColorError": {
          backgroundColor: fleetPalette.error.dark,
        },
        "&.MuiChip-avatarColorWarning": {
          backgroundColor: fleetPalette.warning.dark,
        },
        "&.MuiChip-avatarColorInfo": {
          backgroundColor: fleetPalette.info.dark,
        },
        "&.MuiChip-avatarColorSuccess": {
          backgroundColor: fleetPalette.success.dark,
        },
      },
      avatarColorPrimary: {
        backgroundColor: fleetPalette.primary.dark,
      },
      avatarColorSecondary: {
        backgroundColor: fleetPalette.secondary.dark,
      },
      label: {
        ...fleetTypography.chip,
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        ...fleetTypography.helperText,
      },
    },
  },
  MuiRating: {
    styleOverrides: {
      iconActive: {
        transform: "scale(1.25)",
      },
    },
  },
};
