import { forwardRef } from 'react';

import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
} from '@mui/material';

export interface LinearProgressProps
  extends Omit<MuiLinearProgressProps, 'color'> {
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color?: 'primary' | 'secondary';
}

/** LinearProgress inform users about the status of ongoing processes, such as loading an app, submitting a form, or saving updates
 *
 * `import { LinearProgress } from '@fsp-io/shared-ui'`
 */

const LinearProgress = forwardRef<unknown, LinearProgressProps>(
  (props, ref) => {
    return <MuiLinearProgress {...props} ref={ref} />;
  },
);

LinearProgress.displayName = 'LinearProgress';

export default LinearProgress;
