import { forwardRef } from 'react';

import {
  ButtonGroup as MuiButtonGroup,
  ButtonGroupProps as MuiButtonGroupProps,
} from '@mui/material';

export type ButtonGroupProps = MuiButtonGroupProps

/** The buttons can be grouped by wrapping them with the ButtonGroup component. They need to be immediate children.
 *
 * `import { ButtonGroup } from '@fsp-io/shared-ui'`
 */

const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  (
    {
      color = 'primary',
      disabled = false,
      disableElevation = true,
      size = 'medium',
      variant = 'contained',
      orientation = 'horizontal',
      fullWidth = false,
      ...rest
    },
    ref,
  ) => {
    return (
      <MuiButtonGroup
        ref={ref}
        color={color}
        disabled={disabled}
        disableElevation={disableElevation}
        size={size}
        variant={variant}
        orientation={orientation}
        fullWidth={fullWidth}
        {...rest}
      />
    );
  },
);

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
