import React, { StrictMode } from 'react'

import { BrowserRouter } from 'react-router-dom'

import * as ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'

import AppRoutes from './app/AppRoutes'
import { client } from './graphql/client'
import { ThemeProvider } from './providers/ThemeProvider'
import NotificationProvider from './providers/NotificationProvider'
import { BreadcrumbsProvider } from './providers/BreadcrumbsProvider'
import { SnackbarProvider } from './providers/snackbar/SnackbarProvider'

import '@/config/i18n'
import '@fontsource/red-hat-display'
import '@fontsource/red-hat-display/300.css'
import '@fontsource/red-hat-display/500.css'
import '@fontsource/red-hat-display/600.css'
import '@fontsource/red-hat-display/700.css'
import '@fontsource/red-hat-display/800.css'
import '@fontsource/red-hat-display/900.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider>
        <SnackbarProvider>
          <BrowserRouter>
            <BreadcrumbsProvider>
              <NotificationProvider>
                <AppRoutes />
              </NotificationProvider>
            </BreadcrumbsProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  </StrictMode>
)
