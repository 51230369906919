import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { LogoProps } from './types';
import Box from '../box';
import Link from '../link';
import clsx from 'clsx';

import styles from './styles';

const baseClassName = 'logo';
const StyledLink = styled(Link)(styles.logo);
const StyledBox = styled(Box)(styles.logo);

/** Logo
 * 
 * `import { Logo } from '@fsp-io/shared-ui'`
 */
const Logo: FC<LogoProps> = ({
  src,
  alt = '',
  href,
  target,
  className,
  sx,
  ...rest
}) => {
  const attr = useMemo(() => (href ? { href, target } : {}), [href, target]);
  const LogoStyled = useMemo(() => (href ? StyledLink : StyledBox), [href]);

  return (
    <LogoStyled sx={sx} className={clsx(baseClassName, className)} {...attr}>
      <img src={src} alt={alt} role="img" {...rest} />
    </LogoStyled>
  );
};

export default Logo;
