import { forwardRef } from 'react'
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material'

export interface TooltipProps extends MuiTooltipProps {
  /**
   * Color sets the background-color and font color.
   */
  color?: 'white' | 'black'
}

/** Tooltip
 *
 * Tooltips display informative text when users hover over, focus on, or tap an element.
 *
 * `import { Tooltip } from '@fsp-io/shared-ui'`
 */

const Tooltip = forwardRef<unknown, TooltipProps>(
  ({ children, color = 'white', ...rest }, ref) => {
    const componentsOverrideProps: any = {
      ...rest.componentsProps,
      ...(color === 'black'
        ? {
            arrow: { sx: { color: '#000' } },
            tooltip: {
              sx: {
                backgroundColor: '#000',
                color: '#FFF',
                whiteSpace: 'nowrap',
                maxWidth: 'none',
                padding: '8px 12px',
              },
            },
          }
        : {
            tooltip: {
              sx: {
                backgroundColor: '#fff',
                color: '#000',
                whiteSpace: 'nowrap',
                maxWidth: 'none',
                padding: '8px 12px',
              },
            },
          }),
    }

    return (
      <MuiTooltip {...rest} componentsProps={componentsOverrideProps} ref={ref}>
        {children}
      </MuiTooltip>
    )
  }
)

Tooltip.displayName = 'Tooltip'

export default Tooltip
