import { forwardRef } from "react";

import MuiInputLabel, {
  InputLabelProps as MuiInputLabelProps,
} from "@mui/material/InputLabel";

export type InputLabelProps = MuiInputLabelProps

const InputLabel = forwardRef<HTMLLabelElement, InputLabelProps>(
  (props, ref) => {
    return <MuiInputLabel {...props} ref={ref} />;
  }
);

InputLabel.displayName = "InputLabel";

export default InputLabel;
