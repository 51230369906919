import { forwardRef } from 'react';

import {
  CardContent as MuiCardContent,
  CardContentProps as MuiCardContentProps,
} from '@mui/material';

export type CardContentProps = MuiCardContentProps

/** CardContent
 *
 * `import { CardContent } from '@fsp-io/shared-ui'`
 */

const CardContent = forwardRef<HTMLDivElement, CardContentProps>(
  (props, ref) => {
    return <MuiCardContent {...props} ref={ref} />;
  },
);

CardContent.displayName = 'CardContent';

export default CardContent;
