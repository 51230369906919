import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import Box from '../box';
import MetadataItem from '../meta-data-item';
import { MetadataProps } from './types';
import styles from './styles';

export const baseClassName = 'metadata';

const BoxStyled = styled(Box)(styles.container);

/**
 * Metadata is used to show a list of metadata.
 *
 * `import { Metadata } from '@fsp-io/shared-ui'`
 */
const Metadata = ({
  children,
  className = '',
  sx = {},
  ...rest
}: MetadataProps) => {
  return (
    <BoxStyled className={clsx(baseClassName, className)} sx={sx} {...rest}>
      {children}
    </BoxStyled>
  );
};

Metadata.Item = MetadataItem;

export default Metadata;
