import { forwardRef } from 'react';

import {
  Collapse as MuiCollapse,
  CollapseProps as MuiCollapseProps,
  collapseClasses,
} from '@mui/material';

export { collapseClasses };

export type CollapseProps = MuiCollapseProps

/** Collapse
 *
 * The Collapse transition is used by the Vertical Stepper StepContent component.
 * It uses [react-transition-group](https://github.com/reactjs/react-transition-group) internally.
 *
 *
 * `import { Collapse } from '@fsp-io/shared-ui'`
 */

const Collapse = forwardRef<unknown, CollapseProps>((props, ref) => {
  return <MuiCollapse {...props} ref={ref} />;
});

Collapse.displayName = 'Collapse';

export default Collapse;
