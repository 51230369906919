import countryCodeLookup from "country-code-lookup";

// Intention of this helper is transform any iso-alpha-x format to the one - iso-alpha-2 format (ISO 3166)
// iso-alpha-2 format is used inside FDS / CountryFlag pattern
export function unifyCountryCodeToISO2(iso: string | number) {
  let result = undefined;

  try {
    result = countryCodeLookup.byIso(iso)?.iso2;
  // eslint-disable-next-line no-empty
  } catch (_) {}

  return result;
}
