import React from 'react'

import { UserRoles } from '@/__generated__/graphql'
import { PATHS } from '@/constants/paths'

import AccessOutlet from './AccessOutlet'

const AdminPlusOutlet: React.FC = () => {
  return (
    <AccessOutlet
      accessRoles={[UserRoles.ADMIN]}
      defaultPath={PATHS.managerPlus.index}
    />
  )
}

export default React.memo(AdminPlusOutlet)
