import React, { Suspense } from 'react'

import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'

import { oktaAuthConfig } from '@/config/okta'
import {
  PATHS,
  ROUTE_CONSTANTS,
  createDescendantRoute,
} from '@/constants/paths'

import FullPageLoader from './components/full-page-loader'
import BootUpLayout from './components/layout/BootUpLayout'
import AuthRoutes from './pages/auth/AuthRoutes'
import AdminPlusRoutes from './pages/plus/admin/AdminPlusRoutes'
import ManagerPlusRoutes from './pages/plus/manager/ManagerPlusRoutes'
import SettingsRoutes from './pages/settings/SettingsRoutes'
import SitesRoutes from './pages/sites/SitesRoutes'
import SupportRoutes from './pages/support/SupportRoutes'
import UserRoutes from './pages/users/UserRoutes'
import SecuredRoute from './routes/SecuredRoute'

const AppRoutes: React.FC = () => {
  const navigate = useNavigate()
  const oktaAuth = new OktaAuth(oktaAuthConfig)

  const customAuthHandler = () => {
    navigate(PATHS.auth.login)
  }

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    })
  }

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          {/* Auth Routes */}
          <Route
            path={createDescendantRoute(PATHS.auth.index)}
            element={<AuthRoutes />}
          />

          {/* Protected Routes */}
          <Route
            path={PATHS.dashboard}
            element={
              <SecuredRoute>
                <BootUpLayout />
              </SecuredRoute>
            }
          >
            <Route
              path={createDescendantRoute(PATHS.adminPlus.index)}
              element={<AdminPlusRoutes />}
            />

            <Route
              path={createDescendantRoute(PATHS.managerPlus.index)}
              element={<ManagerPlusRoutes />}
            />

            <Route
              path={createDescendantRoute(PATHS.sites.index)}
              element={<SitesRoutes />}
            />

            <Route
              path={createDescendantRoute(ROUTE_CONSTANTS.users)}
              element={<UserRoutes />}
            />

            <Route
              path={createDescendantRoute(ROUTE_CONSTANTS.support)}
              element={<SupportRoutes />}
            />

            <Route
              path={createDescendantRoute(ROUTE_CONSTANTS.settings)}
              element={<SettingsRoutes />}
            />

            <Route
              path={PATHS.dashboard}
              element={<Navigate to={PATHS.adminPlus.index} replace />}
            />

            <Route
              path="*"
              element={<Navigate to={PATHS.dashboard} replace />}
            />
          </Route>
        </Routes>
      </Suspense>
    </Security>
  )
}

export default React.memo(AppRoutes)
