import React from 'react'

import { useLocation } from 'react-router-dom'

import { Box, Toolbar } from '@industrial-plus/shared-ui'
import { isPlusPath } from "@/app/pages/plus/header/verifyIfPlusPaths";
import { useCurrentUserQuery, UserRoles } from '@/__generated__/graphql'
import PlusSectionHeader from "@/app/pages/plus/header/PlusSectionHeader";

import { AppBar } from './styles'
import { TopBarBreadCrumbs } from '../breadcrumbs/topbar-breadcrumbs'
import { NotificationPopup } from './notification-popup/NotificationPopup'

interface Props {
  open: boolean
}

const Header: React.FC<Props> = (props: Props) => {
  const { open } = props

  const { data } = useCurrentUserQuery({
    context: {
      clientName: 'users',
    },
  })

  const { pathname } = useLocation()

  const userRole = data?.currentUser?.user?.role

  const isPlusRoute = isPlusPath(pathname)

  return (
    <AppBar elevation={1} position="absolute" open={open} data-testid="banner">
      <Toolbar sx={{ height: '100%' }}>
        <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
          {!isPlusRoute && <TopBarBreadCrumbs />}
        </Box>
        {/* Header Right Side Content */}
        {/* INFO: Hiding Header Buttons for now */}
        {/* <HeaderButtons /> */}
        {isPlusRoute && <PlusSectionHeader />}
        {userRole !== UserRoles.ADMIN && <NotificationPopup />}
      </Toolbar>
    </AppBar>
  )
}

export default React.memo(Header)
