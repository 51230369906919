import { forwardRef } from 'react';

import {
  ListSubheader as MuiListSubheader,
  ListSubheaderProps as MuiListSubheaderProps,
} from '@mui/material';

export type ListSubheaderProps = MuiListSubheaderProps

/** ListSubheader
 *
 * `import { ListSubheader } from '@fsp-io/shared-ui'`
 */

const ListSubheader = forwardRef<HTMLLIElement, ListSubheaderProps>(
  (props, ref) => {
    return <MuiListSubheader {...props} ref={ref} />;
  },
);

ListSubheader.displayName = 'ListSubheader';

export default ListSubheader;
