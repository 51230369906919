export const TERMS_OF_SERVICE_URI =
  'https://www.marioff.com/en/legal/terms-of-use'

export const PRIVACY_POLICY_URI =
  'https://abound.carrier.com/en/worldwide/privacy/'

export const LOGO_URI = 'https://www.marioff.com/'

const OKTA_DOMAIN = process.env['NX_OKTA_DOMAIN']
export const FORGOT_PASSWORD_LINK = `https://${OKTA_DOMAIN}/signin/forgot-password`

export const DEFAULT_LIMIT = 10
export const DEFAULT_UPPER_LIMIT = 10000
export const DEFAULT_PAGE_NO = 1

// Installation
export const MAX_INSTALLATION_DEVICES_PER_LOOP = 255
export const MAX_INSTALLATION_LOOP_PER_SYSTEM = 6

// Inspections
export const VIEW_TEMPLATE_PARAM = 'view-template'
