import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useTranslation } from 'react-i18next'

import {
  ClientDeviceType,
  useDeviceRegistrationMutation,
} from '@/__generated__/graphql'
import { useSnackbar } from '@/providers/snackbar/SnackbarProvider'

interface FCMTokenProviderProps {
  children: React.ReactNode
}

export type FCMTokenProviderContextType = {
  fcmToken: string | null
  setFcmToken: (token: string) => void
}

export const FCMTokenProviderContext =
  createContext<FCMTokenProviderContextType>({} as FCMTokenProviderContextType)

const FCMTokenProvider: React.FC<FCMTokenProviderProps> = (
  props: FCMTokenProviderProps
) => {
  const [fcmToken, setFcmToken] = useState<string | null>(null)
  const [deviceRegistrationDone, setDeviceRegistrationDone] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const [deviceRegistration] = useDeviceRegistrationMutation({
    context: {
      clientName: 'notifications',
    },
  })

  // Check if local storage already has the FCM token
  useEffect(() => {
    const fcmTokenFound = localStorage.getItem('fcmToken')

    if (fcmTokenFound) {
      setFcmToken(fcmTokenFound)
    }
  }, [])

  // If token is now found by any way, register the device with the server
  useEffect(() => {
    if (fcmToken && !deviceRegistrationDone) {
      // Saving Token in local storage
      localStorage.setItem('fcmToken', fcmToken)

      const registerDevice = async () => {
        try {
          await deviceRegistration({
            variables: {
              deviceDetails: {
                clientDeviceType: ClientDeviceType.Web,
                token: fcmToken!,
              },
            },
          })
          setDeviceRegistrationDone(true)
        } catch (e) {
          showSnackbar(
            t('components.notifications.notificationRegistrationFailed'),
            'error'
          )
        }
      }

      registerDevice()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceRegistration, fcmToken, deviceRegistrationDone])

  const memoizedSetFcmToken = useCallback((token: string) => {
    setFcmToken(token)
  }, [])

  // Memoize the context value
  const contextValue = useMemo(
    () => ({ fcmToken, setFcmToken: memoizedSetFcmToken }),
    [fcmToken, memoizedSetFcmToken]
  )

  return (
    <FCMTokenProviderContext.Provider value={contextValue}>
      {props.children}
    </FCMTokenProviderContext.Provider>
  )
}

export default React.memo(FCMTokenProvider)

export const useFCMTokenProviderContext = () =>
  useContext(FCMTokenProviderContext)
