import { styled } from '@mui/material/styles'
import { InputMaxHeightMap } from '../../utils/input.utils'
import Box from '../box'
import InputLabel from '../input-label'
import { ThemedWrapper } from './types'

interface BoxProps extends Omit<ThemedWrapper, 'theme'> {
  showLabel?: boolean
}

export const BoxStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'showLabel',
})<BoxProps>(({ theme, size = 'small', error, showLabel = false }) => {
  const inputHeightPx = InputMaxHeightMap[size]
  const flagDropdownWidthPx = 72

  return {
    '& .react-tel-input': {
      marginTop: showLabel ? '-0.625rem' : '0',

      '& .invalid-number-message': {
        position: 'absolute',
        bottom: '-18px',
        fontSize: '0.75rem',
        color: theme.palette.error.main,
      },
      '& .invalid-number': {
        border: `1px solid ${theme.palette.error.main} !important`,
      },
      '& .flag-dropdown': {
        height: `${inputHeightPx}px`,
        width: `${flagDropdownWidthPx}px`,
        backgroundColor: theme.palette.primary.contrastText,
        borderColor: error ? theme.palette.error.main : theme.palette.grey[400],
        borderRadius: '4px 0 0 4px',
        borderRight: 0,

        '&.open': {
          borderRadius: '4px 0 0 4px',
        },

        '& .selected-flag': {
          width: '80%',
          paddingLeft: '16px',
          paddingRight: '16px',
          display: 'inline-block',

          '&.open': {
            borderRadius: '4px 0 0 4px',
          },

          '& .arrow': {
            left: '38px',
            width: '6px',
            height: '8px',
            borderTopColor: theme.palette.action.active,
            '&.up': {
              top: '30%',
              borderBottomColor: theme.palette.action.active,
            },
          },
        },
      },
      '& .country-list': {
        borderRadius: 0,
      },
      '& .form-control': {
        borderRadius: '4px',
        borderColor: error ? theme.palette.error.main : theme.palette.grey[400],
        height: `${inputHeightPx}px`,
        width: '100%',
        paddingLeft: `${flagDropdownWidthPx}px`,
        fontSize: '17px',

        '&::placeholder': {
          fontSize: '15px',
        },
      },
    },
  }
})

export const StyledLabel = styled(InputLabel)`
  margin-bottom: 2px;
`
