import {
  CountryCode,
  isValidPhoneNumber as isValid,
} from 'libphonenumber-js';

import { Country } from '../types';

function isValidPhoneNumber() {
  return (value: string, country: object): boolean => {
    let result = false;

    try {
      const iso2 = (country as Country).iso2;
      const valueWithoutCountryCode = value.substring(
        (country as Country).dialCode.length,
      );

      if (valueWithoutCountryCode.length === 0) {
        // * INFO: Case when the input field is empty
        result = false;
      } else {
        // * INFO: Case when the input field is not empty, then checking validity
        result = isValid(
          valueWithoutCountryCode,
          iso2.toUpperCase() as CountryCode,
        );
      }
    } catch (e) {
      result = false;
    }

    return result;
  };
}

export default isValidPhoneNumber;
