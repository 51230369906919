import FullPageLoader from '@/app/components/full-page-loader'
import Layout from '@/app/components/layout/Layout'
import SiteAccessGuard from '@/app/guards/SiteAccessGuard'
import {
  LazyLoadedPageNotFound,
  LazyLoadedSitesList,
  LazyLoadedSitesOverview,
} from '@/app/routes/LazyLoadedRoutes'
import { ROUTE_CONSTANTS, createDescendantRoute } from '@/constants/paths'
import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

const SitesRoutes: React.FC = () => {
  return (
    <Layout>
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route index element={<LazyLoadedSitesList />} />
          <Route
            path={createDescendantRoute(ROUTE_CONSTANTS.siteId)}
            element={<SiteAccessGuard />}
          >
            <Route path="*" element={<LazyLoadedSitesOverview />} />
          </Route>
          <Route path="*" index element={<LazyLoadedPageNotFound />} />
        </Routes>
      </Suspense>
    </Layout>
  )
}

export default React.memo(SitesRoutes)
