import moment from 'moment';
import { PickerDateType } from './types';
import { StyledButton } from './styles';
import React, { useState } from 'react';

interface PresetsProps {
  onUpdateDate: (startDate: PickerDateType, endDate: PickerDateType) => void;
}
enum ButtonType {
  BUTTON1 = 0,
  BUTTON2 = 1,
  BUTTON3 = 2,
  BUTTON4 = 3,
  BUTTON5 = 4,
}

const RenderDatePresets: React.FC<PresetsProps> = (props: PresetsProps) => {
  const [isActive, setIsActive] = useState<ButtonType>();
  const onDatesChange = (
    startDate: PickerDateType,
    endDate: PickerDateType,
  ): void => {
    props.onUpdateDate(startDate, endDate);
  };

  const presets = [
    {
      text: '1 Week',
      start: moment().subtract(1, 'week').add(1, 'days'),
      end: moment(),
    },
    {
      text: '1 Month',
      start: moment().subtract(1, 'M').add(1, 'days'),
      end: moment(),
    },
    {
      text: '3 Months',
      start: moment().subtract(3, 'M').add(1, 'days'),
      end: moment(),
    },
    {
      text: '6 Months',
      start: moment().subtract(6, 'M').add(1, 'days'),
      end: moment(),
    },
    {
      text: '1 Year',
      start: moment().subtract(1, 'y').add(1, 'days'),
      end: moment(),
    },
  ];

  return (
    <div style={{ padding: '0 22px 11px 22px' }}>
      {presets.map(({ text, start, end }, id) => {
        return (
          <StyledButton
            key={text}
            type="button"
            isActive={isActive !== undefined && isActive === id}
            onClick={() => {
              setIsActive(id);
              return onDatesChange(start, end);
            }}
          >
            {text}
          </StyledButton>
        );
      })}
    </div>
  );
};

export default React.memo(RenderDatePresets);
