export const ROUTE_CONSTANTS = {
  auth: 'auth',
  login: 'login',
  callback: 'callback',
  logout: 'logout',
  forgotPassword: 'forgot-password',
  users: 'users',
  support: 'support',
  settings: 'settings',
  profile: 'profile',
  notifications: 'notifications',
  list: 'list',
  details: 'details',
  installation: 'installation',
  oktaID: ':oktaId',
  sites: 'sites',
  dashboard: 'dashboard',
  systems: 'systems',
  devices: 'devices',
  assignedUsers: 'assigned-users',
  events: 'events',
  alarms: 'alarms',
  incidents: 'incidents',
  walkTests: 'walk-tests',
  siteId: ':siteId',
  systemId: ':systemId',
  eventId: ':eventId',
  assignUsers: 'assign-users',
  history: 'history',
  managerPlus: 'manager+',
  inspections: 'inspections',
  reports: 'reports',
  tab: ':tab',
  templates: 'templates',
  create: 'create',
  all: 'all',
  analytics: 'system-analytics',
  adminPlus: 'admin+',
  master: 'master',
  assigned: 'assigned',
  archived: 'archived',
  assignTemplates: 'assign-templates',
  unassignTemplates: 'unassign-templates',
  templateId: ':templateId',
}

const BASE_PATHS = {
  auth: `/${ROUTE_CONSTANTS.auth}`,
  dashboard: '/',
  sites: `/${ROUTE_CONSTANTS.sites}`,
  managerPlus: `/${ROUTE_CONSTANTS.managerPlus}`,
  adminPlus: `/${ROUTE_CONSTANTS.adminPlus}`,
  systems: `/${ROUTE_CONSTANTS.systems}`,
  users: `/${ROUTE_CONSTANTS.users}`,
  support: `/${ROUTE_CONSTANTS.support}`,
  settings: `/${ROUTE_CONSTANTS.settings}`,
}

export const PATHS = {
  auth: {
    index: BASE_PATHS.auth,
    login: `${BASE_PATHS.auth}/${ROUTE_CONSTANTS.login}`,
    login_callback: `${BASE_PATHS.auth}/${ROUTE_CONSTANTS.login}/${ROUTE_CONSTANTS.callback}`,
    logout: `${BASE_PATHS.auth}/${ROUTE_CONSTANTS.logout}`,
    forgot_password: `${BASE_PATHS.auth}/${ROUTE_CONSTANTS.forgotPassword}`,
  },
  dashboard: BASE_PATHS.dashboard,
  adminPlus: {
    index: BASE_PATHS.adminPlus,
    masterTemplates: `${BASE_PATHS.adminPlus}/${ROUTE_CONSTANTS.templates}/${ROUTE_CONSTANTS.master}`,
    assignedTemplates: `${BASE_PATHS.adminPlus}/${ROUTE_CONSTANTS.templates}/${ROUTE_CONSTANTS.assigned}`,
    archivedTemplates: `${BASE_PATHS.adminPlus}/${ROUTE_CONSTANTS.templates}/${ROUTE_CONSTANTS.archived}`,

    //Assign Templates
    assignTemplates: `${BASE_PATHS.adminPlus}/${ROUTE_CONSTANTS.details}/${ROUTE_CONSTANTS.templateId}/${ROUTE_CONSTANTS.assignTemplates}`,
    unassignTemplates: `${BASE_PATHS.adminPlus}/${ROUTE_CONSTANTS.details}/${ROUTE_CONSTANTS.templateId}/${ROUTE_CONSTANTS.unassignTemplates}`,
  },
  managerPlus: {
    index: BASE_PATHS.managerPlus,
    tabs: `${BASE_PATHS.managerPlus}/${ROUTE_CONSTANTS.tab}`,
    dashboard: `${BASE_PATHS.managerPlus}/${ROUTE_CONSTANTS.dashboard}`,
    inspections: {
      index: `${BASE_PATHS.managerPlus}/${ROUTE_CONSTANTS.inspections}`,
      templates: `${BASE_PATHS.managerPlus}/${ROUTE_CONSTANTS.inspections}/${ROUTE_CONSTANTS.templates}`,
      create: `${BASE_PATHS.managerPlus}/${ROUTE_CONSTANTS.inspections}/${ROUTE_CONSTANTS.create}`,
      all: `${BASE_PATHS.managerPlus}/${ROUTE_CONSTANTS.inspections}/${ROUTE_CONSTANTS.all}`,
    },
    reports: `${BASE_PATHS.managerPlus}/${ROUTE_CONSTANTS.reports}`,
  },
  sites: {
    index: BASE_PATHS.sites,
    overview: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}`,
    siteDashboard: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.dashboard}`,
    siteSystems: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.systems}`,
    siteDevices: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.devices}`,
    siteAssignedUsers: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.assignedUsers}`,
    siteEvents: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.events}`,
    siteEventsHistory: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.history}/${ROUTE_CONSTANTS.events}/${ROUTE_CONSTANTS.eventId}`,
    siteAlarms: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.alarms}`,
    siteAlarmsHistory: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.history}/${ROUTE_CONSTANTS.alarms}/${ROUTE_CONSTANTS.eventId}`,
    siteIncidents: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.incidents}`,
    siteWalkTests: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.walkTests}`,
    siteSystemAnalytics: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.analytics}`,

    // System Details
    systemDetails: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.details}/${ROUTE_CONSTANTS.systems}/${ROUTE_CONSTANTS.systemId}`,

    // Installations page
    installationsPage: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.installation}/${ROUTE_CONSTANTS.systems}/${ROUTE_CONSTANTS.systemId}`,

    //Assign User
    assignUser: `${BASE_PATHS.sites}/${ROUTE_CONSTANTS.siteId}/${ROUTE_CONSTANTS.details}/${ROUTE_CONSTANTS.assignUsers}`,
  },
  users: {
    index: BASE_PATHS.users,
    list: `${BASE_PATHS.users}/${ROUTE_CONSTANTS.list}`,
    profile: `${BASE_PATHS.users}/${ROUTE_CONSTANTS.profile}`,
    details: `${BASE_PATHS.users}/${ROUTE_CONSTANTS.details}/${ROUTE_CONSTANTS.oktaID}`,
    notifications: `${BASE_PATHS.users}/${ROUTE_CONSTANTS.notifications}`,
  },
  support: {
    index: BASE_PATHS.support,
  },
  settings: {
    index: BASE_PATHS.settings,
  },
}

export const createDescendantRoute = (route: string) => `${route}/*`

export const getSiteDashboardPath = (siteId: string): string => {
  return PATHS.sites.siteDashboard.replace(ROUTE_CONSTANTS.siteId, `${siteId}`)
}

export const getSiteTabsPath = (id: string, tab: string): string => {
  return `${PATHS.sites.overview.replace(
    ROUTE_CONSTANTS.siteId,
    `${id}`
  )}/${tab}`
}

export const getManagerPlusTabsPath = (tab: string) => {
  return PATHS.managerPlus.tabs.replace(ROUTE_CONSTANTS.tab, tab)
}

export const getSystemDetailsPath = (
  siteId: string,
  systemId: string
): string => {
  return PATHS.sites.systemDetails
    .replace(ROUTE_CONSTANTS.siteId, siteId)
    .replace(ROUTE_CONSTANTS.systemId, systemId)
}

export const getInstallationPage = (
  siteId: string,
  systemId: string
): string => {
  return PATHS.sites.installationsPage
    .replace(ROUTE_CONSTANTS.siteId, siteId)
    .replace(ROUTE_CONSTANTS.systemId, systemId)
}

export const getUserDetailsPath = (oktaId: string) => {
  return PATHS.users.details.replace(ROUTE_CONSTANTS.oktaID, oktaId)
}

export const getSiteAssignUsersPath = (siteId: string) => {
  return PATHS.sites.assignUser.replace(ROUTE_CONSTANTS.siteId, siteId)
}

export const getSiteAlarmsPath = (siteId: string): string => {
  return PATHS.sites.siteAlarms.replace(ROUTE_CONSTANTS.siteId, siteId)
}

export const getSiteAlarmsHistoryPath = (
  siteId: string,
  eventId: string
): string => {
  return PATHS.sites.siteAlarmsHistory
    .replace(ROUTE_CONSTANTS.siteId, siteId)
    .replace(ROUTE_CONSTANTS.eventId, eventId)
}

export const getSiteEventsPath = (siteId: string): string => {
  return PATHS.sites.siteEvents.replace(ROUTE_CONSTANTS.siteId, siteId)
}

export const getSiteEventsHistoryPath = (
  siteId: string,
  eventId: string
): string => {
  return PATHS.sites.siteEventsHistory
    .replace(ROUTE_CONSTANTS.siteId, siteId)
    .replace(ROUTE_CONSTANTS.eventId, eventId)
}

export const getInspectionTabPaths = (tab: string): string => {
  return `${PATHS.managerPlus.inspections.index}/${tab}`
}

export const getAssignMasterTemplatePath = (templateId: string) => {
  return PATHS.adminPlus.assignTemplates.replace(
    ROUTE_CONSTANTS.templateId,
    templateId
  )
}

export const getUnAssignMasterTemplatePath = (templateId: string) => {
  return PATHS.adminPlus.unassignTemplates.replace(
    ROUTE_CONSTANTS.templateId,
    templateId
  )
}
