import clsx from 'clsx'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import RenderDatePresets from './presets'
import { DateRangeWrapper } from './styles'
import {
  DateRangeConfiguration,
  FocusInputState,
  OnDateSelectedType,
  PickerCalenderIconPosition,
  PickerDateType,
  PickerDisabled,
  PickerOpenDirection,
  PickerOrientation,
} from './types'

interface Props {
  value?: [number, number]
  onChange: (event: { type: OnDateSelectedType; payload?: Date[] }) => void
  config?: DateRangeConfiguration
}

// * INFO: Setting Default Configuration Props
const configDefaultProps: DateRangeConfiguration = {
  className: '',
  reopenPickerOnClearDates: true,
  showClearDates: true,
  disablePicker: false,
  showCalenderIcon: true,
  orientation: PickerOrientation.HORIZONTAL_ORIENTATION,
  openDirection: PickerOpenDirection.OPEN_DOWN,
  withFullScreenPortal: false,
  hideKeyboardShortcutsPanel: true,
  disableFuture: false,
  numberOfMonths: 2,
  displayFormat: 'DD/MM/YYYY',
  usePresets: false,
}

// * INFO: Defining IDs for start date input, end date input
const START_DATE_ID = 'date-picker-start-date'
const END_DATE_ID = 'date-picker-end-date'

const formatDate = (timestamp?: number): PickerDateType =>
  timestamp ? moment(timestamp) : null

const DateRange: React.FC<Props> = (props: Props) => {
  const [startDate, setStartDate] = useState(formatDate(props.value?.[0]))
  const [endDate, setEndDate] = useState(formatDate(props.value?.[1]))
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  )

  const configuration = useMemo(
    () => ({
      ...configDefaultProps,
      ...props.config,
    }),
    [props.config]
  )

  // * INFO: Function handling the complete date change events
  const handleDatesChange = useCallback(
    (arg: { startDate: PickerDateType; endDate: PickerDateType }) => {
      setStartDate(arg.startDate)
      setEndDate(arg.endDate)

      // Both startDate and endDate exists
      if (arg.startDate && arg.endDate) {
        return props.onChange({
          type: OnDateSelectedType.DATE_SELECTED,
          payload: [arg.startDate.toDate(), arg.endDate.toDate()],
        })
      }

      // Only one of them (startDate or endDate) exists
      const startDateInput = document.getElementById(
        START_DATE_ID
      ) as HTMLInputElement
      const endDateInput = document.getElementById(
        END_DATE_ID
      ) as HTMLInputElement

      // If someone enters date prior to minimum date
      if (
        configuration.minDateForData &&
        startDateInput &&
        moment(startDateInput.value) < moment(configuration.minDateForData)
      ) {
        return props.onChange({
          type: OnDateSelectedType.PRIOR_DATE_SELECTED,
        })
      }

      // If someone enters date future to maximum date
      if (
        configuration.disableFuture &&
        endDateInput &&
        moment(endDateInput.value) > moment()
      ) {
        return props.onChange({
          type: OnDateSelectedType.FUTURE_DATE_SELECTED,
        })
      }

      // No Date selected
      if (!arg.startDate || !arg.endDate) {
        return props.onChange({
          type: OnDateSelectedType.NO_DATE_SELECTED,
        })
      }
    },
    [configuration.minDateForData, configuration.disableFuture, props]
  )

  // * INFO: Function to disable outside range date selection
  const isOutsideRange = (day: any) => {
    if (configuration.minDateForData && configuration.disableFuture) {
      return (
        day.isBefore(moment(configuration.minDateForData)) ||
        day.isAfter(moment())
      )
    }

    if (configuration.minDateForData) {
      return day.isBefore(moment(configuration.minDateForData))
    }

    if (configuration.disableFuture) {
      return day.isAfter(moment())
    }

    return false
  }

  // * INFO: Function to handle disabled nature of Date Range Picker
  const disabled = useMemo(() => {
    if (configuration.disablePicker === PickerDisabled.START_DATE) {
      return PickerDisabled.START_DATE
    }

    if (configuration.disablePicker === PickerDisabled.END_DATE) {
      return PickerDisabled.END_DATE
    }

    return !!configuration.disablePicker
  }, [configuration.disablePicker])

  // * INFO: Function to handle calendar icon of Date Range Picker
  const showCalenderIcon = useMemo(() => {
    if (!configuration.showCalenderIcon) {
      return false
    }

    return !(startDate !== null && endDate !== null)
  }, [configuration.showCalenderIcon, endDate, startDate])

  // * INFO: Function to handle which month will be initially visible when calendar opens
  const initialVisibleMonth = useCallback(() => {
    if (startDate === null && endDate === null) {
      return moment().subtract(1, 'month')
    }

    // Either startDate or endDate is present, and we have the focus on at least one input
    if (focusedInput === FocusInputState.START_DATE) {
      if (startDate) {
        return startDate
      }
      return endDate!
    }

    // We have focus on endDate, and data is present on at least one input
    if (endDate) {
      return endDate
    }

    return startDate!
  }, [startDate, endDate, focusedInput])

  const updateDate = useCallback(
    (startDate: PickerDateType, endDate: PickerDateType): void => {
      setFocusedInput(FocusInputState.START_DATE)
      handleDatesChange({ startDate, endDate })
    },
    [setFocusedInput, handleDatesChange]
  )

  return (
    <DateRangeWrapper className={clsx(configuration.className)}>
      <DateRangePicker
        isOutsideRange={isOutsideRange}
        startDate={startDate}
        startDateId={START_DATE_ID}
        endDate={endDate}
        endDateId={END_DATE_ID}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focus) => setFocusedInput(focus)}
        reopenPickerOnClearDates={configuration.reopenPickerOnClearDates}
        showClearDates={configuration.showClearDates}
        disabled={disabled}
        displayFormat={configuration.displayFormat}
        showDefaultInputIcon={showCalenderIcon}
        orientation={configuration.orientation}
        openDirection={configuration.openDirection}
        withFullScreenPortal={configuration.withFullScreenPortal}
        hideKeyboardShortcutsPanel={configuration.hideKeyboardShortcutsPanel}
        startDatePlaceholderText={configuration.startDatePlaceholderText}
        endDatePlaceholderText={configuration.endDatePlaceholderText}
        numberOfMonths={configuration.numberOfMonths}
        renderCalendarInfo={
          configuration.usePresets
            ? () => <RenderDatePresets onUpdateDate={updateDate} />
            : configuration.renderCalendarInfo
        }
        onClose={configuration.onClose}
        inputIconPosition={PickerCalenderIconPosition.ICON_AFTER_POSITION}
        noBorder
        initialVisibleMonth={initialVisibleMonth}
        small
      />
    </DateRangeWrapper>
  )
}

DateRange.defaultProps = {
  config: {},
}

export default React.memo(DateRange)
