import { forwardRef } from 'react';

import {
  ListItemSecondaryAction as MuiListItemSecondaryAction,
  ListItemSecondaryActionProps as MuiListItemSecondaryActionProps,
} from '@mui/material';

export type ListItemSecondaryActionProps = MuiListItemSecondaryActionProps

/** ListItemSecondaryAction
 *
 * `import { ListItemSecondaryAction } from '@fsp-io/shared-ui'`
 */

const ListItemSecondaryAction = forwardRef<
  HTMLDivElement,
  ListItemSecondaryActionProps
>((props, ref) => {
  return <MuiListItemSecondaryAction {...props} ref={ref} />;
});

ListItemSecondaryAction.displayName = 'ListItemSecondaryAction';

export default ListItemSecondaryAction;
