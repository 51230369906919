import { TypographyOptions } from '@industrial-plus/shared-ui/theme';

export const FONT_FAMILY = ['Red Hat Display', 'sans-serif'].join(', ');

const TYPOGRAPHY: TypographyOptions = {
  htmlFontSize: 10,
  fontFamily: FONT_FAMILY,
  h1: {
    fontFamily: FONT_FAMILY,
    fontSize: 96,
    fontWeight: 800,
    lineHeight: '131px',
    letterSpacing: '-1.5px',
  },
  h2: {
    fontFamily: FONT_FAMILY,
    fontSize: 60,
    fontWeight: 800,
    lineHeight: '82px',
    letterSpacing: '-0.5px',
  },
  h3: {
    fontFamily: FONT_FAMILY,
    fontSize: 48,
    fontWeight: 700,
    lineHeight: '65px',
    letterSpacing: '-0.25px',
  },
  h4: {
    fontFamily: FONT_FAMILY,
    fontSize: 34,
    fontWeight: 700,
    lineHeight: '46px',
    letterSpacing: '-0.15px',
  },
  h5: {
    fontFamily: FONT_FAMILY,
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '33px',
  },
  h6: {
    fontFamily: FONT_FAMILY,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '27px',
  },
  body1: {
    fontFamily: FONT_FAMILY,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },
  body2: {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
  },
  body3: {
    fontFamily: FONT_FAMILY,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '14px',
  },
  subtitle1: {
    fontFamily: FONT_FAMILY,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
  },
  subtitle2: {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '16px',
  },
  buttonLarge: {
    fontFamily: FONT_FAMILY,
    fontSize: 15,
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '0.25px',
    textTransform: 'capitalize',
  },
  buttonMedium: {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.25px',
    textTransform: 'capitalize',
  },
  buttonSmall: {
    fontFamily: FONT_FAMILY,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0.25px',
    textTransform: 'capitalize',
  },
  caption: {
    fontFamily: FONT_FAMILY,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0.25px',
  },
  overline: {
    fontFamily: FONT_FAMILY,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  avatarLetter: {
    fontFamily: FONT_FAMILY,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },
  inputText1: {
    fontFamily: FONT_FAMILY,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
  },
  inputText2: {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  helperText: {
    fontFamily: FONT_FAMILY,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  alertTitle: {
    fontFamily: FONT_FAMILY,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '19px',
    letterSpacing: '0.1px',
  },
  tableHeader1: {
    fontFamily: FONT_FAMILY,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '19px',
    letterSpacing: '0.25px',
  },
  tableHeader2: {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '19px',
    letterSpacing: '0.25px',
  },
  chip: {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
  },
  inputLabel: {
    fontFamily: FONT_FAMILY,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '12px',
  },
  badge: {
    fontFamily: FONT_FAMILY,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '14px',
  },
  tooltip: {
    fontFamily: FONT_FAMILY,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '14px',
    letterSpacing: '0.25px',
  },
};

export default TYPOGRAPHY;
