import { forwardRef } from 'react';

import { Menu as MuiMenu, MenuProps as MuiMenuProps } from '@mui/material';

export type MenuProps = MuiMenuProps

/**
 *  A Menu displays a list of choices on a temporary surface. It appears when the user interacts with a button, or other control.
 *
 * `import { Menu } from '@fsp-io/shared-ui'`
 */

const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ elevation = 2, ...rest }, ref) => {
    return <MuiMenu elevation={elevation} {...rest} ref={ref} />;
  },
);

Menu.displayName = 'Menu';

export default Menu;
