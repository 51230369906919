import React from 'react';
import { styled } from '@mui/system';

import Link from '../../link';
import { EntryLinkProps } from './types';

import styles from './styles';

const LinkStyled = styled(Link)(styles.link);

export default function EntryLink({ label, onClick, sx }: EntryLinkProps) {
  return (
    <LinkStyled onClick={onClick} sx={sx} variant="subtitle2">
      {label}
    </LinkStyled>
  );
}
