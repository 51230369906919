import { styled } from '@mui/material/styles';

export const SnackbarContainer = styled('div')`
  & .SnackbarContainer-root {
    & .SnackbarItem-variantSuccess {
      background-color: success;
    }
    & .SnackbarItem-variantError {
      background-color: error;
    }
    & .SnackbarItem-variantInfo {
      background-color: info;
    }
    & .SnackbarItem-variantWarning {
      background-color: warning;
    }
  }
`;
