import { Moment } from 'moment';

// * INFO: Types file for Date Range Picker  

export type PickerDateType = Moment | null;

export enum FocusInputState {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export enum PickerDisabled {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export enum PickerOrientation {
  HORIZONTAL_ORIENTATION = 'horizontal',
  VERTICAL_ORIENTATION = 'vertical',
}

export enum PickerOpenDirection {
  OPEN_DOWN = 'down',
  OPEN_UP = 'up',
}

export enum PickerCalenderIconPosition {
  ICON_BEFORE_POSITION = 'before',
  ICON_AFTER_POSITION = 'after',
}

export interface DateRangeConfiguration {
  className?: string;
  reopenPickerOnClearDates?: boolean;
  showClearDates?: boolean;
  startDatePlaceholderText?: string;
  endDatePlaceholderText?: string;
  disablePicker?: boolean | PickerDisabled;
  showCalenderIcon?: boolean;
  orientation?: PickerOrientation;
  openDirection?: PickerOpenDirection;
  withFullScreenPortal?: boolean;
  numberOfMonths?: number;
  renderCalendarInfo?: () => React.ReactNode | null | undefined;
  hideKeyboardShortcutsPanel?: boolean;
  onClose?: (final: {
    startDate: PickerDateType;
    endDate: PickerDateType;
  }) => void;
  disableFuture?: boolean;
  minDateForData?: Date;
  displayFormat?: string;
  usePresets?: boolean;
}

export enum OnDateSelectedType {
  NO_DATE_SELECTED = 1,
  PRIOR_DATE_SELECTED,
  FUTURE_DATE_SELECTED,
  DATE_SELECTED,
}