import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import enUSTranslations from '@/assets/locales/en-US/translations'

const resources = {
  enUS: {
    translation: enUSTranslations,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'enUS',
    debug: false,
    resources,
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    preload: ['enUS'],
    detection: {
      order: ['navigator'],
    },
  })

export { i18n }
