import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  MenuItem,
  Grid,
  Divider,
  Typography,
  Select,
} from '@industrial-plus/shared-ui'
import { useTheme, Popover, TextField } from '@mui/material'
import { FormikHelpers, useFormik } from 'formik'
import * as Yup from 'yup'

import { FilterValues } from './NotificationInterfaces'
import {
  StyledInputLabel,
  StyledDateLabel,
  StyledTitleBox,
  StyledButtonsContainer,
} from './NotificationPopupStyled'
import ICSDatePicker from '../../date-picker/ICSDatePicker'

export interface NotificationPopupFilterProps {
  open: boolean
  handleClose: () => void
  anchorEl: null | HTMLElement
  businessUnits: (string | undefined)[]
  types: (string | undefined)[]
  sites: (string | null | undefined)[]
  handleFilter: (input: FilterValues) => void
}

export const NotificationPopupFilter: React.FC<NotificationPopupFilterProps> = (
  props
) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const handleOnSubmit = (
    values: FilterValues,
    formikHelpers: FormikHelpers<FilterValues>
  ) => {
    props.handleFilter(values)
  }

  const validationSchema = Yup.object().shape({
    from: Yup.date()
      .nullable()
      .test(
        'is-less',
        t('users.profile.notifications.filter.fromDateLessThanTo'),
        function (value, { parent }) {
          const { to } = parent
          if (to && value) {
            return value <= to
          }
          return true
        }
      ),
    to: Yup.date()
      .nullable()
      .test(
        'is-greater',
        t('users.profile.notifications.filter.toDateGreaterThanFrom'),
        function (value, { parent }) {
          const { from } = parent
          if (from && value) {
            return value >= from
          }
          return true
        }
      ),
  })

  const handleReset = (formik: FormikHelpers<FilterValues>) => () => {
    formik.resetForm()
    formik.submitForm()
  }

  const formik = useFormik({
    initialValues: {
      businessUnit: '',
      type: '',
      siteName: '',
      from: null as Date | null,
      to: null as Date | null,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleOnSubmit(formik.values, formik)
      props.handleClose()
    },
  })

  return (
    <Popover
      open={props.open}
      onClose={props.handleClose}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <StyledTitleBox>
        <Typography variant="subtitle1" sx={{ padding: '1rem' }}>
          {t('common.filter')}
        </Typography>
      </StyledTitleBox>
      <Divider sx={{ width: '90%', margin: '0 auto' }} />
      <Box sx={{ padding: 2, width: '21rem' }}>
        <form
          id="filter"
          onSubmit={formik.handleSubmit}
          onReset={formik.handleReset}
        >
          <StyledInputLabel htmlFor="businessUnit">
            {t('common.businessUnitIndex')}
          </StyledInputLabel>
          <Select
            name="businessUnit"
            size="xsmall"
            value={formik.values.businessUnit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            disableUnderline
            showBorder
            error={!!formik.errors.businessUnit && formik.touched.businessUnit}
            autoComplete="off"
            sx={{
              marginBottom: '1rem',
              backgroundColor: theme.colors.gray100,
            }}
          >
            {props.businessUnits.map((option) => (
              <MenuItem key={option} value={option}>
                <Typography sx={{ fontSize: '0.75rem' }}>{option}</Typography>
              </MenuItem>
            ))}
          </Select>
          <StyledInputLabel htmlFor="type">
            {t('users.profile.notifications.filter.notificationType')}
          </StyledInputLabel>
          <Select
            name="type"
            size="xsmall"
            value={formik.values.type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            disableUnderline
            showBorder
            error={!!formik.errors.type && formik.touched.type}
            autoComplete="off"
            sx={{
              marginBottom: '1rem',
              backgroundColor: theme.colors.gray100,
            }}
          >
            {props.types.map((option) => (
              <MenuItem key={option} value={option}>
                <Typography sx={{ fontSize: '0.75rem' }}>{option}</Typography>
              </MenuItem>
            ))}
          </Select>
          <StyledInputLabel htmlFor="siteName">
            {t('common.names.site')}
          </StyledInputLabel>
          <Select
            name="siteName"
            size="xsmall"
            value={formik.values.siteName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            disableUnderline
            showBorder
            error={!!formik.errors.siteName && formik.touched.siteName}
            autoComplete="off"
            sx={{
              marginBottom: '1rem',
              backgroundColor: theme.colors.gray100,
            }}
          >
            {props.sites.map((option) => (
              <MenuItem key={option} value={option ?? ''}>
                <Typography sx={{ fontSize: '0.75rem' }}>{option}</Typography>
              </MenuItem>
            ))}
          </Select>
          <Grid container>
            <Grid item xs={5}>
              <StyledDateLabel htmlFor="from">
                {t('common.from')}
              </StyledDateLabel>
              <ICSDatePicker
                value={formik.values.from}
                onChange={(date) => {
                  if (date && date instanceof Date) {
                    const updatedDate = new Date(date)
                    updatedDate.setHours(0, 0, 0, 0)

                    formik.setFieldValue('from', updatedDate.toISOString())
                  } else {
                    formik.setFieldValue('from', null)
                  }
                }}
                onOpen={() => formik.setFieldValue('from', null)}
                errorText={
                  formik.touched.from && typeof formik.errors.from === 'string'
                    ? formik.errors.from
                    : ''
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Divider orientation="horizontal" sx={{ width: '1rem' }} />
            </Grid>
            <Grid item xs={5}>
              <StyledDateLabel htmlFor="to">{t('common.to')}</StyledDateLabel>
              <ICSDatePicker
                value={formik.values.to}
                onChange={(date) => {
                  if (date && date instanceof Date) {
                    const updatedDate = new Date(date)
                    updatedDate.setHours(23, 59, 59, 59)

                    formik.setFieldValue('to', updatedDate.toISOString())
                  } else {
                    formik.setFieldValue('to', null)
                  }
                }}
                onOpen={() => formik.setFieldValue('to', null)}
                errorText={
                  formik.touched.from && typeof formik.errors.from === 'string'
                    ? formik.errors.from
                    : ''
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <Divider sx={{ width: '90%', margin: '0 auto' }} />
      <StyledButtonsContainer>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleReset(formik)}
        >
          {t('users.profile.notifications.filter.clearFilter')}
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          form="filter"
          sx={{ backgroundColor: theme.colors.primary600 }}
        >
          {t('common.apply')}
        </Button>
      </StyledButtonsContainer>
    </Popover>
  )
}
