import { forwardRef } from 'react';

import {
  DialogContentText as MuiDialogContentText,
  DialogContentTextProps as MuiDialogContentTextProps,
} from '@mui/material';

export type DialogContentTextProps = MuiDialogContentTextProps

/** DialogContentText
 *
 * DialogContentText must be used a child component of the DialogContent. It is used to display Text within the Dialog Content Wrapper.
 *
 * `import { DialogContentText } from '@fsp-io/shared-ui'`
 */

const DialogContentText = forwardRef<HTMLSpanElement, DialogContentTextProps>(
  (props, ref) => {
    return <MuiDialogContentText {...props} ref={ref} />;
  },
);

DialogContentText.displayName = 'DialogContentText';

export default DialogContentText;
