import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Box from '../box';
import { Orientation, Position, StatusType } from '../../types/common';
import { StatusIndicatorProps } from './types';

import styles from './styles';
import { getStatusColor } from '../../utils/getStatusColor.utils';

const StatusIndicatorStyled = styled(Box)(styles.indicator);

const baseClassName = 'status-indicator';

/** StatusIndicator
 *
 * The StatusIndicator pattern is a visual cue intended to attract the user’s attention and communicate severity level information.
 *
 * `import { StatusIndicator } from '@fsp-io/shared-ui`
 */

const StatusIndicator: FC<StatusIndicatorProps> = ({
  sx,
  className,
  type = StatusType.Primary,
  customBgColor = '',
  orientation = Orientation.Vertical,
  alignment = Position.Right,
  ...rest
}) => {
  const bgColor = customBgColor || getStatusColor(type);
  return (
    <StatusIndicatorStyled
      role="status"
      type={type}
      alignment={alignment}
      custombgcolor={bgColor}
      orientation={orientation}
      className={clsx(
        baseClassName,
        className,
        `${baseClassName}--${orientation}`,
        `align--${alignment}`,
        {
          [`${baseClassName}--${type}`]: customBgColor === '' && type,
        },
      )}
      sx={sx}
      {...rest}
    />
  );
};

export default StatusIndicator;
