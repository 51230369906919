import isValidPhoneNumber from "./isValidPhoneNumber";
import { ResultOfValidateHandlerType } from "../types";

const validator = isValidPhoneNumber();

const getResultOfValidateHandler: ResultOfValidateHandlerType = (
  isValid,
  valueArg,
  countryArg,
  ...rest
) => {
  if (isValid === undefined || isValid === null) {
    return validator(valueArg, countryArg);
  }

  return typeof isValid === "boolean"
    ? isValid
    : isValid(valueArg, countryArg, ...rest);
};

export default getResultOfValidateHandler;
