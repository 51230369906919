import React, { ReactNode } from 'react'

import { IconButton } from '@industrial-plus/shared-ui'
import CloseIcon from '@mui/icons-material/Close'
import {
  SnackbarProvider as Notistack,
  useSnackbar as useNotistack,
  VariantType,
} from 'notistack'

import { SnackbarContainer } from './SnackbarProvider.styles'

export interface CustomActionButtonProps {
  onClickAction: () => void
  label?: string
  executeDirectly?: boolean
}

interface SnackbarProviderProps {
  children: ReactNode
}

const CustomCloseButton = () => {
  const { closeSnackbar } = useNotistack()
  return (
    <IconButton
      onClick={() => closeSnackbar()}
      color="inherit"
      size="small"
      style={{ marginRight: '3px' }}
    >
      <CloseIcon style={{ fontSize: '20px' }} />
    </IconButton>
  )
}

const CustomActionButton = ({
  onClickAction,
  label = 'Retry',
}: CustomActionButtonProps) => {
  const { closeSnackbar } = useNotistack()

  const handleAction = () => {
    closeSnackbar()
    onClickAction()
  }

  return (
    <IconButton
      onClick={handleAction}
      color="inherit"
      size="small"
      style={{ fontSize: '14px', marginRight: '10px' }}
    >
      {label}
    </IconButton>
  )
}

export function SnackbarProvider({
  children,
}: SnackbarProviderProps): JSX.Element {
  return (
    <SnackbarContainer>
      <Notistack
        action={<CustomCloseButton />}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        autoHideDuration={5000}
        maxSnack={3}
      >
        {children}
      </Notistack>
    </SnackbarContainer>
  )
}

interface UseSnackbar {
  closeSnackbar: () => void
  showSnackbar: (
    message: string,
    variant: VariantType,
    action?: CustomActionButtonProps | undefined
  ) => void
}

export function useSnackbar(): UseSnackbar {
  const { enqueueSnackbar, closeSnackbar } = useNotistack()

  const handleCloseSnackbar = () => {
    closeSnackbar()
  }

  const handleShowSnackbar = (
    message: string,
    variant: VariantType,
    action?: CustomActionButtonProps
  ) => {
    enqueueSnackbar(message, {
      action:
        action && !action.executeDirectly ? (
          <CustomActionButton {...action} />
        ) : null,
      persist: variant === 'error' ? true : false,
      variant,
      style: { whiteSpace: 'pre-line' },
    })

    if (action && action.executeDirectly) {
      action.onClickAction()
    }
  }

  return {
    closeSnackbar: handleCloseSnackbar,
    showSnackbar: handleShowSnackbar,
  }
}
