import { ThemedStyledComponent } from "../types";

export default {
  downloadLink: {
    padding: "6px 16px",
  },
  downloadButton: ({ theme }: ThemedStyledComponent) => ({
    marginTop: theme?.spacing(2),
    padding: 0,
  }),
};
