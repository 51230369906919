import { forwardRef } from 'react';

import {
  ListItemAvatar as MuiListItemAvatar,
  ListItemAvatarProps as MuiListItemAvatarProps,
} from '@mui/material';

export type ListItemAvatarProps = MuiListItemAvatarProps

/** ListItemAvatar
 *
 * `import { ListItemAvatar } from '@fsp-io/shared-ui'`
 */

const ListItemAvatar = forwardRef<HTMLDivElement, ListItemAvatarProps>(
  (props, ref) => {
    return <MuiListItemAvatar {...props} ref={ref} />;
  },
);

ListItemAvatar.displayName = 'ListItemAvatar';

export default ListItemAvatar;
