import { forwardRef } from 'react';

import {
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
} from '@mui/material';
import { SliderPrimaryTooltip, SliderSecondaryTooltip } from "./styles";

export type SliderProps = MuiSliderProps

/** Sliders allow users to make selections from a range of values.
 * Sliders reflect a range of values along a bar, from which users may select a single value. They are ideal for adjusting settings such as volume, brightness, or applying image filters. <br/>
 *
 * `import { Slider } from '@fsp-io/shared-ui'`
 */

const Slider = forwardRef<HTMLSpanElement, SliderProps>(
  (
    {
      color = 'primary',
      disableSwap = false,
    //   isRtl = false,
      marks = false,
      max = 100,
      min = 0,
      orientation = 'horizontal',
      size = 'medium',
      step = 10,
      track = 'normal',
      valueLabelDisplay = 'off',
      sx,
      components,
      componentsProps,
      getAriaValueText,
      ...rest
    },
    ref,
  ) => {
    return (
      <MuiSlider
        {...rest}
        color={color}
        disableSwap={disableSwap}
        // isRtl={isRtl}
        marks={marks}
        max={max}
        min={min}
        orientation={orientation}
        size={size}
        step={step}
        track={track}
        valueLabelDisplay={valueLabelDisplay}
        sx={sx}
        getAriaValueText={getAriaValueText}
        //we pass custom ToolTip for label here..
        components={{
          ValueLabel: components?.ValueLabel ?? ValueLabelComponent,
        }}
        componentsProps={componentsProps}
        ref={ref}
      />
    );
  },
);

Slider.displayName = 'Slider';

export default Slider;

//We use different, custom Tooltips for Primary and Secondary Sliders
export function ValueLabelComponent(props: any) {
  const { children, value, ownerState, color, style, sx } = props;
  const ToolTipColor = color ?? ownerState?.color ?? 'primary';

  return ToolTipColor === 'primary' ? (
    <SliderPrimaryTooltip
      enterTouchDelay={0}
      placement="top"
      title={value}
      style={style}
      sx={sx}
    >
      {children}
    </SliderPrimaryTooltip>
  ) : (
    <SliderSecondaryTooltip
      enterTouchDelay={0}
      placement="top"
      title={value}
      style={style}
      sx={sx}
    >
      {children}
    </SliderSecondaryTooltip>
  );
}

