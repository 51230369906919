import { forwardRef } from 'react';

import MuiTableHead, {
  TableHeadProps as MuiTableHeadProps,
} from '@mui/material/TableHead';

export interface TableHeadProps extends MuiTableHeadProps {}

/** TableHead
 *
 * `import { TableHead } from '@fsp-io/shared-ui'`
 */

const TableHead = forwardRef<HTMLTableSectionElement, TableHeadProps>(
  (props, ref) => {
    return <MuiTableHead {...props} ref={ref} />;
  },
);

TableHead.displayName = 'TableHead';

export default TableHead;
