import ManagerPlusIcon from '@/assets/svg/manager-plus.svg'
import SettingsIcon from '@/assets/svg/settings.svg'
import SiteIcon from '@/assets/svg/site.svg'
import SupportIcon from '@/assets/svg/support.svg'
import UsersIcon from '@/assets/svg/users.svg'
import { PATHS } from '@/constants/paths'

export const SideBarUpperItems = [
  {
    title: 'adminPlus.index',
    icon: ManagerPlusIcon,
    path: PATHS.adminPlus.index,
  },
  {
    title: 'managerPlus.index',
    icon: ManagerPlusIcon,
    path: PATHS.managerPlus.index,
  },
  {
    title: 'sites.index',
    icon: SiteIcon,
    path: PATHS.sites.index,
  },
  {
    title: 'users.index',
    icon: UsersIcon,
    path: PATHS.users.index,
  },
]

export const SideBarLowerItems = [
  {
    title: 'support.index',
    icon: SupportIcon,
    path: PATHS.support.index,
  },
  {
    title: 'setting.index',
    icon: SettingsIcon,
    path: PATHS.settings.index,
  },
]
