import { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';
import { StylesExtendable } from '../../theme/types/props';
import {
  CustomTypographyVariant,
  UnnecessaryTypographyVariants,
} from '../typography/types';

export enum CopyRightTextAlign {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface CopyrightProps extends StylesExtendable {
  /**
   * The content rendered within the component.
   */
  text: string;
  textRight?: string;
  textAlign?: CopyRightTextAlign;
  variant?:
    | Exclude<MuiTypographyProps['variant'], UnnecessaryTypographyVariants>
    | CustomTypographyVariant;
}
