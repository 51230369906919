import React from 'react'

import { UserRoles } from '@/__generated__/graphql'

import AccessOutlet from './AccessOutlet'

const AdminManagerSupervisorOutlet: React.FC = () => {
  return (
    <AccessOutlet
      accessRoles={[
        UserRoles.ADMIN,
        UserRoles.MANAGER,
        UserRoles.SITE_SUPERVISOR,
      ]}
    />
  )
}

export default React.memo(AdminManagerSupervisorOutlet)
