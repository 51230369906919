import { CountrySelectProps } from './types';

const styles = ({ input }: CountrySelectProps) => {
  const defaultStyle = {
    borderWidth: 'thin',
  };

  const defaultSelectStyle = {
    alignItems: 'center',
    display: 'flex',
  };

  if (!input?.props?.hiddenLabel) {
    return {
      ...defaultStyle,
      '& > .MuiInputBase-input.MuiSelect-select': defaultSelectStyle,
    };
  }

  return {
    ...defaultStyle,
    ...{
      '& > .MuiInputBase-input.MuiSelect-select': {
        padding: 0,
        paddingLeft: '10px',
        height: '100%',
        ...defaultSelectStyle,
      },
    },
  };
};

const wrapperMenuStyles = {
  height: '1rem',
  width: '1.5rem',
};

export default styles;

export { wrapperMenuStyles };
