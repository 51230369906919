import { forwardRef } from 'react';

import {
  Stepper as MuiStepper,
  StepperProps as MuiStepperProps,
} from '@mui/material';
import StepConnector from '../step-connector';

export type StepperProps = MuiStepperProps

/**
 * Steppers convey progress through numbered steps. It provides a wizard-like workflow.
 *
 * `import { Stepper } from '@fsp-io/shared-ui'`
 */

const Stepper = forwardRef<HTMLDivElement, StepperProps>(
  ({ connector = <StepConnector />, ...rest }, ref) => {
    return <MuiStepper connector={connector} {...rest} ref={ref} />;
  },
);

Stepper.displayName = 'Stepper';

export default Stepper;
