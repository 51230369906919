export default {
  tabs: {
    padding: "0 18px",
  },
  tab: {
    padding: "0 18px !important",
  },
  tabPanel: {
    padding: "14px 46px 0",
  },
  divider: {
    position: "relative",
    top: -3,
  } as const,
};
