import React, { Suspense } from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'

import FullPageLoader from '@/app/components/full-page-loader'
import Layout from '@/app/components/layout/Layout'
import AdminManagerSupervisorOutlet from '@/app/guards/AdminManagerSupervisorOutlet'
import {
  LazyLoadedUserDetails,
  LazyLoadedUsersList,
} from '@/app/routes/LazyLoadedRoutes'
import { PATHS, ROUTE_CONSTANTS } from '@/constants/paths'

import UserProfileTabPaths from './profile/UserProfileTabPaths'

const UserRoutes: React.FC = () => {
  return (
    <Layout>
      <Suspense fallback={<FullPageLoader />}>
        <Routes>
          <Route path="*" element={<UserProfileTabPaths />} />

          <Route path="*" element={<AdminManagerSupervisorOutlet />}>
            <Route index element={<LazyLoadedUsersList />} />

            <Route
              path={`${ROUTE_CONSTANTS.details}/${ROUTE_CONSTANTS.oktaID}`}
              element={<LazyLoadedUserDetails />}
            />
          </Route>

          <Route
            path="*"
            element={<Navigate to={PATHS.users.list} replace />}
          />
        </Routes>
      </Suspense>
    </Layout>
  )
}

export default React.memo(UserRoutes)
