import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Popover } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { InfoIconLogo } from '@/icons/InfoIconLogo'
import { CrossCloseIcon } from '@/icons/CrossCloseIcon'
import { Stack, Typography } from '@industrial-plus/shared-ui'

import { NotificationPermissionTypes } from './types'
import { AlertWarningStack, CloseButton, OkayButton } from './styles'

const AlertWarning: React.FC = () => {
  const [display, setDisplay] = useState(true)
  const [confirmationButton, setConfirmationButton] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const theme = useTheme()
  const { t } = useTranslation()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setConfirmationButton(true)
  }

  const handleDisplayRemove = (): void => {
    setDisplay(false)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  if (Notification.permission === NotificationPermissionTypes.granted) {
    return null
  }

  if (!display) {
    return null
  }

  return (
    <AlertWarningStack direction="row">
      <InfoIconLogo />
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="subtitle2"
          fontSize="1.25rem"
          ml="1rem"
          color={theme.colors.primary700}
          lineHeight="1.75rem"
        >
          {t('components.notifications.alertWarning')}
        </Typography>
        <CloseButton onClick={handleClick}>
          <CrossCloseIcon />
          {confirmationButton ? (
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{ marginRight: '2.81rem' }}
            >
              <Stack
                direction="row"
                sx={{ alignItems: 'center', padding: '1.25rem' }}
              >
                <Typography
                  variant="h6"
                  color={theme.colors.primary700}
                  mr="5px"
                >
                  {t('components.notifications.notificationsDisabled')}
                </Typography>
                <OkayButton onClick={handleDisplayRemove}>
                  {t('common.okay')}
                </OkayButton>
              </Stack>
            </Popover>
          ) : null}
        </CloseButton>
      </Stack>
    </AlertWarningStack>
  )
}

export default React.memo(AlertWarning)
