import { forwardRef } from 'react';

import MuiFormLabel, {
  FormLabelProps as MuiFormLabelProps,
} from '@mui/material/FormLabel';

export type FormLabelProps = MuiFormLabelProps

/** FromLabel
 *
 * `import { FromLabel } from '@fsp-io/shared-ui'`
 */

const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>((props, ref) => {
  return <MuiFormLabel {...props} ref={ref} />;
});

FormLabel.displayName = 'FormLabel';

export default FormLabel;
