import { CSSObject } from "@mui/material";
import { Position } from "../types/common";

export const getStatusIndicatorPositionStyles = (
  statusPosition?: Position
): CSSObject => {
  switch (statusPosition) {
    case Position.Right:
      return {
        top: 0,
        right: 0,
      };
    case Position.Bottom:
      return {
        bottom: 0,
        left: 0,
      };
    default:
      return {
        top: 0,
        left: 0,
      };
  }
};

export const getStatusIndicatorOffsetStyles = (
  statusPosition?: Position
): CSSObject => {
  const statusIndicatorOffset = "4px";

  switch (statusPosition) {
    case Position.Right:
      return {
        paddingRight: statusIndicatorOffset,
      };
    case Position.Top:
      return {
        paddingTop: statusIndicatorOffset,
      };
    case Position.Bottom:
      return {
        paddingBottom: statusIndicatorOffset,
      };
    default:
      return {
        paddingLeft: statusIndicatorOffset,
      };
  }
};
