import { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  SxProps,
  Theme,
} from '@mui/material';
import Box from '../box';
import IconButton from '../icon-button';
import Icon from '../icon';
import Typography from '../typography';
import { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';
import {
  CustomTypographyVariant,
  UnnecessaryTypographyVariants,
} from '../typography/types';

export interface DialogTitleProps extends MuiDialogTitleProps {
  // this may be updated later
  clearable?: boolean;
  onClose?: () => void;
  sx?: SxProps<Theme>;
  variant?:
    | Exclude<MuiTypographyProps['variant'], UnnecessaryTypographyVariants>
    | CustomTypographyVariant;
}

/** DialogTitle
 *
 * DialogTitle is a used add a Title to the Dialog Component.
 * It contains simple text but special CSS is applied automatically to highlight the text content serving as the Title.
 *
 * `import { DialogTitle } from '@fsp-io/shared-ui'`
 */

const DialogTitle = forwardRef<HTMLHeadingElement, DialogTitleProps>(
  (props, ref) => {
    const { clearable, children, onClose, ...rest } = props;

    return (
      <MuiDialogTitle {...rest} ref={ref}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textTransform: 'capitalize',
            ...rest.sx,
          }}
        >
          <Typography
            component="h2"
            variant={props.variant ? props.variant : 'h6'}
          >
            {children}
          </Typography>
          {clearable && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.action.active,
              }}
              size="small"
            >
              <Icon fontSize="medium">
                <CloseIcon sx={{ width: '100%', height: '100%' }} />
              </Icon>
            </IconButton>
          )}
        </Box>
      </MuiDialogTitle>
    );
  },
);

DialogTitle.displayName = 'DialogTitle';

export default DialogTitle;
