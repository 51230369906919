import { forwardRef } from 'react';

import MuiToolbar, {
  ToolbarProps as MuiToolbarProps,
} from '@mui/material/Toolbar';

export type ToolbarProps = MuiToolbarProps;

/** Toolbar
 *
 * `import { Toolbar } from '@fsp-io/shared-ui'`
 */

const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>((props, ref) => {
  return <MuiToolbar {...props} ref={ref} />;
});

Toolbar.displayName = 'Toolbar';

export default Toolbar;
