import { CSSObject, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material';

export function getSxStyles(theme: Theme, sx: SxProps | SxProps<Theme> = {}) {
  if (Array.isArray(sx)) {
    const styleObj: CSSObject = {};
    sx.forEach((el) => {
      Object.assign(styleObj, getSxStyles(theme, el));
    });
    return styleObj;
  } else {
    return typeof sx === 'function' ? sx(theme) : sx;
  }
}
