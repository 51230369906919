import { getSxStyles } from '@/app/utils/styles'
import { Icon, BoxProps } from '@industrial-plus/shared-ui'
import { SystemStyleObject } from '@mui/system'
import React from 'react'

interface SymbolParams extends Pick<BoxProps, 'children' | 'sx'> {
  iconName?: string
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'disabled'
    | 'action'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  fontSize?: 'small' | 'inherit' | 'large' | 'medium'
}

const IconSymbol = ({ iconName, color, ...rest }: SymbolParams) => {
  return (
    <Icon
      baseClassName="material-icons-round"
      color={color}
      sx={(theme) => ({ ...getSxStyles(theme, rest.sx) } as SystemStyleObject)}
      fontSize={rest.fontSize}
      {...rest}
    >
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
        rel="stylesheet"
      />
      {iconName ?? 'close'}
    </Icon>
  )
}

export default IconSymbol
