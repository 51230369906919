import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Alert as MuiAlert } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '../icon-button';
import styles from './styles';
import { AlertProps } from './types';

/** Alert
 *
 * An alert displays a short, important message in a way that attracts the user's attention without interrupting the user's task.
 *
 * `import { Alert } from '@fsp-io/shared-ui'`
 */

const StyledAlert = styled(MuiAlert)(styles);

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      onClose,
      severity = 'warning',
      variant = 'standard',
      color,
      iconMapping = {
        // Default value for success icon passed here to prevent import React inside FleetThemeConfigs
        success: <CheckCircleOutlineIcon fontSize="inherit" />,
      },
      action,
      ...rest
    },
    ref,
  ) => {
    const alertColor = color ?? severity;
    return (
      <StyledAlert
        color={color}
        severity={severity}
        variant={variant}
        iconMapping={iconMapping}
        action={
          <>
            {action}
            {onClose && (
              <IconButton
                aria-label="Close"
                onClick={onClose}
                sx={{
                  width: '28px',
                  height: '28px',
                  fontSize: '20px',
                  color: (theme) =>
                    variant === 'filled'
                      ? theme.palette[alertColor]?.contrastText
                      : theme.palette.action.active,
                  marginLeft: '12px',
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          </>
        }
        {...rest}
        ref={ref}
      />
    );
  },
);

Alert.displayName = 'Alert';

export default Alert;
