import { Position } from "../../types/common";

export const setBorderRadius = (align: Position): string => {
  switch (align) {
    case "Top":
      return "4px 4px 0 0";
    case "Bottom":
      return "0 0 4px 4px";
    case "Right":
      return "0 4px 4px 0";
    default:
      return "4px 0 0 4px";
  }
};
