import { forwardRef } from 'react';

import { Paper as MuiPaper, PaperProps as MuiPaperProps } from '@mui/material';

export type PaperProps = MuiPaperProps

/** Paper is used as the Background of an application. In Material Design, the physical properties of paper are translated
 * to the screen, and an application's behavior mimics paper's ability to be re-sized, shuffled, and bound together in multiple sheets.
 *
 * `import Paper from '@fsp-io/Paper'`
 */

const Paper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
  return <MuiPaper {...props} ref={ref} />;
});

Paper.displayName = 'Paper';

export default Paper;
