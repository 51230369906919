import { forwardRef } from 'react';

import {
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  duration,
} from '@mui/material';

export type DrawerProps = MuiDrawerProps

/** Navigation drawers provide access to destinations in your app. Side sheets are surfaces containing supplementary content that are anchored to the left or right edge of the screen.
 *
 * `import { Drawer } from '@fsp-io/shared-ui'`
 */

const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  (
    {
      transitionDuration = {
        enter: duration.enteringScreen,
        exit: duration.leavingScreen,
      },
      ...rest
    },
    ref,
  ) => {
    return (
      <MuiDrawer transitionDuration={transitionDuration} {...rest} ref={ref} />
    );
  },
);

Drawer.displayName = 'Drawer';

export default Drawer;
