import { forwardRef } from 'react';

import {
  CardHeader as MuiCardHeader,
  CardHeaderProps as MuiCardHeaderProps,
} from '@mui/material';

export type CardHeaderProps = MuiCardHeaderProps

/** CardHeader
 *
 * `import { CardHeader } from '@fsp-io/shared-ui'`
 */

const CardHeader = forwardRef<unknown, CardHeaderProps>((props, ref) => {
  return <MuiCardHeader {...props} ref={ref} />;
});

CardHeader.displayName = 'CardHeader';

export default CardHeader;
