import styled from '@emotion/styled';
import { useField } from 'formik';
import { memo, useState } from 'react';
import FormControl from '../form-control';
import TextareaAutosize, { TextareaAutosizeProps } from '../text-area';
import Typography from '../typography';

interface Props extends TextareaAutosizeProps {
  name: string;
  label: string;
}

const StyledTextarea = styled(TextareaAutosize)`
  font-family: 'Red Hat Display', sans-serif;
`;

const TEXTAREA_DEFAULTS = {
  variant: 'outlined',
  size: 'small',
  error: false,
  helperText: '',
};

const FormikTextArea: React.FC<Props> = ({
  name,
  label,
  ...otherProps
}: Props) => {
  const [field, meta] = useField(name);

  const configTextfield = {
    ...TEXTAREA_DEFAULTS,
    ...otherProps,
    ...field,
    label,
  };

  const [count, setCount] = useState<number>(
    Number(configTextfield.value.length)
  );

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  const onKeyHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    setCount((e.target as HTMLTextAreaElement).value.length);
  };

  return (
    <FormControl
      sx={{
        minWidth: 120,
        width: '100%',
      }}
      variant="filled"
    >
      <Typography variant="subtitle2">{label}</Typography>
      <StyledTextarea
        style={{ fontSize: '16px' }}
        onKeyUp={onKeyHandler}
        {...configTextfield}
      />
      <Typography variant="caption" display="block" gutterBottom>
        {count}/{otherProps.maxLength}
      </Typography>
    </FormControl>
  );
};

FormikTextArea.defaultProps = {
  minRows: 3,
};

export default memo(FormikTextArea);
