import React from 'react'

import { Backdrop, Divider } from '@industrial-plus/shared-ui'
import { useMediaQuery, useTheme } from '@mui/material'

import {
  ActionType,
  Component,
  useAccessPermissions,
} from '@/app/hooks/useAccessPermissions'
import { PATHS } from '@/constants/paths'

import LogoutButton from './LogoutButton'
import NavigationItem from './NavigationItem'
import { SideBarUpperItems } from './SideBarItems'
import { Drawer, SideNavUpperList, StyledList } from './styles'
import Toolbar from './Toolbar'

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
}

const SideNav: React.FC<Props> = (props: Props) => {
  const { open, setOpen } = props

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'))

  const adminSectionAllowed = useAccessPermissions(
    ActionType.READ,
    Component.ADMIN_PLUS
  )

  const managerSectionAllowed = useAccessPermissions(
    ActionType.READ,
    Component.MANAGER_PLUS
  )

  const siteSectionAllowed = useAccessPermissions(
    ActionType.READ,
    Component.SITE
  )

  const userSectionAllowed = useAccessPermissions(
    ActionType.READ,
    Component.USER
  )

  const permissionsHandler = (path: string): boolean => {
    switch (path) {
      case PATHS.adminPlus.index:
        return adminSectionAllowed

      case PATHS.managerPlus.index:
        return managerSectionAllowed

      case PATHS.sites.index:
        return siteSectionAllowed

      case PATHS.users.index:
        return userSectionAllowed
      default:
        return false
    }
  }

  return (
    <>
      {isTablet && (
        <Backdrop
          role="dialog"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
          onClick={() => setOpen(!open)}
        />
      )}
      <Drawer
        open={open}
        role="navigation"
        PaperProps={{ role: 'menubar' }}
        variant="permanent"
      >
        <Toolbar onToggle={() => setOpen(!open)} open={open} />
        <Divider />

        {/* Side Nav Upper List */}
        <SideNavUpperList>
          {SideBarUpperItems.map((item, index) => {
            const accessAllowed = permissionsHandler(item.path)

            if (!accessAllowed) {
              return null
            }

            return (
              <NavigationItem
                key={index}
                open={open}
                title={item.title}
                icon={item.icon}
                path={item.path}
              />
            )
          })}
        </SideNavUpperList>

        {/* Side Nav Lower List */}
        <StyledList>
          {/* {SideBarLowerItems.map((item, index) => {
            return (
              <NavigationItem
                key={index}
                open={open}
                title={item.title}
                icon={item.icon}
                path={item.path}
              />
            );
          })} */}
        </StyledList>

        <Divider />

        {/* Logout Button */}
        <LogoutButton open={open} />
      </Drawer>
    </>
  )
}

export default React.memo(SideNav)
