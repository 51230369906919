import { InMemoryCache, makeVar } from '@apollo/client'
import { concatPagination } from '@apollo/client/utilities'

export const originalUrlRedirected = makeVar<string>('')

/* istanbul ignore next */
const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        // cache the previous results and concat the new results to original data
        users: concatPagination(['where']),
        originalUrlRedirected: {
          read() {
            return originalUrlRedirected()
          },
        },
        siteDashboardDetails: {
          merge(existing, incoming) {
            return {
              ...existing,
              ...incoming,
            }
          },
        },
        siteDetails: {
          merge(existing, incoming) {
            return {
              ...existing,
              ...incoming,
            }
          },
        },
      },
    },
    UserProfile: {
      merge: true,
    },
  },
})

export default cache
