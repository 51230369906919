import crypto from 'crypto'

import { UserRoles } from '@/__generated__/graphql'

export class Helpers {
  public static transformEnumKeyToCapitalCase(value: string) {
    return value
      .split('_')
      .map((str) => {
        const res = str.toLowerCase()
        return res.substring(0, 1).toUpperCase() + res.substring(1)
      })
      .join(' ')
  }

  public static generateDropdownValuesFromEnum(
    enumRef: any
  ): { name: string; value: string }[] {
    return Object.keys(enumRef).map((key) => ({
      name: Helpers.transformEnumKeyToCapitalCase(key),
      value: enumRef[key] as string,
    }))
  }

  public static capitalize(value: string, character = ' ') {
    return value
      .split(character)
      .map((str) => {
        const res = str.toLowerCase()
        return res.substring(0, 1).toUpperCase() + res.substring(1)
      })
      .join(' ')
  }

  // Function for converting "Customer Project Manager" to "User"
  public static displayUserRole(userRole: UserRoles) {
    if (!userRole) {
      return ''
    }

    return this.transformEnumKeyToCapitalCase(userRole)
  }

  public static createHash = (xml?: string): string => {
    if (!xml) {
      throw new Error(`SystemConfig::createHash: xml is not defined: '${xml}'`)
    }

    return crypto.createHmac('sha256', xml).digest('hex')
  }

  public static readonly formatDateToDDMMYYYY = (
    date: string,
    splitString = '-'
  ) => {
    // Split the date string by '-'
    const [year, month, day] = date.split(splitString)

    // Reformat to 'dd-mm-yyyy'
    return `${day}-${month}-${year}`
  }

  public static readonly formatDateToYYYYMMDD = (
    date: string,
    splitString = '-'
  ) => {
    // Split the date string by '-'
    const [day, month, year] = date.split(splitString)

    // Reformat to 'yyyy-mm-dd'
    return `${year}-${month}-${day}`
  }

  public static formatIntoCamelCase = (str: string): string => {
    return str.substring(0, 1).toLowerCase() + str.substring(1)
  }
}
